import React, { useState } from 'react'
import PageHeader from '../../../components/PageHeader'
import { useDispatch, useSelector } from 'react-redux';
import { addCategory, addTag, removeCategory, removeTag } from '../../../stores/edit/EditSlice';
import AbortableAlert from '../../../components/AbortableAlert';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

const TagIndex = ({ children }) => {

    const [view, setView] = useState(false);
    const dispatch = useDispatch();
    const { isEditLoading } = useSelector(state => state.edit);
    const locUser = useSelector(state => state.firebase.profile.user);

    const [misc, setMiscState] = useState({
        name: "",
    });

    const updateFormValue = (evt) => {
        setMiscState(state => ({ ...state, [evt.target.name]: evt.target.value }));
    }

    const handleAddTag = () => {
        if (misc.name === "" || misc.name === null) {
            AbortableAlert("Please add a tag.");
            return;;
        }
        dispatch(addTag(misc.name))
            .then(() => {
                AbortableAlert("Tag successfully added.");
                setMiscState(state => ({ ...state, name: "" }));
                setView(false);
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleDeleteTag = (data) => {
        if (window.confirm("Are you sure you want to delete?")) {
            dispatch(removeTag(data))
                .then(() => {
                    AbortableAlert("Tag successfully removed.");
                })
                .catch((err) => {
                    AbortableAlert(err);
                })
        }
    }

    console.log("heheh")

    const handleAddCategory = () => {
        console.log("im running", misc.name)
        if (misc.name === "" || misc.name === null) {
            AbortableAlert("Please add a category.");
            return;;
        }
        dispatch(addCategory(misc.name))
            .then(() => {
                AbortableAlert("Category successfully added.");
                setMiscState(state => ({ ...state, name: "" }));
                setView(false);
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleDeleteCategory = (data) => {
        if (window.confirm("Are you sure you want to delete?")) {
            dispatch(removeCategory(data))
                .then(() => {
                    AbortableAlert("Category successfully removed.");
                })
                .catch((err) => {
                    AbortableAlert(err);
                })
        }
    }

    let proppedChildren = React.Children.map(children, c => React.cloneElement(c, {
        locUser: locUser,
        updateFormValue: updateFormValue,
        setView: setView,
        view: view,
        name: misc.name,
        handleAddTag: handleAddTag,
        handleDeleteTag: handleDeleteTag,
        handleAddCategory: handleAddCategory,
        handleDeleteCategory: handleDeleteCategory,
        isEditLoading: isEditLoading,
    }));

    return <>
        {/* <!-- Content Header (Page header) --> */}
        <PageHeader
            pName="Miscellaneous"
            pDesc="Manage forum tags"
        />

        {/* // < !--Main content-- > */}
        < section className="content container-fluid" >

            {/* <!-------------------------- | Your Page Content Here --------------------------> */}
            {proppedChildren}
        </section >
        {/* // <!-- /.content --> */}
    </>
}

export default compose(
    firestoreConnect(
        [
            {
                collection: "inits",
                doc: 'tags',
                storeAs: "homeTags"
            },
            {
                collection: "inits",
                doc: 'categorys',
                storeAs: "homeCategorys"
            }
        ]
    )
)(TagIndex)
import React from 'react'
import { useSelector } from 'react-redux'

const Overview = () => {

    const healthcare = useSelector(
        ({ firestore: { ordered } }) => ordered.healthcarePost && ordered.healthcarePost
    )
    const immigration = useSelector(
        ({ firestore: { ordered } }) => ordered.immigrationPost && ordered.immigrationPost
    )
    const general = useSelector(
        ({ firestore: { ordered } }) => ordered.generalPost && ordered.generalPost
    )
    const discrimination = useSelector(
        ({ firestore: { ordered } }) => ordered.discriminationPost && ordered.discriminationPost
    )
    const working = useSelector(
        ({ firestore: { ordered } }) => ordered.workingPost && ordered.workingPost
    )
    const education = useSelector(
        ({ firestore: { ordered } }) => ordered.educationPost && ordered.educationPost
    )
    const housing = useSelector(
        ({ firestore: { ordered } }) => ordered.housingPost && ordered.housingPost
    )
    const gender = useSelector(
        ({ firestore: { ordered } }) => ordered.genderPost && ordered.genderPost
    )

    return <>
        <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
                <span className="info-box-icon bg-aqua"><i className="fa fa-tags"></i></span>
                <div className="info-box-content">
                    <span className="info-box-text">Healthcare</span>
                    <span className="info-box-number">{healthcare && healthcare.length}<small></small></span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}
        <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
                <span className="info-box-icon bg-green"><i className="fa fa-tags"></i></span>

                <div className="info-box-content">
                    <span className="info-box-text">Immigration</span>
                    <span className="info-box-number">{immigration && immigration.length}</span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}

        {/* <!-- fix for small devices only --> */}
        <div className="clearfix visible-sm-block"></div>

        <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
                <span className="info-box-icon bg-yellow"><i className="fa fa-tags"></i></span>

                <div className="info-box-content">
                    <span className="info-box-text">Working</span>
                    <span className="info-box-number">{working && working.length}</span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}
        <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
                <span className="info-box-icon bg-red"><i className="fa fa-tags"></i></span>

                <div className="info-box-content">
                    <span className="info-box-text">Gender</span>
                    <span className="info-box-number">{gender && gender.length}</span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>

        {/* <!-- fix for small devices only --> */}
        <div className="clearfix visible-sm-block"></div>

        <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
                <span className="info-box-icon bg-yellow"><i className="fa fa-tags"></i></span>

                <div className="info-box-content">
                    <span className="info-box-text">Discrimination</span>
                    <span className="info-box-number">{discrimination && discrimination.length}</span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}
        <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
                <span className="info-box-icon bg-red"><i className="fa fa-tags"></i></span>

                <div className="info-box-content">
                    <span className="info-box-text">Education</span>
                    <span className="info-box-number">{education && education.length}</span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>

        {/* <!-- fix for small devices only --> */}
        <div className="clearfix visible-sm-block"></div>

        <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
                <span className="info-box-icon bg-aqua"><i className="fa fa-tags"></i></span>
                <div className="info-box-content">
                    <span className="info-box-text">General</span>
                    <span className="info-box-number">{general && general.length}<small></small></span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}
        <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
                <span className="info-box-icon bg-green"><i className="fa fa-tags"></i></span>

                <div className="info-box-content">
                    <span className="info-box-text">Housing</span>
                    <span className="info-box-number">{housing && housing.length}</span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}


    </>
}

export default Overview
import React from "react";
import { useSelector } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Outlet } from "react-router-dom";
import { compose } from "redux";

const Partners = () => {
  const partners = useSelector(({ firestore: { ordered } }) => ordered.partners);
  return (
    <div className="page-wrapper" style={{ width: "100%" }}>
      <Outlet context={partners} />
    </div>
  );
};

export default compose(
  firestoreConnect(() => {
    return [
      {
        collection: "partners",
        orderBy: ["createdAt", "desc"],
        storeAs: "partners",
      },
    ];
  })
)(Partners);

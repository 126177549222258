import moment from 'moment'
import React from 'react'
import { isLoaded } from 'react-redux-firebase';

const HouseInfoBox = ({
    name,
    apartment,
    location,
    status,
    createdAt,
    tReviews,
    handleHouseDelete,
    handlePublish,
    handleUnpublish,
    rUsername,
    reviewedAt,
    docs,
}) => {

    const handleReviewTotal = () => {
        if (isLoaded(docs)) {
            let total = 0;

            docs.forEach(doc => {
                total += Number(doc.rating.exterior) + Number(doc.rating.interior) + Number(doc.rating.communication)
                    + Number(doc.rating.services) + Number(doc.rating.security) + Number(doc.rating.vfm) + Number(doc.rating.hApp)
                    + Number(doc.rating.facility)
            })

            if (total === 0) {
                return 0
            }
            return total / (8 * docs.length);
        }

    }

    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
            <div className="box box-widget widget-user-2">
                <div className="widget-user-header bg-white" style={{ padding: "10px 20px" }}>
                    <h5 className="widget-user-desc" style={{ margin: "0px", fontSize: "16px" }}>Skill Information</h5>
                </div>
                <div className="box-footer no-padding">
                    <ul className="nav nav-stacked">
                        <li>
                            <a
                                href="/"
                                onClick={(event) => event.preventDefault()}
                            >
                                Apartment <span className="pull-right badge bg-grey">{name}</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/"
                                onClick={(event) => event.preventDefault()}
                            >
                                Room
                                <span className="pull-right">
                                    {apartment}
                                </span></a>
                        </li>
                        <li>
                            <a
                                href="/"
                                onClick={(event) => event.preventDefault()}
                            >
                                Location
                                <span className="pull-right">
                                    {location}
                                </span></a>
                        </li>
                        <li>
                            <a
                                href="/"
                                onClick={(event) => event.preventDefault()}
                            >
                                Status
                                <span
                                    className={`pull-right badge ${(status === 0) ? 'bg-green' : 'bg-red'}`}
                                >
                                    {`${(status === 0) ? 'Published' : 'Unpublished'}`}
                                </span></a>
                        </li>
                        <li>
                            <a
                                href="/"
                                onClick={(event) => event.preventDefault()}
                            >
                                Created on
                                <span className="pull-right ">
                                    <i>{moment(createdAt.toDate()).format('ll')}</i>
                                </span></a>
                        </li>
                        <li>
                            <a
                                href="/"
                                onClick={(event) => event.preventDefault()}
                            >
                                Total ratings
                                <span className="pull-right">
                                    {tReviews}
                                </span></a>
                        </li>
                        <li>
                            <a
                                href="/"
                                onClick={(event) => event.preventDefault()}
                            >
                                Score
                                <span
                                    className={`pull-right badge ${(handleReviewTotal().toFixed(1) <= 2.1) ? 'bg-red' :
                                        (handleReviewTotal().toFixed(1) > 2.1 && handleReviewTotal().toFixed(1) < 3.5) ? 'bg-yellow' : 'bg-green'}`}
                                >
                                    {handleReviewTotal().toFixed(1)}
                                </span></a>
                        </li>

                        {
                            rUsername !== "" ?
                                <>
                                    <li>
                                        <a
                                            href="/"
                                            onClick={(event) => event.preventDefault()}
                                        >
                                            Reviewed on
                                            <span className="pull-right">

                                                <i>{moment(reviewedAt.toDate()).format('llll')}</i>

                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/"
                                            onClick={(event) => event.preventDefault()}
                                        >
                                            Reviewed by
                                            <span className="pull-right">
                                                <span><i>{rUsername}</i></span>
                                            </span>
                                        </a>
                                    </li>
                                </> : null
                        }

                    </ul>
                </div>
            </div>
            <div className="box box-widget widget-user-2">
                <div className="widget-user-header bg-white" style={{ padding: "10px 20px" }}>
                    <h5 className="widget-user-desc" style={{ margin: "0px", fontSize: "16px" }}>Actions</h5>
                </div>
                <div className="box-footer no-padding">
                    <ul className="nav nav-stacked">
                        <li>
                            {
                                status === 0 ?
                                    <button
                                        type="button"
                                        className="btn btn-warning"
                                        onClick={handleUnpublish}
                                    >
                                        Un-publish house
                                    </button> :
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                        onClick={handlePublish}
                                    >
                                        Publish house
                                    </button>
                            }

                        </li>
                        <li>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={handleHouseDelete}
                            >
                                Delete this house
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default HouseInfoBox
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import AuthIsActive from "./auth/controls/AuthIsActive";
import Login from "./auth/login/Login";
import BuildWrapper from "./layouts/BuildWrapper";
import MainWrapper from "./layouts/MainWrapper";
import Dashboard from "./pages/dashboard/Dashboard";
import PostIndex from "./pages/posts/partials/PostIndex";
import FilteredPosts from "./pages/posts/partials/FilteredPosts";
import Posts from "./pages/posts/Posts";
import GetPostById from "./pages/posts/partials/GetPostById";
import Skills from "./pages/skills/Skills";
import SkillIndex from "./pages/skills/partials/SkillIndex";
import FilteredSkills from "./pages/skills/partials/FilteredSkills";
import GetSkillById from "./pages/skills/partials/GetSkillById";
import Users from "./pages/users/Users";
import UsersIndex from "./pages/users/partials/UsersIndex";
import GetUserById from "./pages/users/partials/GetUserById";
import Resources from "./pages/resources/Resources";
import ResourcesIndex from "./pages/resources/partials/ResourcesIndex";
import Events from "./pages/events/Events";
import EventsIndex from "./pages/events/partials/EventsIndex";
import Surveys from "./pages/surveys/Surveys";
import SurveyIndex from "./pages/surveys/partials/SurveyIndex";
import Complaints from "./pages/complaints/Complaints";
import ComplaintIndex from "./pages/complaints/partials/ComplaintIndex";
import GetComplaintById from "./pages/complaints/partials/GetComplaintById";
import Appeals from "./pages/appeals/Appeals";
import AppealIndex from "./pages/appeals/partials/AppealIndex";
import GetAppealById from "./pages/appeals/partials/GetAppealById";
import Miscellaneous from "./pages/misc/Miscellaneous";
import MiscIndex from "./pages/misc/partials/MiscIndex";
import GetMiscById from "./pages/misc/partials/GetMiscById";
import Publish from "./pages/publish/Publish";
import PublishIndex from "./pages/publish/partials/PublishIndex";
import GetPublishById from "./pages/publish/partials/GetPublishById";
import Featured from "./pages/featured/Featured";
import FeaturedIndex from "./pages/featured/partials/FeaturedIndex";
import RegUsers from "./pages/users/partials/RegUsers";
import AdminUsers from "./pages/users/partials/AdminUsers";
import CreateUser from "./pages/users/partials/CreateUser";
import AllPublications from "./pages/publish/partials/AllPublications";
import CreatePublication from "./pages/publish/partials/CreatePublication";
import AllResources from "./pages/resources/partials/AllResources";
import CreateResource from "./pages/resources/partials/CreateResource";
import GetResourceById from "./pages/resources/partials/GetResourceById";
import GetEventById from "./pages/events/partials/GetEventById";
import GetSurveyById from "./pages/surveys/partials/GetSurveyById";
import ReportIndex from "./pages/reports/partials/ReportIndex";
import Reports from "./pages/reports/Reports";
import TagIndex from "./pages/tags/partials/TagIndex";
import ForumTags from "./pages/tags/partials/ForumTags";
import CategoryTags from "./pages/tags/partials/CategoryTags";
import Housing from "./pages/housing/Housing";
import HousingIndex from "./pages/housing/partials/HousingIndex";
import FilteredHousing from "./pages/housing/partials/FilteredHousing";
import GetHouseById from "./pages/housing/partials/GetHouseById";
import MembersIndex from "./pages/members/partials/MembersIndex";
import CreateMember from "./pages/members/partials/CreateMember";
import Members from "./pages/members/Members";
import Allmembers from "./pages/members/partials/Allmembers";
import GetMemberById from "./pages/members/partials/GetMemberById";
import Partners from "./pages/sponsors/Partners";
import PartnersIndex from "./pages/sponsors/partials/PartnersIndex";
import AllPartners from "./pages/sponsors/partials/AllPartners";
import CreatePartner from "./pages/sponsors/partials/CreatePartner";
import GetPartnerById from "./pages/sponsors/partials/GetPartnerById";
import AuthProtected from "./auth/controls/AuthProtected";
import ErrorPage from "./pages/error/ErrorPage";
import DonatedIndex from "./pages/donations/partials/DonationIndex";
import Donors from "./pages/donations/partials/Donors";
import Donations from "./pages/donations/Donations";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <AuthProtected>
            <MainWrapper />
          </AuthProtected>
        }
      >
        <Route
          index
          element={
            <AuthIsActive>
              <Login />
            </AuthIsActive>
          }
        />

        <Route path="admin" element={<BuildWrapper />}>
          <Route index element={<Dashboard />} />

          {/* posts */}
          <Route path="posts" element={<Posts />}>
            <Route index element={<PostIndex />} />
            <Route path="status/:id" element={<FilteredPosts />} />
            <Route path="id/:id" element={<GetPostById />} />
          </Route>

          {/* features */}
          <Route path="features" element={<Featured />}>
            <Route index element={<FeaturedIndex />} />
          </Route>

          {/* skills */}
          <Route path="skills" element={<Skills />}>
            <Route index element={<SkillIndex />} />
            <Route path="status/:id" element={<FilteredSkills />} />
            <Route path="id/:id" element={<GetSkillById />} />
          </Route>

          {/* housing*/}
          <Route path="housing" element={<Housing />}>
            <Route index element={<HousingIndex />} />
            <Route path="status/:id" element={<FilteredHousing />} />
            <Route path="id/:id" element={<GetHouseById />} />
          </Route>

          {/* complaints */}
          <Route path="complaints" element={<Complaints />}>
            <Route index element={<ComplaintIndex />} />
            <Route path="id/:id" element={<GetComplaintById />} />
          </Route>

          {/* appeals*/}
          <Route path="appeals" element={<Appeals />}>
            <Route index element={<AppealIndex />} />
            <Route path="id/:id" element={<GetAppealById />} />
          </Route>

          {/* reports*/}
          <Route path="reports" element={<Reports />}>
            <Route index element={<ReportIndex />} />
          </Route>

          {/* users */}
          <Route path="users" element={<Users />}>
            <Route
              index
              element={
                <UsersIndex>
                  <RegUsers />
                </UsersIndex>
              }
            />
            <Route
              path="create"
              element={
                <UsersIndex>
                  <CreateUser />
                </UsersIndex>
              }
            />
            <Route
              path="admin-users"
              element={
                <UsersIndex>
                  <AdminUsers />
                </UsersIndex>
              }
            />
            <Route path=":id" element={<GetUserById />} />
          </Route>
          {/* donation */}
          <Route path="donations" element={<Donations />}>
            <Route
              index
              element={
                <DonatedIndex>
                  <Donors />
                </DonatedIndex>
              }
            />
          </Route>

          {/* members */}
          <Route path="members" element={<Members />}>
            <Route
              index
              element={
                <MembersIndex>
                  <Allmembers />
                </MembersIndex>
              }
            />
            <Route
              path="create"
              element={
                <MembersIndex>
                  <CreateMember />
                </MembersIndex>
              }
            />
            <Route path=":id" element={<GetMemberById />} />
          </Route>

          {/* partners */}
          <Route path="partners" element={<Partners />}>
            <Route
              index
              element={
                <PartnersIndex>
                  <AllPartners />
                </PartnersIndex>
              }
            />
            <Route
              path="create"
              element={
                <PartnersIndex>
                  <CreatePartner />
                </PartnersIndex>
              }
            />
            <Route path=":id" element={<GetPartnerById />} />
          </Route>

          {/*resources */}
          <Route path="resources" element={<Resources />}>
            <Route
              index
              element={
                <ResourcesIndex>
                  <AllResources />
                </ResourcesIndex>
              }
            />
            <Route
              path="create"
              element={
                <ResourcesIndex>
                  <CreateResource />
                </ResourcesIndex>
              }
            />
            <Route path="id/:id" element={<GetResourceById />} />
          </Route>

          {/*publish */}
          <Route path="publish" element={<Publish />}>
            <Route
              index
              element={
                <PublishIndex>
                  <AllPublications />
                </PublishIndex>
              }
            />
            <Route
              path="create"
              element={
                <PublishIndex>
                  <CreatePublication />
                </PublishIndex>
              }
            />
            <Route path="id/:id" element={<GetPublishById />} />
          </Route>

          {/*misc */}
          <Route path="misc" element={<Miscellaneous />}>
            <Route index element={<MiscIndex />} />
            <Route
              path="tags/forum"
              element={
                <TagIndex>
                  <ForumTags />
                </TagIndex>
              }
            />
            <Route
              path="tags/skills"
              element={
                <TagIndex>
                  <CategoryTags />
                </TagIndex>
              }
            />
            <Route path=":id" element={<GetMiscById />} />
          </Route>

          {/*events */}
          <Route path="events" element={<Events />}>
            <Route index element={<EventsIndex />} />
            <Route path="id/:id" element={<GetEventById />} />
          </Route>

          {/*surves */}
          <Route path="surveys" element={<Surveys />}>
            <Route index element={<SurveyIndex />} />
            <Route path="id/:id" element={<GetSurveyById />} />
          </Route>
          <Route path="*" element={<ErrorPage />}></Route>
        </Route>
        <Route path="*" element={<ErrorPage />}></Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

const AuthIsActive = ({ children }) => {
    const { auth } = useSelector(state => state.firebase);
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.uid) {
            navigate('/admin', { replace: true })
        }
    }, [auth])
    return children
}

export default AuthIsActive
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import imageCompression from "browser-image-compression";
import { useNavigate, useParams } from "react-router-dom";
import { updatePartner } from "../../../stores/edit/EditSlice";
import PageHeader from "../../../components/PageHeader";
import AbortableAlert from "../../../components/AbortableAlert";
import ErrorPage from "../../error/ErrorPage";

const GetPartnerById = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();

  const [param, setParam] = useState(null);
  const { isEditLoading } = useSelector((state) => state.edit);

  useEffect(() => {
    if (params) {
      setParam(params.id);
    }
  }, [params]);

  useFirestoreConnect(() => {
    return [
      {
        collection: "partners",
        doc: param,
        storeAs: `${param}-partner`,
      },
    ];
  });

  const locPartner = useSelector(
    ({ firestore: { ordered } }) => ordered[`${param}-partner`]
  );

  const [partner, setPartnerState] = useState({
    poster: "",
    name: "",
    link: "",
  });

  useEffect(() => {
    if (!isEmpty(locPartner)) {
      setPartnerState((state) => ({
        ...state,
        poster: locPartner[0].properties.logoUrl,
        name: locPartner[0].properties.name,
        link: locPartner[0].properties.linkUrl,
      }));
    }
  }, [locPartner]);

  const updateFormValue = (evt) => {
    setPartnerState((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  // const handleOnChangeOptions = (evt) => {
  //   const options = [...evt.target.options]
  //     .filter((o) => o.selected)
  //     .map((o) => o.value)
  //     .toString();
  //   setPartnerState((state) => ({ ...state, [evt.target.name]: options }));
  // };

  // compressed image
  const compressImageFile = async (file) => {
    const options = {
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: "image/webp",
    };

    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  };

  const handleUpload = (evt) => {
    const [file] = evt.target.files;
    if (evt.target.id === "poster") {
      const compressedFile = compressImageFile(file);
      compressedFile.then((file) => {
        if (file) {
          setPartnerState((state) => ({ ...state, poster: file }));
        }
      });
    }
  };

  const handleUpdatePartner = () => {
    if (partner.poster === null || partner.poster === "") {
      AbortableAlert("Add a suitable logo for this partner!");
      return;
    }

    if (partner.name === "" || partner.name === null) {
      AbortableAlert("Add a name for this partner");
      return;
    }

    if (partner.link === "" || partner.link === null) {
      AbortableAlert("Add a link for this partner");
      return;
    }

    const data = {
      id: params.id,
      name: partner.name,
      poster: partner.poster,
      link: partner.link,
    };

    // console.log(data);

    dispatch(updatePartner(data))
      .then((res) => {
        AbortableAlert("Partner updated successfully.");
        setTimeout(() => {
          navigate("/admin/partners");
        }, 1000);
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  const handleNavigate = () => {
    navigate(-1, { replace: true });
  };

  if (isLoaded(locPartner)) {
    return (
      <>
        {isEmpty(locPartner) ? (
          <ErrorPage />
        ) : isLoaded(locPartner) ? (
          <>
            {" "}
            {/* <!-- Content Header (Page header) --> */}
            <PageHeader
              pName="Partners"
              pDesc="Manage partners by ID"
              handleBack={handleNavigate}
            />
            {/* // < !--Main content-- > */}
            <section className="content container-fluid">
              {/* <!-------------------------- | Your Page Content Here --------------------------> */}
              {/* overview */}
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10">
                  {/* <!-- Box Comment --> */}
                  <div className="create-form">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="box box-primary">
                          <div
                            className="box-header with-border"
                            style={{ padding: "1rem 3rem" }}
                          >
                            <h3 className="box-title">Create a partner</h3>
                          </div>
                          <div
                            className="box-body"
                            style={{ padding: "1rem 3rem" }}
                          >
                            <img
                              className="img-responsive max-banner pad"
                              style={{
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                width: "80px",
                                height: "80px",
                              }}
                              src={locPartner[0].properties.logoUrl}
                              loading="eager"
                              alt={locPartner[0].properties.logoUrl}
                            />
                            <div className="form-group">
                              <label htmlFor="poster">
                                Update partner image
                              </label>
                              <input
                                type="file"
                                accept="image/*"
                                id="poster"
                                onChange={handleUpload}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="name">
                                Partner name (eg: "Soian Churi")
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="name"
                                placeholder="Enter a tag (eg, Soian Churi)"
                                value={
                                  partner.name || locPartner[0].properties.name
                                }
                                onChange={updateFormValue}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="link">
                                Partner's Url (eg: "https://www.joeybidden.com")
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="link"
                                id="link"
                                placeholder="partner personal Url (eg: https://www.joeybidden.com)"
                                value={
                                  partner.link ||
                                  locPartner[0].properties.linkUrl
                                }
                                onChange={updateFormValue}
                              />
                            </div>
                          </div>
                          <div
                            className="box-footer fx"
                            style={{ padding: "1rem 3rem", gap: "1rem" }}
                          >
                            <button
                              type="submit"
                              className="btn btn-default"
                              onClick={handleNavigate}
                            >
                              cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={handleUpdatePartner}
                              disabled={isEditLoading}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* // <!-- /.content --> */}
          </>
        ) : null}
      </>
    );
  }
};

export default GetPartnerById;

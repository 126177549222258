import React from "react";
import { useSelector } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Outlet } from "react-router-dom";
import { compose } from "redux";

const Members = () => {
  const members = useSelector(({ firestore: { ordered } }) => ordered.members);
  return (
    <div className="page-wrapper" style={{ width: "100%" }}>
      <Outlet context={members} />
    </div>
  );
};

export default compose(
  firestoreConnect(() => {
    return [
      {
        collection: "members",
        where: [
          ["properties.type", "==", "Founder"],
          ["status", "==", 0],
        ],
        orderBy: ["createdAt", "desc"],
        storeAs: "founders",
      },
      {
        collection: "members",
        orderBy: ["createdAt", "desc"],
        storeAs: "members",
      },
    ];
  })
)(Members);

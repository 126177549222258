import React from 'react'

const EditorContainer = ({
    quillEditorRef
}) => {
    return (
        <div ref={quillEditorRef} id="editor-container"></div>
    )
}

export default EditorContainer
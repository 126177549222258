import React from 'react'

const Footer = () => {
    return (
        <footer className="main-footer">
            {/* <!-- To the right --> */}
            <div className="pull-right hidden-xs">
                All rights reserved.
            </div>
            {/* <!-- Default to the left --> */}
            <strong>Copyright &copy; 2023 <a href="#!">VOIS Cyprus</a>.</strong>
        </footer>
    )
}

export default Footer
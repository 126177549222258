import React, { useState } from "react";
import PageHeader from "../../../components/PageHeader";
import Overview from "./Overview";
import { useOutletContext } from "react-router-dom";
import { isLoaded } from "react-redux-firebase";
import { deleteMember, deletePartner } from "../../../stores/edit/EditSlice";
import AbortableAlert from "../../../components/AbortableAlert";
import { useDispatch } from "react-redux";

const PartnersIndex = ({ children }) => {
  const partners = useOutletContext();
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();

  // pagination
  const [indexDisp, setIndexDisp] = useState(0);
  const maxLimit = 10;
  const [maxDispLimit, setMaxDispLimit] = useState(maxLimit);

  const handleFilterChange = (evt) => {
    setFilter(evt.target.value);
  };

  const filteredPartners = React.useMemo(() => {
    if (isLoaded(partners)) {
      return partners.filter((data) => {
        return (
          [data.properties.name]
            .join("")
            .toLowerCase()
            .indexOf(filter.toLowerCase()) !== -1
        );
      });
    }
  }, [filter, partners]);

  const handlePrev = () => {
    setIndexDisp(indexDisp - 1);
    setMaxDispLimit(maxDispLimit - maxLimit);
  };

  const handleNext = () => {
    setIndexDisp(indexDisp + 1);
    setMaxDispLimit(maxDispLimit + maxLimit);
  };

  const handleUserDelete = (id) => {
    if (window.confirm("Are you sure to proceed ?")) {
      dispatch(deletePartner(id))
        .then((result) => {
          // console.log(result)
          AbortableAlert("Partner successfully deleted.");
        })
        .catch((err) => {
          AbortableAlert("An error occurred.");
        });
    }
  };

  let proppedChildren = React.Children.map(children, (c) =>
    React.cloneElement(c, {
      filteredPartners: filteredPartners,
      maxDispLimit: maxDispLimit,
      filter: filter,
      handleFilterChange: handleFilterChange,
      indexDisp: indexDisp,
      partners: partners,
      handleUserDelete: handleUserDelete,
      handlePrev: handlePrev,
      handleNext: handleNext,
    })
  );

  if (isLoaded(partners)) {
    return (
      <>
        {/* <!-- Content Header (Page header) --> */}
        <PageHeader pName="Partners" pDesc="Manage partners" />

        {/* // < !--Main content-- > */}
        <section className="content container-fluid">
          {/* <!-------------------------- | Your Page Content Here --------------------------> */}
          {/* overview */}
          <div className="row">
            <Overview />
          </div>

          {proppedChildren}
        </section>
        {/* // <!-- /.content --> */}
      </>
    );
  }
};

export default PartnersIndex;

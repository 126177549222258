import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader'
import ContentInfoBox from './comps/ContentInfoBox';
import AbortableAlert from '../../../components/AbortableAlert';
import AppealInfoBox from './comps/AppealInfoBox';
import { deleteAppeal, resolveAppeal, unResolveAppeal } from '../../../stores/edit/EditSlice';

const GetAppealById = () => {

    const dispatch = useDispatch();
    const params = useParams();
    const [loading, setIsLoading] = useState(true);

    const [param, setParam] = useState(null);
    const appeal = useSelector(
        ({ firestore: { ordered } }) => ordered[`${param}-appeals`]
    )

    const { isEditLoading } = useSelector(state => state.edit)

    useEffect(() => {
        if (params) {
            setParam(params.id)
        }
    }, [params]);

    useFirestoreConnect(() => {
        return [
            {
                collection: "appeals",
                doc: param,
                storeAs: `${param}-appeals`
            }
        ]
    })

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(-1, { replace: true });
    }

    const handleResolve = () => {
        dispatch(resolveAppeal(params.id))
            .then(() => {
                AbortableAlert("Appeal status successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleUnresolve = () => {
        dispatch(unResolveAppeal(params.id))
            .then(() => {
                AbortableAlert("Appeal status successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleAppealDelete = () => {
        if (window.confirm("Do you want to proceed?")) {
            setIsLoading(false);
            dispatch(deleteAppeal(params.id))
                .then(() => {
                    navigate("/admin/appeals", { replace: true });
                })
                .catch((err) => {
                    AbortableAlert(err);
                })
        }
    }

    if (isLoaded(appeal) && loading) {
        return <>

            {/* <!-- Content Header (Page header) --> */}
            <PageHeader
                pName="Complaints"
                pDesc="Manage complaints by ID"
                handleBack={handleNavigate}
            />

            {/* // < !--Main content-- > */}
            < section className="content container-fluid" >

                {/* <!-------------------------- | Your Page Content Here --------------------------> */}
                {/* overview */}
                <div className="row">
                    <AppealInfoBox
                        tag={appeal[0].properties.category}
                        status={appeal[0].status}
                        createdAt={appeal[0].createdAt}
                        rUsername={appeal[0].review.username}
                        reviewedAt={appeal[0].review.reviewedAt}
                        handleResolve={handleResolve}
                        handleUnResolve={handleUnresolve}
                        handleAppealDelete={handleAppealDelete}
                    />
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                        {/* <!-- Box Comment --> */}
                        <ContentInfoBox
                            createdAt={appeal[0].createdAt}
                            username={appeal[0].author.username}
                            photoUrl={appeal[0].author.photoURL}
                            title={appeal[0].properties.title}
                            subTitle={appeal[0].properties.category}
                            contentHTML={appeal[0].properties.description}
                            status={appeal[0].status}
                        />
                        {/* <!-- /.box --> */}
                    </div>
                    <div className="col-md-2"></div>
                </div >
                <div className="box box-widget widget-user-2" style={{
                    position: "fixed",
                    width: "fit-content",
                    right: "30px",
                    top: "60px"
                }}>
                    <div className="box-footer">
                        <div className="btn-group spx" style={{ flexDirection: "column", alignItems: 'flex-start' }}>
                            <p>Actions: </p>
                            <div className='fx' style={{ flex: "1", alignItems: 'flex-start', gap: "10px" }}>
                                {
                                    appeal[0].status !== 0 ?
                                        <button
                                            type="button"
                                            className="btn btn-default"
                                            disabled={appeal[0].status === 0 || isEditLoading}
                                            onClick={handleResolve}
                                        >
                                            Resolve appeal
                                        </button> :
                                        <button
                                            type="button"
                                            className="btn btn-default"
                                            disabled={isEditLoading}
                                            onClick={handleUnresolve}
                                        >
                                            Un-resolve appeal
                                        </button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* // <!-- /.content --> */}
        </>
    }

}

export default GetAppealById
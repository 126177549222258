import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import ContentInfoBox from "./comps/ContentInfoBox";
import ComplaintInfoBox from "./comps/ComplaintInfoBox";
import {
  deleteComplaint,
  resolveComplaint,
  unResolveComplaint,
} from "../../../stores/edit/EditSlice";
import AbortableAlert from "../../../components/AbortableAlert";
import ErrorPage from "../../error/ErrorPage";

const GetComplaintById = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setIsLoading] = useState(true);

  const [param, setParam] = useState(null);
  const complaint = useSelector(
    ({ firestore: { ordered } }) => ordered[`${param}-complaints`]
  );

  const { isEditLoading } = useSelector((state) => state.edit);

  useEffect(() => {
    if (params) {
      setParam(params.id);
    }
  }, [params]);

  useFirestoreConnect(() => {
    return [
      {
        collection: "complaints",
        doc: param,
        storeAs: `${param}-complaints`,
      },
    ];
  });

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(-1, { replace: true });
  };

  const handleResolve = () => {
    dispatch(resolveComplaint(params.id))
      .then(() => {
        AbortableAlert("Complaint status successfully updated.");
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  const handleUnresolve = () => {
    dispatch(unResolveComplaint(params.id))
      .then(() => {
        AbortableAlert("Complaint status successfully updated.");
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  const handleComplaintDelete = () => {
    if (window.confirm("Do you want to proceed?")) {
      setIsLoading(false);
      dispatch(deleteComplaint(params.id))
        .then(() => {
          navigate("/admin/complaints", { replace: true });
        })
        .catch((err) => {
          AbortableAlert(err);
        });
    }
  };

  return (
    <>
      {isEmpty(complaint) ? (
        <ErrorPage />
      ) : isLoaded(complaint) && loading ? (
        <>
          {/* <!-- Content Header (Page header) --> */}
          <PageHeader
            pName="Complaints"
            pDesc="Manage complaints by ID"
            handleBack={handleNavigate}
          />

          {/* // < !--Main content-- > */}
          <section className="content container-fluid">
            {/* <!-------------------------- | Your Page Content Here --------------------------> */}
            {/* overview */}
            <div className="row">
              <ComplaintInfoBox
                tag={complaint[0].properties.category}
                status={complaint[0].status}
                createdAt={complaint[0].createdAt}
                rUsername={complaint[0].review.username}
                reviewedAt={complaint[0].review.reviewedAt}
                isEditLoading={isEditLoading}
                handleResolve={handleResolve}
                handleUnResolve={handleUnresolve}
                handleComplaintDelete={handleComplaintDelete}
              />
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                {/* <!-- Box Comment --> */}
                <ContentInfoBox
                  createdAt={complaint[0].createdAt}
                  username={complaint[0].author.username}
                  photoUrl={complaint[0].author.photoURL}
                  banner={complaint[0].properties.banner}
                  title={complaint[0].properties.title}
                  subTitle={complaint[0].properties.category}
                  contentHTML={complaint[0].properties.description}
                  status={complaint[0].status}
                />
                {/* <!-- /.box --> */}
              </div>
              <div className="col-md-2"></div>
            </div>
            <div
              className="box box-widget widget-user-2"
              style={{
                position: "fixed",
                width: "fit-content",
                right: "50px",
                top: "110px",
              }}
            >
              <div className="box-footer">
                <div
                  className="btn-group spx"
                  style={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <p>Actions: </p>
                  <div
                    className="fx"
                    style={{ flex: "1", alignItems: "flex-start", gap: "10px" }}
                  >
                    {complaint[0].status !== 0 ? (
                      <button
                        type="button"
                        className="btn btn-default"
                        disabled={complaint[0].status === 0 || isEditLoading}
                        onClick={handleResolve}
                      >
                        Resolve complaint
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-default"
                        disabled={isEditLoading}
                        onClick={handleUnresolve}
                      >
                        Un-resolve complaint
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* // <!-- /.content --> */}
        </>
      ) : null}
    </>
  );
};

export default GetComplaintById;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { firestoreConnect, isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useNavigate, useParams } from 'react-router-dom';
import AbortableAlert from '../../../components/AbortableAlert';
import PageHeader from '../../../components/PageHeader';
import { deleteHouse, deleteHouseReview, publishHouse, unPublishHouse } from '../../../stores/edit/EditSlice';
import ContentInfoBox from './comps/ContentInfoBox';
import HouseInfoBox from './comps/HouseInfoBox';
import { compose } from 'redux';
import moment from 'moment';
import Ratings from '../../../components/Ratings';

const GetHouseById = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const [param, setParam] = useState(null);
    const [loading, setIsLoading] = useState(true);

    const housing = useSelector(
        ({ firestore: { ordered } }) => ordered[`${param}-ratings`]
    )

    const docs = useSelector(
        ({ firestore: { ordered } }) => ordered[`${window.location.href.split("/")[window.location.href.split("/").length - 1]}-docReviews`]
    )

    useEffect(() => {
        if (params) {
            setParam(params.id)
        }
    }, [params]);

    useFirestoreConnect(() => {
        return [
            {
                collection: "ratings",
                doc: param,
                storeAs: `${param}-ratings`
            }
        ]
    })


    const handleUnpublish = () => {
        if (window.confirm("Do you want to proceed?")) {
            dispatch(unPublishHouse(params.id))
                .then(() => {
                    AbortableAlert("House status successfully updated.");
                })
                .catch((err) => {
                    AbortableAlert(err);
                })
        }
    }

    const handlePublish = () => {
        if (window.confirm("Do you want to proceed?")) {
            dispatch(publishHouse(params.id))
                .then(() => {
                    AbortableAlert("House status successfully updated.");
                })
                .catch((err) => {
                    AbortableAlert(err);
                })
        }
    }

    const handleHouseDelete = () => {
        if (window.confirm("Do you want to proceed?")) {
            setIsLoading(false);
            dispatch(deleteHouse(params.id))
                .then(() => {
                    navigate("/admin/housing", { replace: true });
                })
                .catch((err) => {
                    AbortableAlert(err);
                })
        }
    }

    const handleRatingDelete = (id, doc) => {
        if (window.confirm("Do you want to proceed?")) {
            setIsLoading(false);
            const data = { id: id, doc: doc };
            dispatch(deleteHouseReview(data))
                .then(() => {
                    setIsLoading(true);
                    AbortableAlert("House review successfully deleted.");
                })
                .catch((err) => {
                    AbortableAlert(err);
                })
        }
    }

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(-1, { replace: true });
    }


    if (isLoaded(housing, docs) && loading) {
        if (!isEmpty(housing)) {
            return <>

                {/* <!-- Content Header (Page header) --> */}
                <PageHeader
                    pName="Housing"
                    pDesc="Manage house by ID"
                    handleBack={handleNavigate}
                />

                {/* // < !--Main content-- > */}
                < section className="content container-fluid" >

                    {/* <!-------------------------- | Your Page Content Here --------------------------> */}
                    {/* overview */}
                    <div className="row">
                        <HouseInfoBox
                            name={housing[0].building.name}
                            apartment={`Floor ${housing[0].building.floor}, Apt ${housing[0].building.apartment}`}
                            location={`${housing[0].building.city}, ${housing[0].building.area}`}
                            status={housing[0].status}
                            createdAt={housing[0].createdAt}
                            tReviews={housing[0].interactions.reviewCount}
                            docs={docs}
                            rUsername={housing[0].review.username}
                            reviewedAt={housing[0].review.reviewedAt}
                            handleHouseDelete={handleHouseDelete}
                            handlePublish={handlePublish}
                            handleUnpublish={handleUnpublish}
                        />
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                            {/* <!-- Box Comment --> */}
                            <ContentInfoBox
                                createdAt={housing[0].createdAt}
                                status={housing[0].status}
                                banners={housing[0].building.photos}
                                title={housing[0].building.name}
                                description={`${housing[0].building.city}, ${housing[0].building.area}`}
                            />
                            {/* <!-- /.box --> */}
                            {
                                docs &&
                                docs.map(doc => (
                                    <div className="box" data-widget="box-widget" key={doc.id}>
                                        <div className="box-header with-border">
                                            <div className="user-block">
                                                <img
                                                    className="img-circle"
                                                    src={doc.author.photoURL}
                                                    alt={doc.author.photoURL}
                                                    loading={'eager'}
                                                />
                                                <span
                                                    className="username">
                                                    <a href="#!">{doc.author.username}</a>
                                                </span>
                                                <span className="description">Shared publicly - {moment(doc.createdAt.toDate()).startOf('hour').fromNow()}</span>
                                            </div>
                                            {/* <!-- /.user-block --> */}
                                            <div className="box-tools">
                                                <button
                                                    type="button"
                                                    className="btn btn-box-tool"
                                                    data-widget="collapse" data-toggle="tooltip" title="Collapse"><i className="fa fa-minus"></i>
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-box-tool dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    title="actions"
                                                    aria-expanded="false"
                                                >
                                                    <i className="fa fa-ellipsis-v"></i>
                                                </button>
                                                <ul className="dropdown-menu" role="menu">
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn btn-default btn-action"
                                                            onClick={() => handleRatingDelete(params.id, doc.id)}
                                                        >
                                                            Delete rating
                                                        </button>
                                                    </li>

                                                </ul>
                                            </div>
                                            {/* <!-- /.box-tools --> */}
                                        </div>
                                        {/* <!-- /.box-header --> */}
                                        <div className="box-body" style={{ padding: ".5rem 1rem" }}>
                                            <div className="direct-chat-msg">
                                                <div className="direct-chat-text">
                                                    {doc.rating.comment}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-footer" style={{ padding: "0 2rem" }}>
                                            <div className="rating" style={{ display: "flex", alignItems: "center", gap: "1rem", padding: ".5rem 0" }}>
                                                Rated:
                                                <span>
                                                    <Ratings
                                                        ratingStarCount={
                                                            ((Number(doc.rating.communication) + Number(doc.rating.vfm) + Number(doc.rating.hApp)
                                                                + Number(doc.rating.services) + Number(doc.rating.security) + Number(doc.rating.interior) + Number(doc.rating.exterior) + Number(doc.rating.facility))
                                                                / 8).toFixed(1)
                                                        }
                                                        ratingNumberCount={
                                                            ((Number(doc.rating.communication) + Number(doc.rating.vfm) + Number(doc.rating.hApp)
                                                                + Number(doc.rating.services) + Number(doc.rating.security) + Number(doc.rating.interior) + Number(doc.rating.exterior) + Number(doc.rating.facility))
                                                                / 8).toFixed(1)
                                                        }
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        {/* <!-- /.box-body --> */}

                                    </div >
                                ))

                            }

                        </div>
                        <div className="col-md-2"></div>
                    </div >
                </section >
                {/* // <!-- /.content --> */}
            </>
        } else {
            return <>

                {/* <!-- Content Header (Page header) --> */}
                <PageHeader
                    pName="Housing"
                    pDesc="Manage house by ID"
                    handleBack={handleNavigate}
                />

                {/* // < !--Main content-- > */}
                < section className="content container-fluid" >
                    <h5>You have a broken link or a missing housing</h5>
                </section >
            </>
        }

    }
}

export default compose(
    firestoreConnect(props => {
        return [
            {
                collection: 'ratings',
                doc: window.location.href.split("/")[window.location.href.split("/").length - 1],
                subcollections: [{ collection: 'reviews' }],
                storeAs: `${window.location.href.split("/")[window.location.href.split("/").length - 1]}-docReviews`
            }
        ]
    })
)(GetHouseById)
import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Outlet } from 'react-router-dom'
import { compose } from 'redux'

const Appeals = ({ appeals, resolvedAppeals }) => {

    return (
        <div className="page-wrapper" style={{ width: "100%" }}>
            <Outlet context={{ appeals, resolvedAppeals }} />
        </div >

    )
}

const mapStateToProps = state => {
    const appeals = state.firestore.ordered.homeappeals;
    const resolvedAppeals = state.firestore.ordered.resolvedAppeals;
    return {
        appeals: appeals,
        resolvedAppeals: resolvedAppeals,
    }
}


export default compose(
    connect(mapStateToProps),
    firestoreConnect(
        [
            {
                collection: "appeals",
                where: ['status', '==', 1],
                orderBy: ["createdAt", 'asc'],
                storeAs: "homeappeals"
            },
            {
                collection: "appeals",
                where: ['status', '==', 0],
                orderBy: ["createdAt", 'asc'],
                storeAs: "resolvedAppeals"
            },
        ]
    )
)(Appeals)
import React from 'react'
import { Link } from 'react-router-dom'

const Overview = ({ users, posts, skills }) => {

    return <>
        <div className="col-lg-3 col-xs-6">
            {/* <!-- small box --> */}
            <div className="small-box bg-green">
                <div className="inner">
                    <h3>{posts && posts.length}<sup style={{ fontSize: "20px" }}></sup></h3>

                    <p>Pending Posts</p>
                </div>
                <div className="icon">
                    <i className="ion ion-alert"></i>
                </div>
                <Link to="posts/status/pending" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></Link>
            </div>
        </div>
        {/* <!-- ./col --> */}
        <div className="col-lg-3 col-xs-6">
            {/* <!-- small box --> */}
            <div className="small-box bg-red">
                <div className="inner">
                    <h3>{skills && skills.length}</h3>
                    <p>Pending Skills</p>
                </div>
                <div className="icon">
                    <i className="ion ion-android-playstore"></i>
                </div>
                <Link to="skills/status/pending" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></Link>
            </div>
        </div>
        {/* <!-- ./col --> */}
        <div className="col-lg-3 col-xs-6">
            {/* <!-- small box --> */}
            <div className="small-box bg-aqua">
                <div className="inner">
                    <h3>15</h3>

                    <p>New Reports</p>
                </div>
                <div className="icon">
                    <i className="ion ion-ios-flag"></i>
                </div>
                <a href="#!" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>

        {/* <!-- ./col --> */}
        <div className="col-lg-3 col-xs-6">
            {/* <!-- small box --> */}
            <div className="small-box bg-yellow">
                <div className="inner">
                    <h3>{users && users.length}</h3>

                    <p>User Registered</p>
                </div>
                <div className="icon">
                    <i className="ion ion-person-add"></i>
                </div>
                <Link to="users" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></Link>
            </div>
        </div>
        {/* <!-- ./col --> */}
    </>
}

export default Overview
import React from 'react'
import PageHeader from '../../../components/PageHeader'
import Overview from './Overview'
import { useOutletContext } from 'react-router-dom';

const PublishIndex = ({ children }) => {


    const posts = useOutletContext();

    let proppedChildren = React.Children.map(children, c => React.cloneElement(c, {
        posts: posts,
    }));

    return <>
        {/* <!-- Content Header (Page header) --> */}
        <PageHeader
            pName="Publication"
            pDesc="Manage publication"
        />

        {/* // < !--Main content-- > */}
        < section className="content container-fluid" >

            {/* <!-------------------------- | Your Page Content Here --------------------------> */}
            {/* overview */}
            <div className="row">
                <Overview />
            </div>


            {proppedChildren}


        </section >
        {/* // <!-- /.content --> */}
    </>
}

export default PublishIndex
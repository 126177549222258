import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PageHeader from '../../../components/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Overview from './Overview'
import { deleteReports, deleteReportsComment } from '../../../stores/edit/EditSlice'
import AbortableAlert from '../../../components/AbortableAlert'

const ReportIndex = () => {
    const [loading, setIsLoading] = useState(true);
    const dispatch = useDispatch();


    const posts = useSelector(
        ({ firestore: { ordered } }) => ordered.homepostreports && ordered.homepostreports
    )

    const ratings = useSelector(
        ({ firestore: { ordered } }) => ordered.homeratingreports && ordered.homeratingreports
    )

    const skills = useSelector(
        ({ firestore: { ordered } }) => ordered.homeskillreports && ordered.homeskillreports
    )

    const comments = useSelector(
        ({ firestore: { ordered } }) => ordered.homecommentreports && ordered.homecommentreports
    )

    const handleReportDelete = (id, type) => {
        if (window.confirm("Do you want to proceed?")) {
            setIsLoading(false);
            const params = { id: id, type: type };
            dispatch(deleteReports(params))
                .then(() => {
                    setIsLoading(true);
                    AbortableAlert("Report successfully deleted.");
                })
                .catch((err) => {
                    AbortableAlert(err);
                })
        }
    }

    const handleCommentDelete = (id, pid, postId, type) => {
        if (window.confirm("Do you want to proceed?")) {
            setIsLoading(false);
            const params = { id: id, pid: pid, postId: postId, type: type };
            dispatch(deleteReportsComment(params))
                .then(() => {
                    setIsLoading(true);
                    AbortableAlert("Comment successfully deleted.");
                })
                .catch((err) => {
                    AbortableAlert(err);
                })
        }
    }

    const navigate = useNavigate();


    if (loading) {
        return <>
            {/* <!-- Content Header (Page header) --> */}
            <PageHeader
                pName="Reports"
                pDesc="Manage reports"
            />

            {/* // < !--Main content-- > */}
            < section className="content container-fluid" >

                {/* <!-------------------------- | Your Page Content Here --------------------------> */}
                {/* overview */}
                <div className="row">
                    <Overview />
                </div>

                {/* appeal lisr */}
                <div className="row" style={{ marginTop: "4rem" }}>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="nav-tabs-custom">
                            <ul className="nav nav-tabs">
                                <li className="active"><a href="#posts" data-toggle="tab" aria-expanded="true">Posts</a></li>
                                <li className=""><a href="#skills" data-toggle="tab" aria-expanded="false">Skills</a></li>
                                <li className=""><a href="#ratings" data-toggle="tab" aria-expanded="true">Ratings</a></li>
                                <li className=""><a href="#comments" data-toggle="tab" aria-expanded="false">Comments</a></li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane active" id="posts">
                                    <div className="box-body no-padding">
                                        <table className="table table-striped">
                                            <tbody>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Reason</th>
                                                    <th>Date created</th>
                                                    <th>Report by</th>
                                                    <th>Actions</th>
                                                </tr>
                                                {
                                                    posts &&
                                                    posts.map(data => (
                                                        <tr key={data.id}>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <div className="user-wrapper">
                                                                        V-{data.id.toUpperCase()}
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    {data.properties.reason}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <i>{moment(data.reportCreatedAt.toDate()).format('ll')}</i>
                                                                </div>

                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <Link to={`/admin/users/${data.author.id}`} className="user-wrapper">
                                                                        View profile
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <div className="btn-group">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default dropdown-toggle"
                                                                            data-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                        </button>
                                                                        <ul className="dropdown-menu pos30">
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn-action"
                                                                                    onClick={() => navigate(`/admin/posts/id/${data.properties.contextId}`)}
                                                                                >
                                                                                    Review post
                                                                                </button>
                                                                            </li>
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn-action"
                                                                                    onClick={() => handleReportDelete(data.id, "posts")}
                                                                                >
                                                                                    Delete report
                                                                                </button>
                                                                            </li>

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="tab-pane" id="skills">
                                    <div className="box-body no-padding">
                                        <table className="table table-striped">
                                            <tbody>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Reason</th>
                                                    <th>Date created</th>
                                                    <th>Report by</th>
                                                    <th>Actions</th>
                                                </tr>
                                                {
                                                    skills &&
                                                    skills.map(data => (
                                                        <tr key={data.id}>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <div className="user-wrapper">
                                                                        V-{data.id.toUpperCase()}
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    {data.properties.reason}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <i>{moment(data.reportCreatedAt.toDate()).format('ll')}</i>
                                                                </div>

                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <Link to={`/admin/users/${data.author.id}`} className="user-wrapper">
                                                                        View profile
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <div className="btn-group">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default dropdown-toggle"
                                                                            data-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                        </button>
                                                                        <ul className="dropdown-menu pos30">
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn-action"
                                                                                    onClick={() => navigate(`/admin/skills/id/${data.properties.contextId}`)}
                                                                                >
                                                                                    Review skill
                                                                                </button>
                                                                            </li>
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn-action"
                                                                                    onClick={() => handleReportDelete(data.id, "skills")}
                                                                                >
                                                                                    Delete report
                                                                                </button>
                                                                            </li>

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="tab-pane" id="ratings">
                                    <div className="box-body no-padding">
                                        <table className="table table-striped">
                                            <tbody>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Reason</th>
                                                    <th>Date created</th>
                                                    <th>Report by</th>
                                                    <th>Actions</th>
                                                </tr>
                                                {
                                                    ratings &&
                                                    ratings.map(data => (
                                                        <tr key={data.id}>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <div className="user-wrapper">
                                                                        V-{data.id.toUpperCase()}
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    {data.properties.reason}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <i>{moment(data.reportCreatedAt.toDate()).format('ll')}</i>
                                                                </div>

                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <Link to={`/admin/users/${data.author.id}`} className="user-wrapper">
                                                                        View profile
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <div className="btn-group">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default dropdown-toggle"
                                                                            data-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                        </button>
                                                                        <ul className="dropdown-menu pos30">
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn-action"
                                                                                    onClick={() => navigate(`/admin/ratings/id/${data.properties.contextId}`)}
                                                                                >
                                                                                    Review rating
                                                                                </button>
                                                                            </li>
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn-action"
                                                                                    onClick={() => handleReportDelete(data.id, "ratings")}
                                                                                >
                                                                                    Delete report
                                                                                </button>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="tab-pane" id="comments">
                                    <div className="box-body no-padding">
                                        <table className="table table-striped">
                                            <tbody>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Reason</th>
                                                    <th>Remarks</th>
                                                    <th>Date created</th>
                                                    <th>Type</th>
                                                    <th>Actions</th>
                                                </tr>
                                                {
                                                    comments &&
                                                    comments.map(data => (
                                                        <tr key={data.id}>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <div className="user-wrapper">
                                                                        V-{data.id.toUpperCase()}
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    {data.properties.reason}
                                                                </div>
                                                            </td>
                                                            <td colSpan={1} style={{ width: '30%' }}>
                                                                <div className="user-wrapper">
                                                                    {data.properties.text}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <i>{moment(data.createdAt.toDate()).format('ll')}</i>
                                                                </div>

                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    {data.properties.type}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="user-wrapper">
                                                                    <div className="btn-group">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default dropdown-toggle"
                                                                            data-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                        </button>
                                                                        <ul className="dropdown-menu pos30">
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn-action"
                                                                                    onClick={
                                                                                        () => data.properties.type === "comment" ?
                                                                                            navigate(`/admin/posts/id/${data.properties.postId}`) :
                                                                                            data.properties.type === "subcomment" ?
                                                                                                navigate(`/admin/posts/id/${data.properties.postId}`) :
                                                                                                data.properties.type === "house" ?
                                                                                                    navigate(`/admin/housing/id/${data.properties.postId}`) :
                                                                                                    navigate(`/admin/skills/id/${data.properties.postId}`)
                                                                                    }
                                                                                >
                                                                                    View {data.properties.type === "comment" ? 'post' : data.properties.type === "subcomment" ? 'post' :
                                                                                        data.properties.type === "skill" ? 'skill' : 'house'}
                                                                                </button>
                                                                            </li>

                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn-action"
                                                                                    onClick={
                                                                                        () => data.properties.type === "comment" ?
                                                                                            handleCommentDelete(data.properties.contextId, "", data.properties.postId, "comment") :
                                                                                            data.properties.type === "subcomment" ?
                                                                                                handleCommentDelete(data.properties.contextId, data.properties.parentId, data.properties.postId, "subcomment") :
                                                                                                data.properties.type === "house" ?
                                                                                                    handleCommentDelete(data.properties.contextId, "", data.properties.postId, "house") :
                                                                                                    handleCommentDelete(data.properties.contextId, "", data.properties.postId, "skill")
                                                                                    }
                                                                                >
                                                                                    Delete comment
                                                                                </button>
                                                                            </li>
                                                                            <li>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn-action"
                                                                                    onClick={() => handleReportDelete(data.id, "comments")}
                                                                                >
                                                                                    Delete report
                                                                                </button>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section >
            {/* // <!-- /.content --> */}
        </>
    }

}

export default ReportIndex
import { compose, configureStore } from "@reduxjs/toolkit";
import fbConfig from '../config/fbConfig';
import { firestoreReducer, getFirestore, reduxFirestore } from "redux-firestore";
import { firebaseReducer, getFirebase } from "react-redux-firebase";
import AuthReducer from "./auth/AuthSlice";
import EditReducer from "./edit/EditSlice"

export const store = configureStore({
    reducer: {
        auth: AuthReducer,
        firebase: firebaseReducer,
        firestore: firestoreReducer,
        edit: EditReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: { getFirebase, getFirestore },
            },
            serializableCheck: false
        })
    ,
    enhancers: [compose(reduxFirestore(fbConfig))],

});
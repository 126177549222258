import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { signOut } from "../../stores/auth/AuthSlice";
import { resetAppeal } from "../../stores/edit/EditSlice";
import { useNavigate } from "react-router-dom";
import AbortableAlert from "../../components/AbortableAlert";

const Navbar = ({ posts, skills, ratings, appeals, complaints, donations }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.firebase.profile);
  const { account } = useSelector((state) => state.firebase.profile);

  const handleClick = (cat) => {
    dispatch(resetAppeal(cat))
      .then(() => {
        navigate(`/admin/${cat}s`);
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  if (isLoaded(user, posts, skills, ratings, appeals, complaints)) {
    const total = posts.length + skills.length + ratings.length;
    const comps = appeals.length + complaints.length;
    return (
      <header className="main-header">
        <a href="/" className="logo">
          {/* <!-- mini logo for sidebar mini 50x50 pixels --> */}
          <span className="logo-mini">
            <b>VOIS</b>
          </span>
          {/* <!-- logo for regular state and mobile devices --> */}
          <span className="logo-lg">
            <b>Admin</b>VOIS
          </span>
        </a>

        {/* <!-- Header Navbar --> */}
        <nav className="navbar navbar-static-top" role="navigation">
          {/* <!-- Sidebar toggle button--> */}
          <a
            href="#!"
            className="sidebar-toggle"
            data-toggle="push-menu"
            role="button"
          >
            <span className="sr-only">Toggle navigation</span>
          </a>
          {/* <!-- Navbar Right Menu --> */}
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              {/* <!-- Messages: style can be found in dropdown.less--> */}

              {/* <!-- /.messages-menu --> */}

              {/* <!-- Notifications Menu --> */}
              <li className="dropdown notifications-menu">
                {/* <!-- Menu toggle button --> */}
                <a href="#!" className="dropdown-toggle" data-toggle="dropdown">
                  <i className="fa fa-bell-o"></i>
                  {total > 0 && (
                    <span className="label label-warning">{total}</span>
                  )}
                </a>
                <ul className="dropdown-menu">
                  {total === 1 && (
                    <li className="header">
                      You have {total} new notification
                    </li>
                  )}
                  {total === 0 && (
                    <li className="header">You have no new notification</li>
                  )}
                  {total > 1 && (
                    <li className="header">
                      You have {total} new notifications
                    </li>
                  )}

                  <li>
                    {/* <!-- Inner Menu: contains the notifications --> */}
                    <ul className="menu">
                      {posts.length !== 0 ? (
                        <li>
                          {/* <!-- start notification --> */}
                          <p onClick={() => handleClick("post")}>
                            <i className="fa fa-users text-aqua"></i>{" "}
                            {posts.length} post{posts.length > 1 && "s"}{" "}
                            pending.
                          </p>
                        </li>
                      ) : null}
                      {skills.length !== 0 ? (
                        <li>
                          {/* <!-- start notification --> */}
                          <p onClick={() => handleClick("skill")}>
                            <i className="fa fa-users text-yellow"></i>{" "}
                            {skills.length} skill{skills.length > 1 && "s"}{" "}
                            pending.
                          </p>
                        </li>
                      ) : null}
                      {donations.length !== 0 ? (
                        <li>
                          {/* <!-- start notification --> */}
                          <p onClick={() => navigate(`/admin/donations`)}>
                            <i className="fa fa-users text-yellow"></i>{" "}
                            {donations.length} donation
                            {donations.length > 1 && "s"} available.
                          </p>
                        </li>
                      ) : null}
                      {ratings.length !== 0 ? (
                        <li>
                          {/* <!-- start notification --> */}
                          <a href={`/admin/ratings`}>
                            <i className="fa fa-users text-green"></i>{" "}
                            {ratings.length} rating{ratings.length > 1 && "s"}{" "}
                            pending
                          </a>
                        </li>
                      ) : null}

                      {/* <!-- end notification --> */}
                    </ul>
                  </li>
                </ul>
              </li>
              {/* <!-- Tasks Menu --> */}
              <li className="dropdown tasks-menu">
                {/* <!-- Menu Toggle Button --> */}
                <a href="#!" className="dropdown-toggle" data-toggle="dropdown">
                  <i className="fa fa-flag-o"></i>
                  {comps > 0 && (
                    <span className="label label-danger">{comps}</span>
                  )}
                </a>
                <ul className="dropdown-menu">
                  {comps === 1 && (
                    <li className="header">
                      You have {comps} new notification
                    </li>
                  )}
                  {comps === 0 && (
                    <li className="header">You have no new notification</li>
                  )}
                  {comps > 1 && (
                    <li className="header">
                      You have {comps} new notifications
                    </li>
                  )}{" "}
                  <li>
                    {/* <!-- Inner menu: contains the tasks --> */}
                    <ul className="menu">
                      {appeals.length !== 0 ? (
                        <li>
                          {/* <!-- start notification --> */}
                          <p
                            onClick={() => handleClick("appeal")}
                            style={{
                              padding: "10px",
                              margin: "0",
                              cursor: "pointer",
                            }}
                          >
                            <i className="fa fa-users text-aqua"></i>{" "}
                            {appeals.length} appeal{appeals.length > 1 && "s"}{" "}
                            pending.
                          </p>
                        </li>
                      ) : null}
                      {complaints.length !== 0 ? (
                        <li>
                          {/* <!-- start notification --> */}
                          <p
                            onClick={() => handleClick("complaint")}
                            style={{
                              padding: "10px",
                              margin: "0",
                              cursor: "pointer",
                            }}
                          >
                            <i className="fa fa-users text-yellow"></i>{" "}
                            {complaints.length} complaint
                            {complaints.length > 1 && "s"} pending.
                          </p>
                        </li>
                      ) : null}
                      {/* <!-- end task item --> */}
                    </ul>
                  </li>
                </ul>
              </li>
              {/* <!-- User Account Menu --> */}
              <li className="dropdown user user-menu">
                {/* <!-- Menu Toggle Button --> */}
                <a href="#!" className="dropdown-toggle" data-toggle="dropdown">
                  {/* <!-- The user image in the navbar--> */}
                  <img
                    src={user.photoURL}
                    className="user-image"
                    alt="User Banner"
                  />
                  {/* <!-- hidden-xs hides the username on small devices so only the image appears. --> */}
                  <span className="hidden-xs">{user.fullName}</span>
                </a>
                <ul className="dropdown-menu">
                  {/* <!-- The user image in the menu --> */}
                  <li className="user-header">
                    <img
                      src={user.photoURL}
                      className="img-circle"
                      alt={user.photoURL}
                    />

                    <p>
                      {user.fullName} -{" "}
                      {user.isAdmin === 1
                        ? "Rookie Admin"
                        : user.isAdmin === 2
                        ? "Super Admin"
                        : user.isAdmin === 3
                        ? "Dev Admin"
                        : ""}
                      <small>
                        Member since{" "}
                        {moment(account.createdAt.toDate()).format("ll")}
                      </small>
                    </p>
                  </li>
                  {/* <!-- Menu Body --> */}
                  {/* <li className="user-body">
                                        <div className="row">
                                            <div className="col-xs-4 text-center">
                                                <a href="#!">Followers</a>
                                            </div>
                                            <div className="col-xs-4 text-center">
                                            <a href="#!">Sales</a>
                                        </div>
                                        <div className="col-xs-4 text-center">
                                            <a href="#!">Friends</a>
                                        </div>
                                        </div>
                                    </li> */}
                  {/* <!-- Menu Footer--> */}
                  <li
                    className="user-footer"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* <div className="pull-left">
                                        <a href="#!" className="btn btn-default btn-flat">Profile</a>
                                    </div> */}
                    <div className="pull-right">
                      <button
                        onClick={() => dispatch(signOut())}
                        className="btn btn-default btn-flat"
                      >
                        Sign out
                      </button>
                    </div>
                  </li>
                </ul>
              </li>
              {/* <!-- Control Sidebar Toggle Button --> */}
              {/* <li>
                                <a href="#!" data-toggle="control-sidebar"><i className="fa fa-gears"></i></a>
                            </li> */}
            </ul>
          </div>
        </nav>
      </header>
    );
  }
};

export default Navbar;

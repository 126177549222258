import React from 'react'
import { useSelector } from 'react-redux'

const Overview = () => {
    const posts = useSelector(
        ({ firestore: { ordered } }) => ordered.homepostreports && ordered.homepostreports
    )

    const ratings = useSelector(
        ({ firestore: { ordered } }) => ordered.homeratingreports && ordered.homeratingreports
    )

    const skills = useSelector(
        ({ firestore: { ordered } }) => ordered.homeskillreports && ordered.homeskillreports
    )

    const comments = useSelector(
        ({ firestore: { ordered } }) => ordered.homecommentreports && ordered.homecommentreports
    )

    return <>
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="info-box">
                <span className="info-box-icon bg-aqua"><i className="fa fa-dot-circle-o"></i></span>
                <div className="info-box-content">
                    <span className="info-box-text">Posts</span>
                    <span className="info-box-number">{posts && posts.length}<small></small></span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="info-box">
                <span className="info-box-icon bg-green"><i className="fa fa-check-square-o"></i></span>

                <div className="info-box-content">
                    <span className="info-box-text">Skills</span>
                    <span className="info-box-number">{skills && skills.length}</span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}

        {/* <!-- fix for small devices only --> */}
        <div className="clearfix visible-sm-block"></div>

        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="info-box">
                <span className="info-box-icon bg-yellow"><i className="fa fa-exclamation"></i></span>

                <div className="info-box-content">
                    <span className="info-box-text">Housing</span>
                    <span className="info-box-number">{ratings && ratings.length}</span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="info-box">
                <span className="info-box-icon bg-red"><i className="fa fa-exclamation-circle"></i></span>

                <div className="info-box-content">
                    <span className="info-box-text">Comments</span>
                    <span className="info-box-number">{comments && comments.length}</span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}
        {/* <!-- fix for small devices only --> */}
        <div className="clearfix visible-sm-block"></div>
    </>
}

export default Overview
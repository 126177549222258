import React from 'react'
import { useOutletContext } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import Overview from './Overview';

const ResourcesIndex = ({ children }) => {

    const resources = useOutletContext();

    let proppedChildren = React.Children.map(children, c => React.cloneElement(c, {
        resources: resources,
    }));

    return <>
        {/* <!-- Content Header (Page header) --> */}
        <PageHeader
            pName="Resources"
            pDesc="Manage resources"
        />

        {/* // < !--Main content-- > */}
        < section className="content container-fluid" >

            {/* <!-------------------------- | Your Page Content Here --------------------------> */}
            {/* overview */}
            <div className="row">
                <Overview />
            </div>

            {proppedChildren}

        </section >
        {/* // <!-- /.content --> */}
    </>
}

export default ResourcesIndex
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { Link } from "react-router-dom";
// import avatar from "../../assets/imgs/avatar.jpeg"

const $ = window.jQuery;
const AsideLeftNav = () => {
  const { user } = useSelector((state) => state.firebase.profile);

  useEffect(() => {
    if (window.jQuery) {
      $('[data-widget="tree"]').tree();
      $('[data-widget="box-widget"]').boxWidget();
    }
  }, []);

  return (
    <aside className="main-sidebar">
      {/* <!-- sidebar: style can be found in sidebar.less --> */}
      <section className="sidebar" style={{ height: "auto" }}>
        {/* <!-- Sidebar user panel (optional) --> */}
        <div className="user-panel">
          <div className="pull-left image">
            <img
              src={user && user.photoURL}
              loading="eager"
              className="img-circle"
              alt={user && user.photoURL}
            />
          </div>
          <div className="pull-left info">
            <p>{user && user.fullName}</p>
            {/* <!-- Status --> */}
            {/* <a href="#!"><i className="fa fa-circle text-success"></i> Online</a> */}
          </div>
        </div>

        {/* <!-- search form (Optional) --> */}
        {/* <form action="#!" method="get" className="sidebar-form">
                    <div className="input-group">
                        <input type="text" name="q" className="form-control" placeholder="Search..." />
                        <span className="input-group-btn">
                            <button type="submit" name="search" id="search-btn" className="btn btn-flat"><i className="fa fa-search"></i>
                            </button>
                        </span>
                    </div>
                </form> */}
        {/* <!-- /.search form --> */}

        {/* <!-- Sidebar Menu --> */}
        <ul className="sidebar-menu tree" data-widget="tree">
          <li className="header">WEB APP</li>
          {/* <!-- Optionally, you can add icons to the links --> */}
          <li className="active">
            <Link to="">
              <i className="fa fa-home"></i> <span>Home</span>
            </Link>
          </li>
          {/* users */}
          {isLoaded(user) ? (
            user.isAdmin === 2 || user.isAdmin === 3 ? (
              <li className="treeview">
                <a href="#!">
                  <i className="fa fa-user"></i> <span>Manage users</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right"></i>
                  </span>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <Link to="users/create">Create user</Link>
                  </li>
                  <li>
                    <Link to="users">Regular users</Link>
                  </li>
                  <li>
                    <Link to="users/admin-users">Admin users</Link>
                  </li>
                </ul>
              </li>
            ) : null
          ) : null}
          {/* communities */}
          <li className="treeview">
            <a href="#!">
              <i className="fa fa-users"></i> <span>Manage community</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-left pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <Link to="posts">Manage Posts</Link>
              </li>
              <li>
                <Link to="skills">Manage Skills</Link>
              </li>
              <li>
                <Link to="housing">Manage Housing</Link>
              </li>
              <li>
                <Link to="features">Manage Top Stories</Link>
              </li>
              <li>
                <Link to="complaints">Manage Complaints</Link>
              </li>
              {/* <li>
                <Link to="appeals">Manage Appeals</Link>
              </li> */}
              <li>
                <Link to="reports">Manage Reports</Link>
              </li>
            </ul>
          </li>
          {/* only VOISCyprus can make a publication */}
          {isLoaded(user) ? (
            (user.username === "VOISCyprus" && user.isAdmin === 2) ||
            user.isAdmin === 3 ? (
              <li className="treeview">
                <a href="#!">
                  <i className="fa fa-database"></i>
                  <span>Manage publications</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right"></i>
                  </span>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <Link to="publish/create">Create publication</Link>
                  </li>
                  <li>
                    <Link to="publish">My publications</Link>
                  </li>
                </ul>
              </li>
            ) : null
          ) : null}

          {/* only admin 2 and 3 for rescources */}
          {isLoaded(user) ? (
            (user.username === "VOISCyprus" && user.isAdmin === 2) ||
            user.isAdmin === 3 ? (
              <li className="treeview">
                <a href="#!">
                  <i className="fa fa-database"></i>{" "}
                  <span>Manage resources</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right"></i>
                  </span>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <Link to="resources/create">Create resource</Link>
                  </li>
                  <li>
                    <Link to="resources">My resource</Link>
                  </li>
                </ul>
              </li>
            ) : null
          ) : null}
          {/* events */}
          <li>
            <Link to="events">
              <i className="fa fa-calendar" aria-hidden="true"></i>
              <span>Manage events</span>
            </Link>
          </li>
          {/* surveys */}
          <li>
            <Link to="surveys">
              <i className="fa fa-sticky-note" aria-hidden="true"></i>
              <span>Manage surveys</span>
            </Link>
          </li>

          {/* only admin 2 and 3 for miscellaneous and websites */}
          {isLoaded(user) ? (
            user.isAdmin === 2 || user.isAdmin === 3 ? (
              <>
                <li className="treeview">
                  <a href="#!">
                    <i className="fa fa-pencil"></i> <span>Miscellaneous</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right"></i>
                    </span>
                  </a>
                  <ul className="treeview-menu">
                    <li>
                      <Link to="misc">Legal resources</Link>
                    </li>
                    <li>
                      <Link to="misc/tags/forum">Manage Forum Tags</Link>
                    </li>
                    <li>
                      <Link to="misc/tags/skills">Manage Skill categories</Link>
                    </li>
                  </ul>
                </li>
                <li className="header">WEBSITE</li>
                <li className="treeview">
                  <a href="#!">
                    <i className="fa fa-gift"></i> <span>Manage donations</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right"></i>
                    </span>
                  </a>
                  <ul className="treeview-menu">
                    <li>
                      <Link to="donations">All donations</Link>
                    </li>
                  </ul>
                </li>
                <li className="treeview">
                  <a href="#!">
                    <i className="fa fa-user-circle"></i>{" "}
                    <span>Manage members</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right"></i>
                    </span>
                  </a>
                  <ul className="treeview-menu">
                    <li>
                      <Link to="members/create">Create member</Link>
                    </li>
                    <li>
                      <Link to="members">All members</Link>
                    </li>
                  </ul>
                </li>
                <li className="treeview">
                  <a href="#!">
                    <i className="fa fa-handshake-o"></i>{" "}
                    <span>Manage partners</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right"></i>
                    </span>
                  </a>
                  <ul className="treeview-menu">
                    <li>
                      <Link to="partners/create">Create partner</Link>
                    </li>
                    <li>
                      <Link to="partners">All partners</Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null
          ) : null}
        </ul>
        {/* <!-- /.sidebar-menu --> */}
      </section>
      {/* <!-- /.sidebar --> */}
    </aside>
  );
};

export default AsideLeftNav;

import React, { useEffect, useRef, useState } from "react";
import Quill from "quill/core";
import Block from "quill/blots/block";
import Inline from "quill/blots/inline";
import List, { ListItem } from "quill/formats/list";
import PageHeader from "../../../components/PageHeader";
import EditorContainer from "../../resources/partials/comps/EditorContainer";
import ToolbarButtons from "../../resources/partials/comps/ToolbarButtons";
import imageCompression from "browser-image-compression";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useNavigate, useParams } from "react-router-dom";
import AbortableAlert from "../../../components/AbortableAlert";
import { updateEvent } from "../../../stores/edit/EditSlice";
import { createEvent as makeEvent } from "ics";
import moment from "moment";
import ErrorPage from "../../error/ErrorPage";

class BoldBlot extends Inline {}

BoldBlot.blotName = "bold";
BoldBlot.tagName = "strong";

class ItalicBlot extends Inline {}

ItalicBlot.blotName = "italic";
ItalicBlot.tagName = "em";

class BlockBlot extends Block {}

BlockBlot.blotName = "blockquote";
BlockBlot.tagName = "blockquote";

class HeaderBlot extends Block {}

HeaderBlot.blotName = "header";
HeaderBlot.tagName = ["h1", "h2"];

Quill.register(BoldBlot);
Quill.register(ItalicBlot);
Quill.register(BlockBlot);
Quill.register(HeaderBlot);
Quill.register({
  "formats/list": List,
  "formats/list-item": ListItem,
});
Quill.debug(false);

const strip = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.innerHTML || "";
};
const $ = window.jQuery;

const GetEventById = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [param, setParam] = useState(null);

  const { isEditLoading } = useSelector((state) => state.edit);

  useEffect(() => {
    if (params) {
      setParam(params.id);
    }
  }, [params]);

  useFirestoreConnect(() => {
    return [
      {
        collection: "events",
        doc: param,
        storeAs: `${param}-locEvent`,
      },
    ];
  });

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(-1, { replace: true });
  };

  const locEvent = useSelector(
    ({ firestore: { ordered } }) => ordered[`${param}-locEvent`]
  );

  //quill ref to store quill instance
  const quillInstanceRef = useRef();
  //toolbar ref to access toolbar div
  const quillToolbarRef = useRef();
  //quill editor div
  const quillEditorRef = useRef();

  function datetimeLocal(datetime) {
    const dt = new Date(datetime.toDate());
    dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
    return dt.toISOString().slice(0, 16);
  }

  const [event, setEventState] = useState({
    title: "",
    eventDateStart: "",
    eventDateEnd: "",
    displayFrom: "",
    displayTo: "",
    contentPlain: "",
    contentHTML: "",
    loccity: "",
    locvenue: "",
    poster: "",
  });

  useEffect(() => {
    if (!isEmpty(locEvent)) {
      setEventState((state) => ({
        ...state,
        title: locEvent[0].properties.title,
        eventDateStart: datetimeLocal(locEvent[0].properties.dateStart),
        eventDateEnd: datetimeLocal(locEvent[0].properties.dateEnd),
        displayFrom: moment(locEvent[0].display.from.toDate()).format(
          "YYYY-MM-DD"
        ),
        displayTo: moment(locEvent[0].display.to.toDate()).format("YYYY-MM-DD"),
        contentPlain: locEvent[0].properties.contentPlain,
        contentHTML: locEvent[0].properties.contentHTML,
        loccity: locEvent[0].location.city,
        locvenue: locEvent[0].location.venue,
        poster: locEvent[0].properties.poster,
      }));
    }
  }, [locEvent]);

  // quill initialize
  useEffect(() => {
    quillInstanceRef.current = new Quill(quillEditorRef.current, {
      placeholder: "Start writing here...",
      scrollingContainer: ".box-template",
    });
    // quillInstanceRef.current.addContainer(quillToolbarRef.current);
    // console.log(quillInstanceRef.current)
  }, [quillInstanceRef, locEvent]);

  useEffect(() => {
    if (params.id && !isEmpty(locEvent)) {
      quillInstanceRef.current.root.innerHTML =
        locEvent[0].properties.contentHTML;
      quillInstanceRef.current.setSelection(
        quillInstanceRef.current.getLength(),
        0
      );
      setEventState((state) => ({
        ...state,
        title: locEvent[0].properties.title,
        eventDateStart: datetimeLocal(locEvent[0].properties.dateStart),
        eventDateEnd: datetimeLocal(locEvent[0].properties.dateEnd),
        displayFrom: moment(locEvent[0].display.from.toDate()).format(
          "YYYY-MM-DD"
        ),
        displayTo: moment(locEvent[0].display.to.toDate()).format("YYYY-MM-DD"),
        poster: locEvent[0].properties.poster,
        loccity: locEvent[0].location.city,
        locvenue: locEvent[0].location.venue,
      }));
    }
  }, [locEvent, params]);

  // console.log(locEvent)

  // ontextchange
  useEffect(() => {
    const quill = quillInstanceRef.current;
    const onTextChange = (type) => {
      const pGrafs = document.querySelectorAll(`#editor-container p`);
      const ulGrafs = document.querySelectorAll(`#editor-container ul`);
      setEventState((state) => ({
        ...state,
        contentPlain: quillInstanceRef.current.container.textContent,
        contentHTML: quillInstanceRef.current.root.innerHTML,
      }));
      if (quillInstanceRef.current.hasFocus()) {
        pGrafs.forEach((p) => {
          if (p.classList.length === 0) {
            p.classList.add("editor-p");
          }
        });
      }

      ulGrafs.forEach((ul) => {
        if (ul.classList.length === 0) {
          ul.classList.add("browser-default");
          ul.classList.add("editor-ul");
        }
      });

      handleContentHeight();
    };
    if (!isEmpty(locEvent)) {
      quill.on("text-change", onTextChange);
      return () => quill.off("text-change", onTextChange);
    }
  }, [quillInstanceRef, locEvent]);

  const getWordCount = () => {
    const editorContainer = $("#editor-container").get(0);
    const regex = /\s+/gi;
    const count =
      $(editorContainer).find(".ql-editor").text().length > 0
        ? $(editorContainer)
            .find(".ql-editor")
            .text()
            .trim()
            .replace(regex, " ")
            .split(" ").length
        : 0;
    return count;
  };

  // handle height modal ocntent
  const handleContentHeight = () => {
    if (getWordCount() >= 100 && getWordCount() <= 300) {
      document.querySelector("#editor-container").style.height = "350px";
      // console.log(getWordCount())
    } else if (getWordCount() >= 301 && getWordCount() <= 500) {
      document.querySelector("#editor-container").style.height = "601px";
      document.querySelector("#editor-container").style.top = "10%";
      // console.log(getWordCount())
    } else if (getWordCount() >= 501 && getWordCount() <= 2000) {
      document.querySelector("#editor-container").style.height = "701px";
    } else {
      document.querySelector("#editor-container").style.height = "unset";
      document.querySelector("#editor-container").style.minHeight = "229px";
    }
  };

  // toolbar formatting
  const handleFormatStyle = (evt) => {
    const quill = quillInstanceRef.current;
    const target = evt.currentTarget.id;
    const selection = quill.getSelection(() => false);
    const selectedFormat = quill.getFormat();

    const isSelectedFormatted = selectedFormat.hasOwnProperty(target)
      ? true
      : false;

    switch (target) {
      case "bold":
        isSelectedFormatted
          ? quill.formatText(selection.index, selection.length, "bold", false)
          : quill.formatText(selection.index, selection.length, "bold", true);
        break;
      case "italic":
        isSelectedFormatted
          ? quill.formatText(selection.index, selection.length, "italic", false)
          : quill.formatText(selection.index, selection.length, "italic", true);
        break;
      case "blockquote":
        isSelectedFormatted
          ? quill.format("blockquote", false)
          : quill.format("blockquote", true);
        break;
      case "header-1":
        isSelectedFormatted
          ? quill.removeFormat(selection.index, selection.length, "header")
          : quill.format("header", 1);
        break;
      case "list":
        isSelectedFormatted
          ? quill.format("list", false)
          : quill.format("list", true);
        break;
      case "list-ul":
        isSelectedFormatted
          ? quill.format("ulist", false)
          : quill.format("ulist", true);
        break;
      default:
    }
  };

  const handleOnChangeOptions = (evt) => {
    const options = [...evt.target.options]
      .filter((o) => o.selected)
      .map((o) => o.value)
      .toString();
    setEventState((state) => ({ ...state, [evt.target.name]: options }));
  };

  const options = [
    "Select a city",
    "Famagusta",
    "Iskele",
    "Nicosia",
    "Kyrenia",
  ];

  const updateFormValue = (evt) => {
    setEventState((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  // compressed image
  const compressImageFile = async (file) => {
    const options = {
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: "image/webp",
    };

    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  };

  // get title count
  const getTitleCount = () => {
    const titleContainer = $("#title").get(0);
    const regex = /\s+/gi;
    const count =
      $(titleContainer).text().length > 0
        ? $(titleContainer).text().trim().replace(regex, " ").split(" ").length
        : 0;
    return count;
  };

  // calculate post duration
  const calculatePostDuration = (wordCount) => {
    const titleCount = getTitleCount();
    const dividedBy = 200;
    const getInteger = (wordCount + titleCount) / dividedBy;
    const getDecimal = getInteger % 1;
    const roundInteger = Math.floor(getInteger) + Math.ceil(getDecimal * 0.6);

    return roundInteger;
  };

  const handleUpload = (evt) => {
    const [file] = evt.target.files;
    if (evt.target.id === "poster") {
      const compressedFile = compressImageFile(file);
      compressedFile.then((file) => {
        if (file) {
          setEventState((state) => ({ ...state, poster: file }));
        }
      });
    } else {
      setEventState((state) => ({ ...state, [evt.target.id]: file }));
    }
  };

  const handleSave = (evt) => {
    let blob;
    const name = "vois-event.ics";
    makeEvent(evt, (error, value) => {
      blob = new File([value], name, { type: "plain/text" });
    });
    return blob;
  };

  const publishEvent = () => {
    if (event.title === "" || event.title === null) {
      AbortableAlert("Add a title for this event.");
      return;
    }

    if (event.eventDateStart === "") {
      AbortableAlert("Add an event date and start time");
      return;
    }

    if (event.eventDateEnd === "") {
      AbortableAlert("Add an event date and end time");
      return;
    }

    if (event.displayFrom === "" && event.displayTo === "") {
      AbortableAlert("Add a display duration for this event");
      return;
    }

    if (event.contentPlain === "" || event.contentPlain === null) {
      AbortableAlert("More information required for this event.");
      return;
    }

    const dateStart = new Date(event.eventDateStart);
    const dateEnd = new Date(event.eventDateEnd);

    const evt = {
      start: [
        dateStart.getFullYear(),
        dateStart.getMonth() + 1,
        dateStart.getDate(),
        dateStart.getHours(),
        dateStart.getMinutes(),
      ],
      duration: {
        hours: dateEnd.getHours() - dateStart.getHours(),
        minutes: dateEnd.getMinutes() - dateStart.getMinutes(),
      },
      title: event.title,
      description: event.title,
      location: "Shamrock pub and bar",
      url: "https://www.voiscyprus.org",
      status: "CONFIRMED",
      busyStatus: "BUSY",
      organizer: { name: "Vois Cyprus", email: "voiscyprus@gmail.com" },
      attendees: [],
    };

    const data = {
      id: params.id,
      title: validator.escape(event.title.trim()),
      dateStart: event.eventDateStart,
      dateEnd: event.eventDateEnd,
      displayFrom: event.displayFrom,
      displayTo: event.displayTo,
      contentPlain: event.contentPlain,
      contentHTML: strip(event.contentHTML),
      loccity: event.loccity,
      locvenue: event.locvenue,
      poster: event.poster,
      icsFile: handleSave(evt),
    };

    // console.log(data)

    dispatch(updateEvent(data))
      .then((res) => {
        // console.log(res)
        AbortableAlert("Event updated successfully.");
        setTimeout(() => {
          navigate("/admin/events");
        }, 1000);
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  return (
    <>
      {isEmpty(locEvent) ? (
        <ErrorPage />
      ) : isLoaded(locEvent) ? (
        <>
          {" "}
          {/* <!-- Content Header (Page header) --> */}
          <PageHeader
            pName="Events"
            pDesc="Manage events by ID"
            handleBack={handleNavigate}
          />
          {/* // < !--Main content-- > */}
          <section className="content container-fluid">
            {/* <!-------------------------- | Your Page Content Here --------------------------> */}
            {/* overview */}
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10">
                {/* <!-- Box Comment --> */}
                <div className="create-form">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="box box-primary">
                        <div
                          className="box-body"
                          style={{ padding: "1rem 3rem" }}
                        >
                          <img
                            className="img-responsive max-banner pad"
                            style={{
                              paddingLeft: "0px",
                              paddingRight: "0px",
                              width: "200px",
                              height: "200px",
                            }}
                            src={locEvent[0].properties.poster}
                            loading="eager"
                            alt={locEvent[0].properties.poster}
                          />
                          <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              id="title"
                              defaultValue={locEvent[0].properties.title}
                              placeholder="Enter a title"
                              onChange={updateFormValue}
                            />
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-xs-6">
                                <label htmlFor="displayFrom">
                                  Display from:
                                </label>
                                <input
                                  type="date"
                                  name="displayFrom"
                                  id="displayFrom"
                                  className="form-control"
                                  placeholder="Display from"
                                  defaultValue={moment(
                                    locEvent[0].display.from.toDate()
                                  ).format("YYYY-MM-DD")}
                                  onChange={updateFormValue}
                                />
                              </div>
                              <div className="col-xs-6">
                                <label htmlFor="displayTo">Display to:</label>
                                <input
                                  type="date"
                                  name="displayTo"
                                  id="displayTo"
                                  className="form-control"
                                  placeholder="Display to"
                                  defaultValue={moment(
                                    locEvent[0].display.to.toDate()
                                  ).format("YYYY-MM-DD")}
                                  onChange={updateFormValue}
                                />
                              </div>
                              <div className="col-xs-12 form-group"></div>
                              <div className="col-xs-6">
                                <label htmlFor="eventDate">
                                  Event (date and start time)
                                </label>
                                <input
                                  type="datetime-local"
                                  name="eventDateStart"
                                  id="eventDateEnd"
                                  className="form-control"
                                  placeholder="Event (Local date and start time)"
                                  defaultValue={datetimeLocal(
                                    locEvent[0].properties.dateStart
                                  )}
                                  onChange={updateFormValue}
                                />
                              </div>
                              <div className="col-xs-6">
                                <label htmlFor="eventDateStart">
                                  Event (date and end time)
                                </label>
                                <input
                                  type="datetime-local"
                                  name="eventDateEnd"
                                  id="eventDateEnd"
                                  className="form-control"
                                  placeholder="Event (Local date and end time)"
                                  defaultValue={datetimeLocal(
                                    locEvent[0].properties.dateEnd
                                  )}
                                  onChange={updateFormValue}
                                />
                              </div>
                              <div className="col-xs-12 form-group"></div>
                              <div className="col-xs-6">
                                <label>Location (Select a city)</label>
                                <select
                                  className="form-control"
                                  defaultValue={locEvent[0].location.city}
                                  name="loccity"
                                  onChange={handleOnChangeOptions}
                                >
                                  {options.map((category) => (
                                    <option
                                      key={category}
                                      value={category}
                                      disabled={category === "Select a city"}
                                    >
                                      {category}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-xs-6">
                                <label htmlFor="locvenue">
                                  Location (venue)
                                </label>
                                <input
                                  type="text"
                                  name="locvenue"
                                  id="locvenue"
                                  defaultValue={locEvent[0].location.venue}
                                  className="form-control"
                                  placeholder="Enter a venue for the event"
                                  onChange={updateFormValue}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="poster">update event poster</label>
                            <input
                              type="file"
                              accept="image/*"
                              id="poster"
                              onChange={handleUpload}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputFile">
                              More information needed for event
                            </label>
                            <div className="box box-default">
                              <div
                                className="box-header  with-border"
                                style={{ padding: "0" }}
                              >
                                <ToolbarButtons
                                  quillToolbarRef={quillToolbarRef}
                                  handleFormatStyle={handleFormatStyle}
                                />
                              </div>
                              <div className="box-body box-template">
                                <EditorContainer
                                  quillEditorRef={quillEditorRef}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="box-footer fx"
                          style={{ padding: "1rem 3rem", gap: "1rem" }}
                        >
                          <button
                            type="submit"
                            className="btn btn-default"
                            onClick={handleNavigate}
                          >
                            cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={publishEvent}
                            disabled={isEditLoading}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* // <!-- /.content --> */}
        </>
      ) : null}
    </>
  );
};

export default GetEventById;

import React from 'react'
import { Link, useOutletContext } from 'react-router-dom';
import BoxCard from '../../../components/BoxCard';
import PageHeader from '../../../components/PageHeader';
import Overview from './Overview';

const SkillIndex = () => {
    const skills = useOutletContext();
    return <>
        {/* <!-- Content Header (Page header) --> */}
        <PageHeader
            pName="Skills"
            pDesc="Manage skills"
        />

        {/* // < !--Main content-- > */}
        < section className="content container-fluid" >

            {/* <!-------------------------- | Your Page Content Here --------------------------> */}
            {/* overview */}
            <div className="row">
                <Overview />
            </div>

            {/* skills lisr */}
            <div className="row">
                <div className="col-xs-12">
                    <section className="sub content-header" >
                        <h4 style={{ margin: "0" }}>
                            {skills && skills.length} skills available
                        </h4>
                        <div className="btn-group">
                            <button type="button" className="btn btn-default btn-edit dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                Filter by status
                                <span className="caret"></span>
                            </button>
                            <ul className="dropdown-menu pos30">
                                <li><Link to="status/pending"><span className='box-status bg-yellow'></span> Pending</Link></li>
                                <li><Link to="status/reviewed"><span className='box-status bg-green'></span> Reviewed</Link></li>
                                <li><Link to="status/rejected"><span className='box-status bg-red'></span> Rejected</Link></li>
                            </ul>
                        </div>
                    </section >
                    <div className="content-data">
                        <div className="row">
                            {skills &&
                                skills.map(skill => (
                                    <div key={skill.id} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                        <BoxCard
                                            id={skill.id}
                                            banner={skill.properties.banners[0]}
                                            message={skill.properties.description}
                                            title={skill.properties.title}
                                            status={skill.status}
                                            text="skill"
                                            type="parent"
                                            hide={false}
                                        />
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section >
        {/* // <!-- /.content --> */}
    </>
}

export default SkillIndex
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { isLoaded } from "react-redux-firebase";

const AdminUsers = ({
  adminusers,
  handleFilterChange,
  filter,
  handleNext,
  handlePrev,
  handleUserDelete,
  handleUserSuspension,
  maxDispLimit,
  indexDisp,
  isAuthLoading,
  filteredAdminUsers,
}) => {
  console.log(adminusers);

  const handleCSVData = () => {
    const header = [
      "User",
      "Email",
      "Created",
      "Suspended",
      "User role / Badge",
      "Mail subscription",
    ];
    const newData = [];

    filteredAdminUsers.forEach((data) => {
      const nData = [
        data.user.username,
        data.user.email,
        moment(data.account.createdAt.toDate()).format("ll"),
        data.account.isSuspended,
        data.user.isAdmin === 2 || data.user.isAdmin === 3
          ? "User/Admin"
          : "User" / data.user.badgeType === "volunteer"
          ? "Volunteer"
          : data.user.badgeType === "royalty"
          ? "Member"
          : "No badge",
        data.account.isMailSubscription ? "subscribed" : "not subscribed",
      ];
      newData.push(nData);
    });

    const csvData = [header, ...newData];

    return csvData;
  };

  if (isLoaded(filteredAdminUsers))
    return (
      <div className="user-table">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="box no-border">
              <div className="box-header spx" style={{ width: "100%" }}>
                <h3
                  className="box-title"
                  style={{ flex: 1, marginRight: "100px" }}
                >
                  Search
                </h3>
                <div
                  className="input-group exp"
                  style={{
                    width: "600px",
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <input
                    type="text"
                    name="user-search"
                    autoComplete="off"
                    value={filter}
                    onChange={handleFilterChange}
                    className="form-control"
                    placeholder="search for user by name or username..."
                  />
                  <CSVLink data={handleCSVData()} filename={"admin_users.csv"}>
                    Export CSV
                  </CSVLink>
                </div>
              </div>
              {/* <!-- /.box-header --> */}
              <div className="box-body no-padding">
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <th>User</th>
                      <th>Member since</th>
                      <th>Suspended</th>
                      <th>User role/Badge</th>
                      {/* <th>User role/Badge</th>
                                                <th>Mail Subscription</th> */}
                      <th>Contact</th>
                      <th>Actions</th>
                    </tr>
                    {filteredAdminUsers
                      .slice(indexDisp, maxDispLimit)
                      .map((data) => (
                        <tr key={data.id}>
                          <td>
                            <div className="user-wrapper">
                              <Link
                                to={`../${data.user.id}`}
                                className="user-wrapper"
                              >
                                <div className="user-img">
                                  <img
                                    src={data.user.photoURL}
                                    className="img-circle"
                                    alt={data.user.photoURL}
                                  />
                                </div>
                                <div className="user-desc">
                                  <p className="name">{data.user.fullName}</p>
                                  <p className="uname">@{data.user.username}</p>
                                </div>
                              </Link>
                            </div>
                          </td>
                          <td>
                            <div className="user-wrapper">
                              <i>
                                {moment(data.account.createdAt.toDate()).format(
                                  "ll"
                                )}
                              </i>
                            </div>
                          </td>
                          <td>
                            <div className="user-wrapper">
                              <span
                                className={`badge ${
                                  !data.account.isSuspended
                                    ? "bg-green"
                                    : "bg-red"
                                }`}
                              >
                                {data.account.isSuspended ? "true" : "false"}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="user-wrapper">
                              <p>
                                {data.user.isAdmin === 2 ||
                                data.user.isAdmin === 3
                                  ? "User/Admin"
                                  : "User"}
                                /
                                {data.user.badgeType === "volunteer" ? (
                                  "Volunteer"
                                ) : data.user.badgeType === "royalty" ? (
                                  "Member"
                                ) : (
                                  <i>No badge</i>
                                )}
                              </p>
                            </div>
                          </td>
                          {/* <td>
                                                            <div className="user-wrapper">
                                                                <p>
                                                                    {(data.user.isAdmin === 2 || data.user.isAdmin === 3) ? 'User/Admin' : 'User'}/
                                                                    {data.user.badgeType === "volunteer" ? 'Volunteer' :
                                                                        data.user.badgeType === "royalty" ? 'royalty' :
                                                                            <i>No badge</i>
                                                                    }
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="user-wrapper">
                                                                <span className={`badge ${data.account.isMailSubscription ? 'bg-green' : 'bg-red'}`}>
                                                                    {data.account.isMailSubscription ? 'subscribed' : 'not subscribed'}
                                                                </span>
                                                            </div>
                                                        </td> */}
                          <td>
                            <div className="user-wrapper">
                              <a
                                href={`mailto:${data.user.email}`}
                                type="button"
                                className="btn btn-warning btn-flat"
                              >
                                <i
                                  className="fa fa-envelope"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="user-wrapper">
                              <div className="btn-group">
                                <button
                                  type="button"
                                  className="btn btn-default dropdown-toggle"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i
                                    className="fa fa-ellipsis-v"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <ul className="dropdown-menu pos30">
                                  <li>
                                    {!data.account.isSuspended ? (
                                      <>
                                        <button
                                          type="button"
                                          className="btn btn-default btn-action"
                                          onClick={() =>
                                            handleUserSuspension(
                                              data.user.id,
                                              1
                                            )
                                          }
                                          disabled={isAuthLoading}
                                        >
                                          Suspend Account
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          type="button"
                                          className="btn btn-default btn-action"
                                          onClick={() =>
                                            handleUserSuspension(
                                              data.user.id,
                                              0
                                            )
                                          }
                                          disabled={isAuthLoading}
                                        >
                                          Un-Suspend Account
                                        </button>
                                      </>
                                    )}
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className="btn btn-default btn-action"
                                      onClick={() =>
                                        handleUserDelete(data.user.id)
                                      }
                                    >
                                      Delete Account
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {/* <!-- /.box-body --> */}
              <div className="box-footer spx" style={{ width: "100%" }}>
                <p
                  className="fx"
                  style={{ flex: "1", justifyContent: "flex-start" }}
                >
                  Showing {indexDisp + 1} -{" "}
                  {maxDispLimit > adminusers.length
                    ? adminusers.length
                    : maxDispLimit}{" "}
                  of {adminusers.length} entries
                </p>
                <div className="btn-group">
                  <button
                    onClick={handlePrev}
                    disabled={indexDisp <= 0}
                    type="button"
                    className="btn btn-default"
                  >
                    <i className="fa fa-caret-left" aria-hidden="true"></i>
                  </button>
                  <button
                    onClick={handleNext}
                    disabled={
                      maxDispLimit === adminusers.length ||
                      maxDispLimit > adminusers.length
                    }
                    type="button"
                    className="btn btn-default"
                  >
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default AdminUsers;

import React, { useEffect, useState } from "react";
import moment from "moment";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const $ = window.jQuery;
const ContentInfoBox = ({
  photoUrl,
  username,
  createdAt,
  banner,
  title,
  subTitle,
  contentHTML,
  status,
  handleUnpublish,
  handleUnpublishAndReject,
  addFeaturedPost,
  removeFeaturedPost,
  // handleAutoPublish,
  // handleRemoveAutoPublish
}) => {
  const [param, setParam] = useState(null);
  const params = useParams();
  const { isEditLoading } = useSelector((state) => state.edit);

  useEffect(() => {
    setParam(username);
  }, [username]);

  useFirestoreConnect(() => {
    return [
      {
        collection: "users",
        where: ["user.username", "==", param],
        storeAs: `${param}-data`,
      },
    ];
  });

  // const userInfo = useSelector(
  //     ({ firestore: { ordered } }) => ordered[`${param}-data`]
  // );
  const featuredPosts = useSelector(
    ({ firestore: { ordered } }) =>
      ordered[`featuredPosts`] && ordered[`featuredPosts`]
  );

  useEffect(() => {
    if (window.jQuery) {
      $('[data-widget="box-widget"]').boxWidget();
    }
  }, []);

  return (
    <div className="box" data-widget="box-widget">
      <div className="box-header with-border">
        <div className="user-block">
          <img
            className="img-circle"
            src={photoUrl}
            alt={photoUrl}
            loading={"eager"}
          />
          <span className="username">
            <a href="#!">{username}</a>
          </span>
          <span className="description">
            Shared publicly -{" "}
            {moment(createdAt.toDate()).startOf("hour").fromNow()}
          </span>
        </div>
        {/* <!-- /.user-block --> */}
        <div className="box-tools">
          <button
            type="button"
            className="btn btn-box-tool"
            data-widget="collapse"
            data-toggle="tooltip"
            title="Collapse"
          >
            <i className="fa fa-minus"></i>
          </button>
          <button
            type="button"
            className="btn btn-box-tool dropdown-toggle"
            data-toggle="dropdown"
            title="actions"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v"></i>
          </button>
          <ul className="dropdown-menu" role="menu">
            {isLoaded(featuredPosts) &&
            featuredPosts[0].data.includes(params.id) ? (
              <li>
                <button
                  type="button"
                  className="btn btn-default btn-action"
                  onClick={removeFeaturedPost}
                >
                  Remove from featured List
                </button>
              </li>
            ) : (
              <li>
                <button
                  type="button"
                  className="btn btn-default btn-action"
                  disabled={status === 1 || status === 2}
                  onClick={addFeaturedPost}
                >
                  Add to featured List
                </button>
              </li>
            )}

            <li>
              <button
                type="button"
                className="btn btn-default btn-action"
                disabled={status === 1 || status === 2 || isEditLoading}
                onClick={handleUnpublish}
              >
                Un-publish post
              </button>
            </li>
            <li>
              <button
                type="button"
                className="btn btn-default btn-action"
                disabled={status === 1 || status === 2 || isEditLoading}
                onClick={handleUnpublishAndReject}
              >
                Un-publish post and reject
              </button>
            </li>
            {/* <li className="divider"></li>
                        <li>
                            <button
                                type="button"
                                className="btn btn-default btn-action"
                                disabled={(userInfo && userInfo[0].user.isAdmin === 1) || isEditLoading}
                                onClick={handleAutoPublish}
                            >
                                Enable Auto-publish ({username})
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                className="btn btn-default btn-action"
                                disabled={(userInfo && userInfo[0].user.isAdmin === 0) || isEditLoading}
                                onClick={handleRemoveAutoPublish}
                            >
                                Disable Auto-publish({username})
                            </button>
                        </li> */}
          </ul>
        </div>
        {/* <!-- /.box-tools --> */}
      </div>
      {/* <!-- /.box-header --> */}
      <div className="box-body">
        {banner && (
          <img
            className="img-responsive max-banner pad"
            src={banner}
            loading="eager"
            alt={banner}
          />
        )}

        <div className="post-title padSide">
          <h5>{title}</h5>
        </div>
        <div className="post-title padSide sub">
          <h5>{subTitle}</h5>
        </div>
        <div
          className="post-content"
          dangerouslySetInnerHTML={{ __html: contentHTML }}
        ></div>
      </div>
      {/* <!-- /.box-body --> */}
    </div>
  );
};

export default ContentInfoBox;

import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import PageHeader from '../../components/PageHeader'
import Overview from './partials/Overview'

const Dashboard = ({ posts, skills, users }) => {
  return (
    <div className="page-wrapper" style={{ width: "100%" }}>
      {/* <!-- Content Header (Page header) --> */}
      <PageHeader
        pName="Home"
        pDesc="General overview"
        pCurrent="Dashboard"
      />

      {/* // < !--Main content-- > */}
      < section className="content container-fluid" >

        {/* <!-------------------------- | Your Page Content Here --------------------------> */}
        <div className="row">
          <Overview
            posts={posts}
            skills={skills}
            users={users}
          />
        </div>

      </section >
      {/* // <!-- /.content --> */}
    </div >

  )
}

const mapStateToProps = state => {
  const posts = state.firestore.ordered.dashposts;
  const skills = state.firestore.ordered.dashskills;
  const users = state.firestore.ordered.dashusers;

  return {
    posts: posts,
    skills: skills,
    users: users,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(
    [
      {
        collection: "posts",
        where: ['status', '==', 2],
        storeAs: "dashposts"
      },
      {
        collection: "skills",
        where: ['status', '==', 2],
        storeAs: "dashskills"
      },
      {
        collection: "users",
        storeAs: "dashusers"
      }
    ]
  )
)(Dashboard)
import React from 'react'
import { firestoreConnect } from 'react-redux-firebase'
import { Outlet } from 'react-router-dom'
import { compose } from 'redux'

const Reports = () => {

    return (
        <div className="page-wrapper" style={{ width: "100%" }}>
            <Outlet />
        </div >

    )
}



export default compose(
    firestoreConnect(
        [
            {
                collection: "reports",
                doc: 'types',
                subcollections: [{ collection: 'posts' }],
                storeAs: "homepostreports"
            },
            {
                collection: "reports",
                doc: 'types',
                subcollections: [{ collection: 'ratings' }],
                storeAs: "homeratingreports"
            },
            {
                collection: "reports",
                doc: 'types',
                subcollections: [{ collection: 'skills' }],
                storeAs: "homeskillreports"
            },
            {
                collection: "reports",
                doc: 'types',
                subcollections: [{ collection: 'comments' }],
                orderBy: ['createdAt', 'desc'],
                storeAs: "homecommentreports"
            },
        ]
    )
)(Reports)
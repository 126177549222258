import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Outlet } from 'react-router-dom'
import { compose } from 'redux'

const Complaints = ({ complaints, resolvedcomplaints }) => {

    return (
        <div className="page-wrapper" style={{ width: "100%" }}>
            <Outlet context={{ complaints, resolvedcomplaints }} />
        </div >

    )
}

const mapStateToProps = state => {
    const complaints = state.firestore.ordered.homecomplaints;
    const resolvedcomplaints = state.firestore.ordered.resolvedcomplaints;
    return {
        complaints: complaints,
        resolvedcomplaints: resolvedcomplaints
    }
}


export default compose(
    connect(mapStateToProps),
    firestoreConnect(
        [
            {
                collection: "complaints",
                where: ['status', '==', 1],
                orderBy: ["createdAt", 'asc'],
                storeAs: "homecomplaints"
            },
            {
                collection: "complaints",
                where: ['status', '==', 0],
                orderBy: ["createdAt", 'asc'],
                storeAs: "resolvedcomplaints"
            },
            {
                collection: "complaints",
                where: ['properties.category', '==', 'General'],
                orderBy: ["createdAt", 'asc'],
                storeAs: "generalComplaints"
            },
            {
                collection: "complaints",
                where: ['properties.category', '==', 'Skillshop'],
                orderBy: ["createdAt", 'asc'],
                storeAs: "skillshopComplaints"
            },
            {
                collection: "complaints",
                where: ['properties.category', '==', 'Housing'],
                orderBy: ["createdAt", 'asc'],
                storeAs: "housingComplaints"
            },
            {
                collection: "complaints",
                where: ['properties.category', '==', 'Immigration'],
                orderBy: ["createdAt", 'asc'],
                storeAs: "immigrationComplaints"
            },
            {
                collection: "complaints",
                where: ['properties.category', '==', 'About App'],
                orderBy: ["createdAt", 'asc'],
                storeAs: "aboutAppComplaints"
            },
        ]
    )
)(Complaints)
import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Outlet } from 'react-router-dom'
import { compose } from 'redux'

const Users = ({ users, adminusers }) => {


    console.log(adminusers)
    return (
        <div className="page-wrapper" style={{ width: "100%" }}>
            <Outlet context={{ users, adminusers }} />
        </div >

    )
}

const mapStateToProps = state => {
    const users = state.firestore.ordered.homeusers;
    const adminusers = state.firestore.ordered.adminusers;
    return {
        users: users,
        adminusers: adminusers
    }
}


export default compose(
    connect(mapStateToProps),
    firestoreConnect(
        [
            {
                collection: "users",
                where: ['user.isAdmin', '==', 0],
                orderBy: ["account.createdAt"],
                storeAs: "homeusers"
            },
            {
                collection: "users",
                where: [['user.isAdmin', '>', 0], ['user.isAdmin', '<', 3]],
                storeAs: "adminusers"
            },
            {
                collection: "users",
                where: ['account.isRegCompleted', '==', true],
                storeAs: "regusers"
            },
            {
                collection: "users",
                where: ['account.isSuspended', '==', true],
                storeAs: "sususers"
            },
        ]
    )
)(Users)
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SET__AUTH__ERROR } from '../../stores/auth/AuthSlice';
import { ValidateData } from './ValidateData';
import { ValidationContext } from './ValidationContext';


const FormValidate = ({
    children,
    data,
    rules,
    text,
    submit,
    view,
    handleCancel
}) => {

    // selectors
    const { isAuthLoading } = useSelector(state => state.auth);

    // dispatcher
    const dispatch = useDispatch();

    // states
    const [formOptions, setFormOptions] = useState({
        errors: {},
        dirty: {},
        isSubmitted: false
    });

    // derviced state
    useEffect(() => {
        setFormOptions(state => ({ ...state, errors: ValidateData(data, rules) }));
    }, [data, rules]);

    useEffect(() => {
        if (formOptions.isSubmitted) {
            const dataObj = { ...data }
            dispatch(submit(dataObj));
            // console.log(dataObj)
        }
        return () => setFormOptions(state => ({ ...state, isSubmitted: false }));
    }, [formOptions.isSubmitted, submit, dispatch]);

    const handleFormChange = (evt) => {
        setFormOptions(state => ({ ...state, dirty: { ...state.dirty, [evt.target.name]: evt.target.value } }));
        dispatch(SET__AUTH__ERROR(null))

    }

    const handlebuttonClick = (e) => {
        e.preventDefault();
        setFormOptions(state => ({ ...state, isSubmitted: true }));
    }



    const getElementErrorMessage = (element) => {
        return (formOptions.isSubmitted || formOptions.dirty[element])
            ? formOptions.errors[element] || [] : []
    }

    const isButtonDisabled = () => {
        return (Object.keys(formOptions.errors).length === 0 && Object.keys(formOptions.dirty).length !== 0);
    }
    const messages = { getElementErrorMessage: getElementErrorMessage }
    const contextData = { ...formOptions, ...messages }

    return <>

        <ValidationContext.Provider value={contextData}>
            <form className="auth-form-group" onChange={handleFormChange} onSubmit={handlebuttonClick}>
                {children}

                {
                    (view === "login") ?
                        <>
                            <div className="row">
                                <div className="col-xs-8">
                                    <div className="checkbox icheck">
                                        <label>
                                            <input type="checkbox" /> Remember Me
                                        </label>
                                    </div>
                                </div>
                                {/* <!-- /.col --> */}
                                <div className="col-xs-4">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block btn-flat"
                                        disabled={!isButtonDisabled() || isAuthLoading}
                                    >{isAuthLoading ? "Loading" : text}
                                    </button>
                                </div>
                                {/* <!-- /.col --> */}
                            </div>
                        </>
                        :
                        (view === "create") ?
                            <>
                                <div className="row">
                                    <div className="col-xs-2">
                                        <button
                                            type="submit"
                                            className="btn btn-default btn-block btn-flat"
                                            onClick={handleCancel}
                                        >cancel
                                        </button>
                                    </div>
                                    {/* <!-- /.col --> */}
                                    <div className="col-xs-2">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-block btn-flat"
                                            disabled={!isButtonDisabled() || isAuthLoading}
                                        >{isAuthLoading ? "Loading" : text}
                                        </button>
                                    </div>
                                    {/* <!-- /.col --> */}
                                </div>
                            </> : null
                }
            </form>
        </ValidationContext.Provider>

    </>
}

export default FormValidate
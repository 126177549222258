import React from 'react'
import { useSelector } from 'react-redux'

const Overview = ({ users }) => {


    const regusers = useSelector(
        ({ firestore: { ordered } }) => ordered['regusers']
    )

    const adminusers = useSelector(
        ({ firestore: { ordered } }) => ordered['adminusers']
    )

    const sususers = useSelector(
        ({ firestore: { ordered } }) => ordered['sususers']
    )
    return <>
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="info-box">
                <span className="info-box-icon bg-aqua"><i className="fa fa-dot-circle-o"></i></span>
                <div className="info-box-content">
                    <span className="info-box-text">Total Users</span>
                    <span className="info-box-number">{users && users.length}<small></small></span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="info-box">
                <span className="info-box-icon bg-red"><i className="fa fa-dot-circle-o"></i></span>
                <div className="info-box-content">
                    <span className="info-box-text">Admin Users</span>
                    <span className="info-box-number">{adminusers && adminusers.length}<small></small></span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="info-box">
                <span className="info-box-icon bg-green"><i className="fa fa-check-square-o"></i></span>

                <div className="info-box-content">
                    <span className="info-box-text">Registered Users</span>
                    <span className="info-box-number">{regusers && regusers.length}</span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}

        {/* <!-- fix for small devices only --> */}
        <div className="clearfix visible-sm-block"></div>

        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="info-box">
                <span className="info-box-icon bg-yellow"><i className="fa fa-exclamation"></i></span>

                <div className="info-box-content">
                    <span className="info-box-text">Suspended Users</span>
                    <span className="info-box-number">{sususers && sususers.length}</span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}

    </>
}

export default Overview
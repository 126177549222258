import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import imageCompression from "browser-image-compression";
import AbortableAlert from "../../../components/AbortableAlert";
import { createMember } from "../../../stores/edit/EditSlice";

const CreateMember = () => {
  const { isEditLoading } = useSelector((state) => state.edit);
  const [view, setView] = useState(false);
  const dispatch = useDispatch();
  const [member, setMemberState] = useState({
    poster: "",
    name: "",
    type: "",
    category: "",
    title: "",
    description: "",
    link: "",
    linkedin: "",
  });

  const updateFormValue = (evt) => {
    setMemberState((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  const handleOnChangeOptions = (evt) => {
    const options = [...evt.target.options]
      .filter((o) => o.selected)
      .map((o) => o.value)
      .toString();
    setMemberState((state) => ({ ...state, [evt.target.name]: options }));
  };

  // compressed image
  const compressImageFile = async (file) => {
    const options = {
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: "image/webp",
    };

    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  };

  const handleUpload = (evt) => {
    const [file] = evt.target.files;
    if (evt.target.id === "poster") {
      const compressedFile = compressImageFile(file);
      compressedFile.then((file) => {
        if (file) {
          setMemberState((state) => ({ ...state, poster: file }));
        }
      });
    }
  };

  const handleAddMember = () => {
    if (member.poster === null || member.poster === "") {
      AbortableAlert("Add a suitable image for this member!");
      return;
    }

    if (member.name === "" || member.name === null) {
      AbortableAlert("Add a name for this member");
      return;
    }

    if (member.type === "" || member.type === "Select member type") {
      AbortableAlert("Select a type for this member");
      return;
    }

    if (member.title === "" || member.title === null) {
      AbortableAlert("Add a title for this member");
      return;
    }

    const data = {
      name: member.name,
      title: member.title,
      poster: member.poster,
      type: member.type === "0" ? "Founder" : "Others",
      category:
        member.category === "0"
          ? "Board"
          : member.category === "1"
          ? "Departments"
          : member.category === "2"
          ? "Committees"
          : "",
      description: member.description,
      linkedin: member.linkedin,
      link: member.link,
    };

    dispatch(createMember(data))
      .then((res) => {
        AbortableAlert("Member created successfully.");
        setView(false);
        setMemberState((state) => ({
          ...state,
          poster: "",
          name: "",
          type: "",
          category: "",
          title: "",
          description: "",
          link: "",
          linkedin: "",
        }));
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  return (
    <div className="user-table">
      <button
        type="button"
        className="btn btn-sm btn-primary user-btn"
        onClick={() => setView(true)}
      >
        Create a member/founder
      </button>
      {view && (
        <div className="create-form">
          <div className="row">
            <div className="col-md-6">
              <div className="box box-primary">
                <div
                  className="box-header with-border"
                  style={{ padding: "1rem 3rem" }}
                >
                  <h3 className="box-title">Create a Member/Founder</h3>
                </div>
                <div className="box-body" style={{ padding: "1rem 3rem" }}>
                  <div className="form-group">
                    <label htmlFor="poster">Member image</label>
                    <input
                      type="file"
                      accept="image/*"
                      id="poster"
                      onChange={handleUpload}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">
                      Member name (eg: "Soian Churi")
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Enter a tag (eg, Soian Churi)"
                      value={member.name}
                      onChange={updateFormValue}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="type">Member type</label>
                    <select
                      className="form-control"
                      name="type"
                      id="type"
                      onChange={handleOnChangeOptions}
                      defaultValue="Select member type"
                    >
                      <option value="Select member type" disabled>
                        Select type
                      </option>
                      <option value="0">Founder</option>
                      <option value="1">Board/Committee/Department</option>
                    </select>
                  </div>
                  {member.type === "1" && (
                    <div className="form-group">
                      <label htmlFor="category">
                        Member category (e.g. Department)
                      </label>
                      <select
                        className="form-control"
                        name="category"
                        id="category"
                        onChange={handleOnChangeOptions}
                        defaultValue="Select member category"
                      >
                        <option value="Select member category" disabled>
                          Select category
                        </option>
                        <option value="0">Board</option>
                        <option value="1">Departments</option>
                        <option value="2">Committees</option>
                      </select>
                    </div>
                  )}

                  <div className="form-group">
                    <label htmlFor="title">
                      Member title (eg: "Speaker, Activist, Media head")
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      id="title"
                      placeholder="Member title (eg: Speaker, Activists)"
                      value={member.title}
                      onChange={updateFormValue}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">
                      Member description (eg: "Lorem ipsium disium")
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="description"
                      id="description"
                      placeholder="Member description (eg: Lorem ipsium disium)"
                      value={member.description}
                      onChange={updateFormValue}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="link">
                      Member's personal Url (eg: "https://www.joeybidden.com")
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="link"
                      id="link"
                      placeholder="Member personal Url (eg: https://www.joeybidden.com)"
                      value={member.link}
                      onChange={updateFormValue}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="linkedin">
                      Member's LinkedIn URL (eg:
                      "https://www.linkedin.com/12332")
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="linkedin"
                      id="linkedin"
                      placeholder=" Member's LinkeIn URL (eg:
                        https://www.linkedin.com/12332)"
                      value={member.linkedin}
                      onChange={updateFormValue}
                    />
                  </div>
                </div>
                <div
                  className="box-footer fx"
                  style={{ padding: "1rem 3rem", gap: "1rem" }}
                >
                  <button
                    type="submit"
                    className="btn btn-default"
                    onClick={() => setView(false)}
                  >
                    cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleAddMember}
                    disabled={isEditLoading}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateMember;

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AbortableAlert from '../../../components/AbortableAlert';
import PageHeader from '../../../components/PageHeader'
import { createSurvey } from '../../../stores/edit/EditSlice';
import AllSurveys from './AllSurveys';

const SurveyIndex = () => {

    const [view, setView] = useState(false);
    const dispatch = useDispatch();

    const { isEditLoading } = useSelector(state => state.edit);


    const [survey, setSurveyState] = useState({
        title: "",
        link: "",
        displayFrom: "",
        displayTo: ""
    });

    const updateFormValue = (evt) => {
        setSurveyState(state => ({ ...state, [evt.target.name]: evt.target.value }));
    }


    const publishSurvey = () => {

        if (survey.title === "" || survey.title === null) {
            AbortableAlert("Add a title for this survey.");
            return;
        }

        if (survey.link === "") {
            AbortableAlert("Add a link for this survey");
            return;
        }

        if (survey.displayFrom === "" && survey.displayTo === "") {
            AbortableAlert("Add a display duration for this survey");
            return;
        }

        const data = {
            title: survey.title,
            link: survey.link,
            displayFrom: survey.displayFrom,
            displayTo: survey.displayTo
        }


        dispatch(createSurvey(data))
            .then((res) => {
                AbortableAlert("Survey created successfully.");
                setView(false);
                setSurveyState(state => ({
                    ...state,
                    title: "",
                    link: "",
                    displayFrom: "",
                    displayTo: ""
                }))
            })
            .catch((err) => {
                AbortableAlert(err);
            })

    }

    return <>
        {/* <!-- Content Header (Page header) --> */}
        <PageHeader
            pName="Surveys"
            pDesc="Manage surveys"
        />

        {/* // < !--Main content-- > */}
        < section className="content container-fluid" >
            <div className="user-table">
                <button
                    type="button"
                    className="btn btn-sm btn-primary user-btn"
                    onClick={() => setView(true)}
                >
                    Create a survey
                </button>

                {
                    view &&
                    <div className="create-form">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="box box-primary">
                                    <div className="box-header with-border" style={{ padding: "1rem 3rem" }}>
                                        <h3 className="box-title">Create a survey</h3>
                                    </div>
                                    <div className="box-body" style={{ padding: "1rem 3rem" }}>
                                        <div className="form-group">
                                            <label htmlFor="title">Title</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="title"
                                                id="title"
                                                placeholder="Enter a title"
                                                value={survey.title}
                                                onChange={updateFormValue}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <label htmlFor="line">Link</label>
                                                    <input
                                                        type="text"
                                                        name="link"
                                                        id="link"
                                                        className="form-control"
                                                        placeholder="Enter https://your url here"
                                                        value={survey.link}
                                                        onChange={updateFormValue}
                                                    />
                                                </div>
                                                <div className="col-xs-12">
                                                    <div className="form-group"></div>
                                                </div>
                                                <div className="col-xs-6">
                                                    <label htmlFor="displayFrom">Display from:</label>
                                                    <input
                                                        type="date"
                                                        name="displayFrom"
                                                        id="displayFrom"
                                                        className="form-control"
                                                        placeholder="Display from"
                                                        value={survey.displayFrom}
                                                        onChange={updateFormValue}
                                                    />
                                                </div>
                                                <div className="col-xs-6">
                                                    <label htmlFor="title">Display to:</label>
                                                    <input
                                                        type="date"
                                                        name="displayTo"
                                                        id="displayTo"
                                                        className="form-control"
                                                        placeholder="Display to"
                                                        value={survey.displayTo}
                                                        onChange={updateFormValue}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer fx" style={{ padding: "1rem 3rem", gap: "1rem" }}>
                                        <button
                                            type="submit"
                                            className="btn btn-default"
                                            onClick={() => setView(false)}
                                        >
                                            cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            disabled={isEditLoading}
                                            onClick={publishSurvey}
                                        >
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>

            <AllSurveys/>
        </section>
    </>
}

export default SurveyIndex
import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Outlet } from "react-router-dom";
import { compose } from "redux";

const Donations = ({ donors, donated, notDonated }) => {
  return (
    <div className="page-wrapper" style={{ width: "100%" }}>
      <Outlet context={{ donors, donated, notDonated }} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const donated = state.firestore.ordered.donated;
  const donors = state.firestore.ordered.donors;
  const notDonated = state.firestore.ordered.notdonated;
  return {
    donors: donors,
    donated: donated,
    notDonated: notDonated,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: "donations",
      where: ["status", "==", 0],
      storeAs: "donated",
    },
    {
      collection: "donations",
      storeAs: "donors",
    },
    {
      collection: "donations",
      where: ["status", "==", 1],
      storeAs: "notdonated",
    },
  ])
)(Donations);

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useNavigate, useParams } from 'react-router-dom';
import AbortableAlert from '../../../components/AbortableAlert';
import PageHeader from '../../../components/PageHeader';
import { acceptSkill, deleteSkill, rejectSkill, revertToPendingSkill, unPublishSkill, unPublishSkillAndReject } from '../../../stores/edit/EditSlice';
import ContentInfoBox from './comps/ContentInfoBox';
import SkillInfoBox from './comps/SkillInfoBox';

const GetSkillById = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const [param, setParam] = useState(null);
    const [loading, setIsLoading] = useState(true);

    const skill = useSelector(
        ({ firestore: { ordered } }) => ordered[`${param}-skills`]
    )

    useEffect(() => {
        if (params) {
            setParam(params.id)
        }
    }, [params]);

    useFirestoreConnect(() => {
        return [
            {
                collection: "skills",
                doc: param,
                storeAs: `${param}-skills`
            }
        ]
    })

    const publishSkill = () => {
        dispatch(acceptSkill(params.id))
            .then(() => {
                AbortableAlert("Skill status successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const isRejectSkill = () => {
        dispatch(rejectSkill(params.id))
            .then(() => {
                AbortableAlert("Skill status successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleUnpublish = () => {
        dispatch(unPublishSkill(params.id))
            .then(() => {
                AbortableAlert("Skill status successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleUnpublishAndReject = () => {
        dispatch(unPublishSkillAndReject(params.id))
            .then(() => {
                AbortableAlert("Skill status successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleRevertToPending = () => {
        dispatch(revertToPendingSkill(params.id))
            .then(() => {
                AbortableAlert("Post status successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleSkillDelete = () => {
        if (window.confirm("Do you want to proceed?")) {
            setIsLoading(false);
            dispatch(deleteSkill(params.id))
                .then(() => {
                    navigate("/admin/skills", { replace: true });
                })
                .catch((err) => {
                    AbortableAlert(err);
                })
        }
    }

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(-1, { replace: true });
    }


    if (isLoaded(skill) && loading) {
        if (!isEmpty(skill)) {
            return <>

                {/* <!-- Content Header (Page header) --> */}
                <PageHeader
                    pName="Skills"
                    pDesc="Manage Skills by ID"
                    handleBack={handleNavigate}
                />

                {/* // < !--Main content-- > */}
                < section className="content container-fluid" >

                    {/* <!-------------------------- | Your Page Content Here --------------------------> */}
                    {/* overview */}
                    <div className="row">
                        <SkillInfoBox
                            category={skill[0].properties.category}
                            isJob={skill[0].properties.isJob}
                            status={skill[0].status}
                            createdAt={skill[0].createdAt}
                            rUsername={skill[0].review.username}
                            reviewedAt={skill[0].review.reviewedAt}
                            handleUnpublish={handleUnpublish}
                            handlePublish={publishSkill}
                            handleSkillReject={isRejectSkill}
                            handleRevertToPending={handleRevertToPending}
                            handleSkillDelete={handleSkillDelete}
                        />
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                            {/* <!-- Box Comment --> */}
                            <ContentInfoBox
                                createdAt={skill[0].createdAt}
                                username={skill[0].author.username}
                                status={skill[0].status}
                                photoUrl={skill[0].author.photoURL}
                                banners={skill[0].properties.banners}
                                title={skill[0].properties.title}
                                description={skill[0].properties.description}
                                contentHTML={skill[0].properties.serviceHTML}
                                handleUnpublish={handleUnpublish}
                                handlePublish={publishSkill}
                                handleUnpublishAndReject={handleUnpublishAndReject}
                                text="skill"
                            />
                            {/* <!-- /.box --> */}
                        </div>
                        <div className="col-md-2"></div>
                    </div >
                </section >
                {/* // <!-- /.content --> */}
            </>
        } else {
            return <>

                {/* <!-- Content Header (Page header) --> */}
                <PageHeader
                    pName="Skills"
                    pDesc="Manage Skills by ID"
                    handleBack={handleNavigate}
                />

                {/* // < !--Main content-- > */}
                < section className="content container-fluid" >
                    <h5>You have a broken link or a missing skill</h5>
                </section >
            </>
        }

    }
}

export default GetSkillById
import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";

const Donors = ({
  donors,
  handleFilterChange,
  filter,
  handleNext,
  handlePrev,
  maxDispLimit,
  indexDisp,
  filteredDonors,
  handleDonorUpdate,
}) => {
  const handleCSVData = () => {
    const header = [
      "Donor",
      "Email",
      "phone",
      "type",
      "amount",
      "Pledge date",
      "Company",
      "Donated",
    ];
    const newData = [];

    filteredDonors.forEach((data) => {
      const nData = [
        data.donor.name,
        data.donor.email,
        data.donor.phone,
        data.type,
        "$" + data.amount,
        moment(data.createdAt.toDate()).format("ll"),
        data.isOrg ? data.company : "personal",
        data.status === 0 ? "Donated" : "No donations yet",
      ];
      newData.push(nData);
    });

    const csvData = [header, ...newData];

    return csvData;
  };

  return (
    <div className="user-table">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="box no-border">
            <div className="box-header spx" style={{ width: "100%" }}>
              <h3
                className="box-title"
                style={{ flex: 1, marginRight: "100px" }}
              >
                Search
              </h3>
              <div
                className="input-group exp"
                style={{
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <input
                  type="text"
                  name="user-search"
                  autoComplete="off"
                  value={filter}
                  onChange={handleFilterChange}
                  className="form-control"
                  placeholder="search for donor by name"
                />
                <CSVLink data={handleCSVData()} filename={"donor_lists.csv"}>
                  Export CSV
                </CSVLink>
              </div>
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body no-padding">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th>Donor</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Pledge date</th>
                    <th>Company</th>
                    <th>Donated?</th>
                    <th>Actions</th>
                  </tr>
                  {filteredDonors.slice(indexDisp, maxDispLimit).map((data) => (
                    <tr key={data.id}>
                      <td>
                        <div className="user-wrapper">
                          <i className="name">{data.donor.name}</i>
                        </div>
                      </td>
                      <td>
                        <div className="user-wrapper">
                          <i className="name">{data.donor.email}</i>
                        </div>
                      </td>
                      <td>
                        <div className="user-wrapper">
                          <i className="name">{data.donor.phone}</i>
                        </div>
                      </td>
                      <td>
                        <div className="user-wrapper">
                          <i className="name">{data.type}</i>
                        </div>
                      </td>
                      <td>
                        <div className="user-wrapper">
                          <i className="name">{"$" + data.amount}</i>
                        </div>
                      </td>
                      <td>
                        <div className="user-wrapper">
                          <i>{moment(data.createdAt.toDate()).format("ll")}</i>
                        </div>
                      </td>
                      <td>
                        <div className="user-wrapper">
                          <i>{data.isOrg ? data.company : "Personal"}</i>
                        </div>
                      </td>
                      <td>
                        <div className="user-wrapper">
                          <i>
                            {data.status === 0 ? "Donated" : "No Donations yet"}
                          </i>
                        </div>
                      </td>

                      {data.status === 1 && (
                        <td>
                          <div className="user-wrapper">
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-default dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i
                                  className="fa fa-ellipsis-v"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <ul className="dropdown-menu pos30">
                                <li>
                                  <button
                                    type="button"
                                    className="btn btn-default btn-action"
                                    onClick={() =>
                                      handleDonorUpdate(data.id)
                                    }
                                  >
                                    Mark Donated
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <!-- /.box-body --> */}
            <div className="box-footer spx" style={{ width: "100%" }}>
              <p
                className="fx"
                style={{ flex: "1", justifyContent: "flex-start" }}
              >
                Showing {indexDisp + 1} -{" "}
                {maxDispLimit > donors.length ? donors.length : maxDispLimit} of
                {donors.length} entries
              </p>
              <div className="btn-group">
                <button
                  onClick={handlePrev}
                  disabled={indexDisp <= 0}
                  type="button"
                  className="btn btn-default"
                >
                  <i className="fa fa-caret-left" aria-hidden="true"></i>
                </button>
                <button
                  onClick={handleNext}
                  disabled={
                    maxDispLimit === donors.length ||
                    maxDispLimit > donors.length
                  }
                  type="button"
                  className="btn btn-default"
                >
                  <i className="fa fa-caret-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donors;

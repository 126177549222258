import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useNavigate, useParams } from "react-router-dom";
import AbortableAlert from "../../../components/AbortableAlert";
import BoxCard from "../../../components/BoxCard";
import PageHeader from "../../../components/PageHeader";
import {
  deleteUserById,
  SET_AUTH_LOADING,
  SET__AUTH__ERROR,
  suspendUserById,
} from "../../../stores/auth/AuthSlice";
import {
  assignMemberBadge,
  assignRookieAdmin,
  assignSuperAdmin,
  assignSuperBadge,
  assignVolunteerBadge,
  autoApprove,
  removeAdmin,
  removeAutoApprove,
  removeBadge,
} from "../../../stores/edit/EditSlice";
import ErrorPage from "../../error/ErrorPage";

const GetUserById = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();

  const [param, setParam] = useState(null);
  const locUser = useSelector((state) => state.firebase.profile.user);

  const user = useSelector(
    ({ firestore: { ordered } }) => ordered[`${param}-user`]
  );

  const posts = useSelector(
    ({ firestore: { ordered } }) => ordered[`${param}-posts`]
  );

  const skills = useSelector(
    ({ firestore: { ordered } }) => ordered[`${param}-skills`]
  );

  const ratings = useSelector(
    ({ firestore: { ordered } }) => ordered[`${param}-ratings`]
  );

  const complaints = useSelector(
    ({ firestore: { ordered } }) => ordered[`${param}-complaints`]
  );

  useEffect(() => {
    if (params) {
      setParam(params.id);
    }
  }, [params]);

  const handleAutoPublish = () => {
    dispatch(autoApprove(user[0].user.id))
      .then(() => {
        AbortableAlert("User privileges successfully updated.");
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  const handleVolunteerBadge = () => {
    dispatch(assignVolunteerBadge(user[0].user.id))
      .then(() => {
        AbortableAlert("User badge successfully updated.");
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  const handleMemberBadge = () => {
    dispatch(assignMemberBadge(user[0].user.id))
      .then(() => {
        AbortableAlert("User badge successfully updated.");
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  const handleSuperBadge = () => {
    dispatch(assignSuperBadge(user[0].user.id))
      .then(() => {
        AbortableAlert("User badge successfully updated.");
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  const handleRookieAdmin = () => {
    dispatch(assignRookieAdmin(user[0].user.id))
      .then(() => {
        AbortableAlert("User privileges successfully updated.");
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  const handleSuperAdmin = () => {
    dispatch(assignSuperAdmin(user[0].user.id))
      .then(() => {
        AbortableAlert("User privileges successfully updated.");
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  const stripAdmin = () => {
    dispatch(removeAdmin(user[0].user.id))
      .then(() => {
        AbortableAlert("User privileges successfully updated.");
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  const stripBadge = () => {
    dispatch(removeBadge(user[0].user.id))
      .then(() => {
        AbortableAlert("User badge successfully updated.");
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  const handleRemoveAutoPublish = () => {
    dispatch(removeAutoApprove(user[0].user.id))
      .then(() => {
        AbortableAlert("User privileges successfully updated.");
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  const handleUserDelete = (id) => {
    let errMessage = "";
    dispatch(deleteUserById(id)).then((result) => {
      // console.log(result)
      if (!result.payload.data.hasOwnProperty("errorInfo")) {
        AbortableAlert("User has been deleted");
        dispatch(SET_AUTH_LOADING());
        dispatch(SET__AUTH__ERROR(null));
      } else {
        switch (result.payload.data.errorInfo.code) {
          default:
            errMessage = "Something went wrong. Try again later";
            dispatch(SET__AUTH__ERROR({ errMessage }));
        }
      }
    });
  };

  const handleUserSuspension = (id, type) => {
    console.log(id, type);
    dispatch(suspendUserById({ id: id, type: type }))
      .then((result) => {
        // console.log(result)
        AbortableAlert("User suspension status updated.");
      })
      .catch((err) => {
        AbortableAlert("Something went wrong. Try again");
      });
  };

  useFirestoreConnect(() => {
    return [
      {
        collection: "users",
        doc: param,
        storeAs: `${param}-user`,
      },
      {
        collection: "posts",
        where: [
          ["author.id", "==", param],
          ["status", "==", "0"],
          ["status", "==", "1"],
          ["status", "==", "2"],
        ],
        orderBy: ["createdAt", "desc"],
        storeAs: `${param}-posts`,
      },
      {
        collection: "skills",
        where: ["author.id", "==", param],
        orderBy: ["createdAt", "desc"],
        storeAs: `${param}-skills`,
      },
      {
        collection: "ratings",
        where: ["author.id", "==", param],
        orderBy: ["createdAt", "desc"],
        storeAs: `${param}-ratings`,
      },
      {
        collection: "complaints",
        where: ["author.id", "==", param],
        orderBy: ["createdAt", "desc"],
        storeAs: `${param}-complaints`,
      },
    ];
  });

  const handleNavigate = () => {
    navigate(-1, { replace: true });
  };

  return (
    <>
      {isEmpty(user) ? (
        <ErrorPage />
      ) : isLoaded(user, locUser) ? (
        <>
          {/* <!-- Content Header (Page header) --> */}
          <PageHeader
            pName="Users"
            pDesc="Manage users by ID"
            handleBack={handleNavigate}
          />

          {/* // < !--Main content-- > */}
          <section className="content container-fluid">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <div className="box box-primary">
                <div className="box-body box-profile">
                  <img
                    className="profile-user-img img-responsive img-circle"
                    src={user[0].user.photoURL}
                    alt="User profile"
                  />

                  <h3 className="profile-username text-center">
                    {user[0].user.fullName}
                  </h3>

                  <p className="text-muted text-center">
                    @{user[0].user.username}
                  </p>

                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <b>Member since</b>{" "}
                      <i className="pull-right">
                        {moment(user[0].account.createdAt.toDate()).format(
                          "ll"
                        )}
                      </i>
                    </li>
                    <li className="list-group-item">
                      <b>Role/Badge</b>{" "}
                      <i className="pull-right">
                        {user[0].user.isAdmin === 2 ||
                        user[0].user.isAdmin === 3
                          ? "User/Admin"
                          : "User"}
                        /
                        {user[0].user.badgeType === "volunteer" ? (
                          "Volunteer"
                        ) : user[0].user.badgeType === "royalty" ? (
                          "royalty"
                        ) : (
                          <i>No badge</i>
                        )}
                      </i>
                    </li>
                    <li className="list-group-item">
                      <b>Posts</b>{" "}
                      <i className="pull-right">{posts && posts.length}</i>
                    </li>
                    <li className="list-group-item">
                      <b>Skills</b>{" "}
                      <i className="pull-right">{skills && skills.length}</i>
                    </li>
                    <li className="list-group-item">
                      <b>Ratings</b>{" "}
                      <i className="pull-right">{ratings && ratings.length}</i>
                    </li>
                    <li className="list-group-item">
                      <b>Complaints</b>{" "}
                      <i className="pull-right">
                        {complaints && complaints.length}
                      </i>
                    </li>
                  </ul>
                  <button className="btn btn-default btn-block">
                    <b>Mail Account</b>
                  </button>

                  {(locUser.isAdmin === 2 || locUser.isAdmin === 3) && (
                    <div
                      className="btn-group btn-block"
                      style={{ width: "100%", display: "flex" }}
                    >
                      <button
                        type="button"
                        className="btn btn-default"
                        style={{ flex: "1" }}
                      >
                        Change role / badge
                      </button>
                      <button
                        type="button"
                        className="btn btn-default dropdown-toggle"
                        style={{ width: "30px" }}
                        data-toggle="dropdown"
                      >
                        <span className="caret"></span>
                        <span className="sr-only">Toggle Dropdown</span>
                      </button>
                      <ul className="dropdown-menu" role="menu">
                        <li>
                          <a href="#!" onClick={handleVolunteerBadge}>
                            Assign Volunteer badge
                          </a>
                        </li>
                        <li>
                          <a href="#!" onClick={handleMemberBadge}>
                            Assign Member badge
                          </a>
                        </li>
                        <li>
                          <a href="#!" onClick={stripBadge}>
                            Remove badge
                          </a>
                        </li>
                        {locUser.isAdmin === 3 && (
                          <li>
                            <a href="#!" onClick={handleSuperBadge}>
                              Assign Super badge
                            </a>
                          </li>
                        )}
                        <li className="divider"></li>
                        <li>
                          <a href="#!" onClick={handleRookieAdmin}>
                            Assign rookie admin
                          </a>
                        </li>
                        <li>
                          <a href="#!" onClick={handleSuperAdmin}>
                            Assign super admin
                          </a>
                        </li>
                        <li>
                          <a href="#!" onClick={stripAdmin}>
                            Remove admin
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}

                  {user[0].account.isAutoApprove ? (
                    <button
                      className="btn btn-primary btn-block"
                      onClick={handleRemoveAutoPublish}
                    >
                      <b>Disable Auto-approve</b>
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary btn-block"
                      onClick={handleAutoPublish}
                    >
                      <b>Enable Auto-approve</b>
                    </button>
                  )}
                  {user[0].account.isSuspended ? (
                    <button
                      className="btn btn-warning btn-block"
                      onClick={() => handleUserSuspension(params.id, 0)}
                    >
                      <b>Un-suspend Account</b>
                    </button>
                  ) : (
                    <button
                      className="btn btn-warning btn-block"
                      onClick={() => handleUserSuspension(params.id, 1)}
                    >
                      <b>Suspend Account</b>
                    </button>
                  )}
                  <button className="btn btn-danger btn-block">
                    <b>Delete Account</b>
                  </button>
                </div>
              </div>

              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">More on user</h3>
                </div>
                <div className="box-body" style={{ width: "100%" }}>
                  <strong>
                    <i className="fa fa-book margin-r-5"></i> Bio
                  </strong>
                  <p className="text-muted">{user[0].user.bio}</p>
                  <hr />
                  <strong>
                    <i className="fa fa-pencil margin-r-5"></i> Socials
                  </strong>
                  <p>
                    <span
                      style={{ marginRight: "5px" }}
                      className="label label-danger"
                    >
                      LinkedIn
                    </span>
                    <span
                      style={{ marginRight: "5px" }}
                      className="label label-success"
                    >
                      Portfolio
                    </span>
                    <span
                      style={{ marginRight: "5px" }}
                      className="label label-info"
                    >
                      Facebook
                    </span>
                    <br />
                    <br />
                    <span
                      style={{ marginRight: "5px" }}
                      className="label label-warning"
                    >
                      Twitter
                    </span>
                    <span
                      style={{ marginRight: "5px" }}
                      className="label label-warning"
                    >
                      Link
                    </span>
                    <span
                      style={{ marginRight: "5px" }}
                      className="label label-warning"
                    >
                      Instagram
                    </span>
                  </p>
                  <hr />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
              <div className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                  <li className="active">
                    <a href="#posts" data-toggle="tab" aria-expanded="true">
                      Posts
                    </a>
                  </li>
                  <li className="">
                    <a href="#skills" data-toggle="tab" aria-expanded="false">
                      Skills
                    </a>
                  </li>
                  <li className="">
                    <a href="#ratings" data-toggle="tab" aria-expanded="false">
                      Ratings
                    </a>
                  </li>
                  <li className="">
                    <a
                      href="#complaints"
                      data-toggle="tab"
                      aria-expanded="false"
                    >
                      Complaints
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active" id="posts">
                    <div className="row greybg">
                      {posts && !isEmpty(posts) ? (
                        posts.map((post) => (
                          <div
                            key={post.id}
                            className="col-md-4 col-sm-6 col-xs-12"
                          >
                            <BoxCard
                              id={post.id}
                              banner={post.properties.banner}
                              message={post.properties.contentPlain}
                              title={post.properties.title}
                              status={post.status}
                              text="post"
                              type="outer"
                              where="posts"
                            />
                          </div>
                        ))
                      ) : (
                        <i style={{ padding: "1rem" }}>
                          There are no posts for this user
                        </i>
                      )}
                    </div>
                  </div>
                  <div className="tab-pane" id="skills">
                    <div className="row greybg">
                      {skills && !isEmpty(skills) ? (
                        skills.map((skill) => (
                          <div
                            key={skill.id}
                            className="col-md-4 col-sm-6 col-xs-12"
                          >
                            <BoxCard
                              id={skill.id}
                              banner={skill.properties.banners[0]}
                              message={skill.properties.description}
                              title={skill.properties.title}
                              status={skill.status}
                              text="skill"
                              type="outer"
                              where="skills"
                            />
                          </div>
                        ))
                      ) : (
                        <i style={{ padding: "1rem" }}>
                          There are no skills for this user
                        </i>
                      )}
                    </div>
                  </div>
                  <div className="tab-pane" id="ratings">
                    <div className="row greybg">
                      {ratings && !isEmpty(ratings) ? (
                        ratings.map((rating) => (
                          <div
                            key={rating.id}
                            className="col-md-4 col-sm-6 col-xs-12"
                          >
                            <BoxCard
                              id={rating.id}
                              banner={rating.building.photos[0]}
                              message={`${rating.building.apartment}, ${rating.building.floor}, ${rating.building.city}`}
                              title={`${rating.building.name}, ${rating.building.area}`}
                              status={rating.status}
                              text="skill"
                              type="outer"
                              where="ratings"
                            />
                          </div>
                        ))
                      ) : (
                        <i style={{ padding: "1rem" }}>
                          There are no ratings for this user
                        </i>
                      )}
                    </div>
                  </div>
                  <div className="tab-pane" id="complaints">
                    <div className="row greybg">
                      {complaints && !isEmpty(complaints) ? (
                        complaints.map((complaint) => (
                          <div
                            key={complaint.id}
                            className="col-md-4 col-sm-6 col-xs-12"
                          >
                            <BoxCard
                              id={complaint.id}
                              banner={complaint.properties.banner[0]}
                              message={complaint.properties.description}
                              title={complaint.properties.title}
                              status={complaint.status}
                              text="complaint"
                              type="outer"
                              where="complaints"
                            />
                          </div>
                        ))
                      ) : (
                        <i style={{ padding: "1rem" }}>
                          There are no complaints for this user
                        </i>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : null}
    </>
  );
};

export default GetUserById;

import React, { useEffect, useRef, useState } from 'react'
import Quill from 'quill/core';
import Block from 'quill/blots/block';
import Inline from 'quill/blots/inline';
import List, { ListItem } from 'quill/formats/list';
import EditorContainer from './comps/EditorContainer';
import ToolbarButtons from './comps/ToolbarButtons';
import imageCompression from 'browser-image-compression';
import AbortableAlert from '../../../components/AbortableAlert';
import { useDispatch, useSelector } from 'react-redux';
import { createPublish } from '../../../stores/edit/EditSlice';
import validator from 'validator';



class BoldBlot extends Inline { }

BoldBlot.blotName = "bold";
BoldBlot.tagName = "strong";

class ItalicBlot extends Inline { }

ItalicBlot.blotName = "italic";
ItalicBlot.tagName = "em";


class BlockBlot extends Block { }

BlockBlot.blotName = "blockquote";
BlockBlot.tagName = "blockquote";

class HeaderBlot extends Block { }

HeaderBlot.blotName = "header";
HeaderBlot.tagName = ["h1", "h2"];


Quill.register(BoldBlot);
Quill.register(ItalicBlot);
Quill.register(BlockBlot);
Quill.register(HeaderBlot);
Quill.register({
    'formats/list': List,
    'formats/list-item': ListItem,
});
Quill.debug(false);

const strip = (html) => {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.innerHTML || "";
}
const $ = window.jQuery;

const CreatePublication = () => {

    //quill ref to store quill instance
    const quillInstanceRef = useRef();
    //toolbar ref to access toolbar div
    const quillToolbarRef = useRef();
    //quill editor div
    const quillEditorRef = useRef();

    const [view, setView] = useState(false);
    const dispatch = useDispatch();
    const { isEditLoading } = useSelector(state => state.edit);

    const [publish, setPublishState] = useState({
        title: "",
        subtitle: "",
        category: "Select a category",
        poster: "",
        content: "",
        contentInHTML: "",
        wordCount: 0,
        duration: 0
    });

    // quill initialize
    useEffect(() => {
        quillInstanceRef.current = new Quill(quillEditorRef.current, {
            placeholder: 'Start writing here...',
            scrollingContainer: ".box-template",
        })
        // quillInstanceRef.current.addContainer(quillToolbarRef.current);
        // console.log(quillInstanceRef.current)
    }, [quillInstanceRef, view]);

    // ontextchange
    useEffect(() => {
        const quill = quillInstanceRef.current;
        const onTextChange = (type) => {
            const pGrafs = document.querySelectorAll(`#editor-container p`);
            const ulGrafs = document.querySelectorAll(`#editor-container ul`);
            setPublishState(state => ({
                ...state,
                wordCount: getWordCount(),
                content: quillInstanceRef.current.container.textContent,
                contentInHTML: quillInstanceRef.current.root.innerHTML
            }))
            if (quillInstanceRef.current.hasFocus()) {
                pGrafs.forEach((p) => {
                    if (p.classList.length === 0) {
                        p.classList.add('editor-p');
                    }
                })
            }

            ulGrafs.forEach((ul) => {
                if (ul.classList.length === 0) {
                    ul.classList.add('browser-default');
                    ul.classList.add('editor-ul');
                }
            });

            handleContentHeight();

        }
        if (view) {
            quill.on('text-change', onTextChange);
            return () => quill.off('text-change', onTextChange);
        }




    }, [quillInstanceRef, view]);

    const getWordCount = () => {
        const editorContainer = $('#editor-container').get(0);
        const regex = /\s+/gi;
        const count = $(editorContainer).find(".ql-editor").text().length > 0 ? $(editorContainer).find(".ql-editor").text().trim().replace(regex, ' ').split(' ').length : 0;
        return count;
    }

    // handle height modal ocntent
    const handleContentHeight = () => {
        if (getWordCount() >= 100 && getWordCount() <= 300) {
            document.querySelector("#editor-container").style.height = "350px";
            // console.log(getWordCount())
        } else if (getWordCount() >= 301 && getWordCount() <= 500) {
            document.querySelector("#editor-container").style.height = "601px";
            document.querySelector("#editor-container").style.top = "10%";
            // console.log(getWordCount())
        } else if (getWordCount() >= 501 && getWordCount() <= 2000) {
            document.querySelector("#editor-container").style.height = "701px";
        } else {
            document.querySelector("#editor-container").style.height = "unset";
            document.querySelector("#editor-container").style.minHeight = "229px";
        }
    }

    // toolbar formatting
    const handleFormatStyle = (evt) => {

        const quill = quillInstanceRef.current;
        const target = evt.currentTarget.id;
        const selection = quill.getSelection(() => false);
        const selectedFormat = quill.getFormat();

        const isSelectedFormatted = selectedFormat.hasOwnProperty(target) ? true : false;

        switch (target) {
            case "bold":
                isSelectedFormatted ?
                    quill.formatText(selection.index, selection.length, "bold", false) :
                    quill.formatText(selection.index, selection.length, "bold", true);
                break;
            case "italic":
                isSelectedFormatted ?
                    quill.formatText(selection.index, selection.length, "italic", false) :
                    quill.formatText(selection.index, selection.length, "italic", true);
                break;
            case "blockquote":
                isSelectedFormatted ?
                    quill.format("blockquote", false) :
                    quill.format("blockquote", true);
                break;
            case "header-1":
                isSelectedFormatted ?
                    quill.removeFormat(selection.index, selection.length, 'header') :
                    quill.format("header", 1);
                break;
            case "list":
                isSelectedFormatted ?
                    quill.format("list", false) :
                    quill.format("list", true);
                break;
            case "list-ul":
                isSelectedFormatted ?
                    quill.format("ulist", false) :
                    quill.format("ulist", true);
                break;
            default:
        }
    }

    const updateFormValue = (evt) => {
        setPublishState(state => ({ ...state, [evt.target.name]: evt.target.value }));
    }

    const handleOnChangeOptions = (evt) => {
        const options = [...evt.target.options].filter(o => o.selected).map(o => o.value).toString();
        setPublishState(state => ({ ...state, [evt.target.name]: options }));
    }

    const categories = [
        "Select a category",
        "Healthcare",
        "Immigration",
        "Working",
        "Gender",
        "Discrimination",
        "Education",
        "General",
        "Housing"
    ]

    // compressed image
    const compressImageFile = async (file) => {

        const options = {
            maxWidthOrHeight: 800,
            useWebWorker: true,
            fileType: "image/webp",
            
        }

        const compressedFile = await imageCompression(file, options)
        return compressedFile;
    }

    // get title count
    const getTitleCount = () => {
        const titleContainer = $('#title').get(0);
        const regex = /\s+/gi;
        const count = $(titleContainer).text().length > 0 ? $(titleContainer).text().trim().replace(regex, ' ').split(' ').length : 0;
        return count;
    }

    // calculate post duration
    const calculatePostDuration = (wordCount) => {
        const titleCount = getTitleCount();
        const dividedBy = 200;
        const getInteger = (wordCount + titleCount) / dividedBy;
        const getDecimal = getInteger % 1;
        const roundInteger = Math.floor(getInteger) + Math.ceil(getDecimal * 0.60)

        return roundInteger;
    }

    const handleUpload = evt => {
        const [file] = evt.target.files;
        if (evt.target.id === "poster") {
            const compressedFile = compressImageFile(file);
            compressedFile.then(file => {
                if (file) {
                    setPublishState(state => ({ ...state, poster: file }));
                }
            });
        } else {
            setPublishState(state => ({ ...state, [evt.target.id]: file }))
        }

    }
    

    const publishPublish = () => {

        if (publish.title === null || publish.title === "") {
            AbortableAlert("Add a suitable title for this publication!");
            return;
        }

        if (publish.category === "" || publish.category === "Select a category") {
            AbortableAlert("Add a category for this publication");
            return;
        }

        if (publish.poster === "" || publish.poster === null) {
            AbortableAlert("A poster is required.");
            return;
        }

        if (publish.content === "" || publish.content === null) {
            AbortableAlert("More information required.");
            return;
        }

        const data = {
            title: validator.escape(publish.title.trim()),
            subtitle: validator.escape(publish.subtitle.trim()),
            category: publish.category,
            poster: publish.poster,
            contentPlain: publish.content,
            contentHTML: strip(publish.contentInHTML),
            duration: calculatePostDuration(publish.wordCount)
        }

        dispatch(createPublish(data))
            .then((res) => {
                console.log(res)
                AbortableAlert("Publication created successfully.");
                setView(false);
                setPublishState(state => ({
                    ...state,
                    title: "",
                    subtitle: "",
                    category: "Select a category",
                    poster: "",
                    content: "",
                    contentInHTML: "",
                    wordCount: 0,
                    duration: 0
                }))
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }


    return (
        <div className="user-table">
            <button
                type="button"
                className="btn btn-sm btn-primary user-btn"
                onClick={() => setView(true)}
            >
                Create a publication
            </button>

            {
                view &&
                <div className="create-form">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="box box-primary">
                                <div className="box-header with-border" style={{ padding: "1rem 3rem" }}>
                                    <h3 className="box-title">Add a publication</h3>
                                </div>
                                <div className="box-body" style={{ padding: "1rem 3rem" }}>
                                    <div className="form-group">
                                        <label htmlFor="title">Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="title"
                                            id="title"
                                            placeholder="Enter a title"
                                            value={publish.title}
                                            onChange={updateFormValue}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="subtitle">Subtitle</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="subtitle"
                                            id="subtitle"
                                            placeholder="Enter subtitle"
                                            value={publish.subtitle}
                                            onChange={updateFormValue}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Select a category</label>
                                        <select
                                            className="form-control"
                                            defaultValue="Select a category"
                                            name='category'
                                            onChange={handleOnChangeOptions}
                                        >
                                            {
                                                categories.map(category => (
                                                    <option
                                                        key={category}
                                                        value={category}
                                                        disabled={category === publish.category}
                                                    >
                                                        {category}
                                                    </option>
                                                ))
                                            }

                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="poster">Add poster</label>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            id="poster"
                                            onChange={handleUpload}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputFile">More information needed for publish</label>
                                        <div className="box box-default" >
                                            <div className="box-header  with-border" style={{ padding: "0" }}>
                                                <ToolbarButtons
                                                    quillToolbarRef={quillToolbarRef}
                                                    handleFormatStyle={handleFormatStyle}
                                                />
                                            </div>
                                            <div className="box-body box-template">
                                                <EditorContainer
                                                    quillEditorRef={quillEditorRef}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="box-footer fx" style={{ padding: "1rem 3rem", gap: "1rem" }}>
                                    <button
                                        type="submit"
                                        className="btn btn-default"
                                        onClick={() => setView(false)}
                                    >
                                        cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={publishPublish}
                                        disabled={isEditLoading}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default CreatePublication
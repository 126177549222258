import React from 'react'

const Ratings = ({
    ratingStarCount,
    ratingNumberCount,
    ratingReviewCount
}) => {

    const generateSpans = (num) => {
        const generatedStars = [];
        const totalStars = 5;
        const remainingStars = totalStars - ratingStarCount;

        const initStars = () => {
            for (let i = 0; i < Number(String(ratingStarCount).split('.')[0]); i++) {
                generatedStars.push(1);
                if (i === (Number(String(ratingStarCount).split('.')[0]) - 1) && Number(String(ratingStarCount).split('.')[1]) > 0) {
                    generatedStars.push(Number(`0.${String(ratingStarCount).split('.')[1]}`))
                }
            }
            for (let i = 0; i < Number(String(remainingStars).split('.')[0]); i++) {
                generatedStars.push(0);
            }
        }

        initStars();

        return generatedStars;
    }
    const innerStars = generateSpans(ratingStarCount);

    // return (
    //     <div className="rating__comp">
    //         <div className="rating__star">
    //             {
    //                 innerSpans.map((star, index) => (
    //                     <span key={index} className={`fa fa-star ${star}`}></span>
    //                 ))
    //             }
    //         </div>
    //         <div className="rating__desc">
    //             {
    //                 ratingNumberCount === 0 || ratingNumberCount === null || ratingNumberCount === undefined ?
    //                     <p className="rating__count"> no reviews yet</p> :
    //                     <>
    //                         <p className="rating__count"> {ratingNumberCount}</p>
    //                         <p className="rating__review">  {ratingReviewCount && `(${ratingReviewCount})`}</p>
    //                     </>

    //             }

    //         </div>
    //     </div>
    // )

    return (
        <div className="custom-rating-wrapper">
            {
                innerStars.map((star, index) => (
                    <div className="custom-rating-mask" key={index} data-star={star}>
                        <div className="custom-rating-img"></div>
                    </div>
                ))
            }
            <div className="rating__desc">
                {
                    ratingNumberCount === 0 || ratingNumberCount === null || ratingNumberCount === undefined ?
                        <p className="rating__count" style={{ margin: "0", marginLeft: ".5rem"}}> no reviews yet</p> :
                        <>
                            <p className="rating__count" style={{ margin: "0", marginLeft: ".5rem"}}> {ratingNumberCount}</p>
                            <p className="rating__review">  {ratingReviewCount && `(${ratingReviewCount})`}</p>
                        </>

                }

            </div>

        </div >
    )
}

export default Ratings
import React from 'react'
import { useSelector } from 'react-redux'

const Overview = () => {

    const homehousing = useSelector(
        ({ firestore: { ordered } }) => ordered['homehousing']
    )

    const reviewedhousing = useSelector(
        ({ firestore: { ordered } }) => ordered['reviewedhousing']
    )

    const rejectedhousing = useSelector(
        ({ firestore: { ordered } }) => ordered['rejectedhousing']
    )

    return <>
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="info-box">
                <span className="info-box-icon bg-aqua"><i className="fa fa-dot-circle-o"></i></span>
                <div className="info-box-content">
                    <span className="info-box-text">Total house(Rated)</span>
                    <span className="info-box-number">{homehousing && homehousing.length}<small></small></span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="info-box">
                <span className="info-box-icon bg-green"><i className="fa fa-check-square-o"></i></span>

                <div className="info-box-content">
                    <span className="info-box-text">Reviewed house(Published)</span>
                    <span className="info-box-number">{reviewedhousing && reviewedhousing.length}</span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}

        {/* <!-- fix for small devices only --> */}
        <div className="clearfix visible-sm-block"></div>

        {/* <!-- /.col --> */}
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="info-box">
                <span className="info-box-icon bg-red"><i className="fa fa-exclamation-circle"></i></span>

                <div className="info-box-content">
                    <span className="info-box-text">Rejected house(Un-published)</span>
                    <span className="info-box-number">{rejectedhousing && rejectedhousing.length}</span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}
    </>
}

export default Overview
import React from "react";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(-1, { replace: true });
  };
  return (
    <div className="page-wrapper" style={{ width: "100%" }}>
      <PageHeader
        pName="404 Error Page"
        pDesc=""
        handleBack={handleNavigate}
      />

      <div className="content container-fluid">
        <div className="error-page">
          <h2 className="headline text-yellow"> 404</h2>

          <div className="error-content">
            <h3>
              <i className="fa fa-warning text-yellow"></i> Oops! Page not found.
            </h3>

            <p>
              We could not find the page you were looking for. Meanwhile, you
              may <a href="/admin">return to dashboard</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;

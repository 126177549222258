import React from 'react'
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Outlet } from 'react-router-dom';
import { compose } from 'redux';

const Housing = ({ housing }) => {
    return (
        <div className="page-wrapper" style={{ width: "100%" }}>
            <Outlet context={housing} />
        </div >

    )
}

const mapStateToProps = state => {
    const housing = state.firestore.ordered.homehousing;
    return {
        housing: housing,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(
        [
            {
                collection: "ratings",
                where: ['status', '!=', 3],
                orderBy: ["status"],
                storeAs: "homehousing"
            },
            {
                collection: "ratings",
                where: ['status', '==', 0],
                storeAs: "reviewedhousing"
            },
            {
                collection: "ratings",
                where: ['status', '==', 1],
                storeAs: "rejectedhousing"
            },
        ]
    )
)(Housing)
import React from 'react'
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Outlet } from 'react-router-dom';
import { compose } from 'redux';

const Skills = ({ skills }) => {
    return (
        <div className="page-wrapper" style={{ width: "100%" }}>
            <Outlet context={skills} />
        </div >

    )
}

const mapStateToProps = state => {
    const skills = state.firestore.ordered.homeskills;
    return {
        skills: skills,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(
        [
            {
                collection: "skills",
                where: ['status', '!=', 3],
                orderBy: ["status"],
                storeAs: "homeskills"
            },
            {
                collection: "skills",
                where: ['status', '==', 2],
                storeAs: "pendingskills"
            },
            {
                collection: "skills",
                where: ['status', '==', 0],
                storeAs: "reviewedskills"
            },
            {
                collection: "skills",
                where: ['status', '==', 1],
                storeAs: "rejectedskills"
            },
        ]
    )
)(Skills)
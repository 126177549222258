import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Outlet } from 'react-router-dom'
import { compose } from 'redux'

const Posts = ({ posts }) => {

    return (
        <div className="page-wrapper" style={{ width: "100%" }}>
            <Outlet context={posts} />
        </div >

    )
}

const mapStateToProps = state => {
    const posts = state.firestore.ordered.homeposts;
    return {
        posts: posts,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(
        [
            {
                collection: "posts",
                where: ['status', '!=', 3],
                orderBy: ["status"],
                storeAs: "homeposts"
            },
            {
                collection: "posts",
                where: ['status', '==', 2],
                storeAs: "pendingposts"
            },
            {
                collection: "posts",
                where: ['status', '==', 0],
                storeAs: "reviewedposts"
            },
            {
                collection: "posts",
                where: ['status', '==', 1],
                storeAs: "rejectedposts"
            },
            {
                collection: "inits",
                doc: 'featured',
                storeAs: "featuredPosts"
            }
        ]
    )
)(Posts)
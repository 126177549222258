import React, { useEffect, useRef, useState } from 'react'
import Quill from 'quill/core';
import Block from 'quill/blots/block';
import Inline from 'quill/blots/inline';
import List, { ListItem } from 'quill/formats/list';
import imageCompression from 'browser-image-compression';
import validator from 'validator';
import AbortableAlert from '../../../components/AbortableAlert';
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader'
import EditorContainer from '../partials/comps/EditorContainer';
import ToolbarButtons from '../partials/comps/ToolbarButtons';
import { updateMisc } from '../../../stores/edit/EditSlice';



class BoldBlot extends Inline { }

BoldBlot.blotName = "bold";
BoldBlot.tagName = "strong";

class ItalicBlot extends Inline { }

ItalicBlot.blotName = "italic";
ItalicBlot.tagName = "em";


class BlockBlot extends Block { }

BlockBlot.blotName = "blockquote";
BlockBlot.tagName = "blockquote";

class HeaderBlot extends Block { }

HeaderBlot.blotName = "header";
HeaderBlot.tagName = ["h1", "h2"];


Quill.register(BoldBlot);
Quill.register(ItalicBlot);
Quill.register(BlockBlot);
Quill.register(HeaderBlot);
Quill.register({
    'formats/list': List,
    'formats/list-item': ListItem,
});

Quill.debug(false);

const strip = (html) => {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.innerHTML || "";
}
const $ = window.jQuery;
const GetMiscById = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const [param, setParam] = useState(null);

    const { isEditLoading } = useSelector(state => state.edit)

    useEffect(() => {
        if (params) {
            setParam(params.id)
        }
    }, [params]);

    useFirestoreConnect(() => {
        return [
            {
                collection: "miscellaneous",
                doc: param,
                storeAs: `${param}-miscs`
            }
        ]
    });

    const miscs = useSelector(
        ({ firestore: { ordered } }) => ordered[`${param}-miscs`]
    )

    const handleNavigate = () => {
        navigate(-1, { replace: true });
    }

    //quill ref to store quill instance
    const quillInstanceRef = useRef();
    //toolbar ref to access toolbar div
    const quillToolbarRef = useRef();
    //quill editor div
    const quillEditorRef = useRef();

    const [misc, setMiscState] = useState({
        title: "",
        poster: "",
        content: "",
        contentInHTML: "",
        wordCount: 0,
        duration: 0
    });

    // quill initialize
    useEffect(() => {
        quillInstanceRef.current = new Quill(quillEditorRef.current, {
            placeholder: 'Start writing here...',
            scrollingContainer: ".box-template",
        })
        // quillInstanceRef.current.addContainer(quillToolbarRef.current);
        // console.log(quillInstanceRef.current)
    }, [quillInstanceRef, miscs]);

    useEffect(() => {
        if (params.id && isLoaded(miscs)) {
            quillInstanceRef.current.root.innerHTML = miscs[0].properties.contentHTML
            quillInstanceRef.current.setSelection(quillInstanceRef.current.getLength(), 0);
            setMiscState(state => ({
                ...state,
                title: miscs[0].properties.title,
                poster: miscs[0].properties.poster
            }))
        }

    }, [miscs])


    // ontextchange
    useEffect(() => {
        const quill = quillInstanceRef.current;
        const onTextChange = (type) => {
            const pGrafs = document.querySelectorAll(`#editor-container p`);
            const ulGrafs = document.querySelectorAll(`#editor-container ul`);
            setMiscState(state => ({
                ...state,
                wordCount: getWordCount(),
                content: quillInstanceRef.current.container.textContent,
                contentInHTML: quillInstanceRef.current.root.innerHTML
            }))
            if (quillInstanceRef.current.hasFocus()) {
                pGrafs.forEach((p) => {
                    if (p.classList.length === 0) {
                        p.classList.add('editor-p');
                    }
                })
            }

            ulGrafs.forEach((ul) => {
                if (ul.classList.length === 0) {
                    ul.classList.add('browser-default');
                    ul.classList.add('editor-ul');
                }
            });

            handleContentHeight();

        }
        if (isLoaded(miscs)) {
            quill.on('text-change', onTextChange);
            return () => quill.off('text-change', onTextChange);
        }



    }, [quillInstanceRef, miscs]);

    const getWordCount = () => {
        const editorContainer = $('#editor-container').get(0);
        const regex = /\s+/gi;
        const count = $(editorContainer).find(".ql-editor").text().length > 0 ? $(editorContainer).find(".ql-editor").text().trim().replace(regex, ' ').split(' ').length : 0;
        return count;
    }

    // handle height modal ocntent
    const handleContentHeight = () => {
        if (getWordCount() >= 100 && getWordCount() <= 300) {
            document.querySelector("#editor-container").style.height = "350px";
            // console.log(getWordCount())
        } else if (getWordCount() >= 301 && getWordCount() <= 500) {
            document.querySelector("#editor-container").style.height = "601px";
            document.querySelector("#editor-container").style.top = "10%";
            // console.log(getWordCount())
        } else if (getWordCount() >= 501 && getWordCount() <= 2000) {
            document.querySelector("#editor-container").style.height = "701px";
        } else {
            document.querySelector("#editor-container").style.height = "unset";
            document.querySelector("#editor-container").style.minHeight = "229px";
        }
    }

    // toolbar formatting
    const handleFormatStyle = (evt) => {

        const quill = quillInstanceRef.current;
        const target = evt.currentTarget.id;
        const selection = quill.getSelection(() => false);
        const selectedFormat = quill.getFormat();

        const isSelectedFormatted = selectedFormat.hasOwnProperty(target) ? true : false;

        switch (target) {
            case "bold":
                isSelectedFormatted ?
                    quill.formatText(selection.index, selection.length, "bold", false) :
                    quill.formatText(selection.index, selection.length, "bold", true);
                break;
            case "italic":
                isSelectedFormatted ?
                    quill.formatText(selection.index, selection.length, "italic", false) :
                    quill.formatText(selection.index, selection.length, "italic", true);
                break;
            case "blockquote":
                isSelectedFormatted ?
                    quill.format("blockquote", false) :
                    quill.format("blockquote", true);
                break;
            case "header-1":
                isSelectedFormatted ?
                    quill.removeFormat(selection.index, selection.length, 'header') :
                    quill.format("header", 1);
                break;
            case "list":
                isSelectedFormatted ?
                    quill.format("list", false) :
                    quill.format("list", true);
                break;
            case "list-ul":
                isSelectedFormatted ?
                    quill.format("ulist", false) :
                    quill.format("ulist", true);
                break;
            default:
        }
    }

    // compressed image
    const compressImageFile = async (file) => {

        const options = {
            maxWidthOrHeight: 800,
            useWebWorker: true,
            fileType: "image/webp",
        }

        const compressedFile = await imageCompression(file, options)
        return compressedFile;
    }

    // get title count
    const getTitleCount = () => {
        const titleContainer = $('#title').get(0);
        const regex = /\s+/gi;
        const count = $(titleContainer).text().length > 0 ? $(titleContainer).text().trim().replace(regex, ' ').split(' ').length : 0;
        return count;
    }

    // calculate post duration
    const calculatePostDuration = (wordCount) => {
        const titleCount = getTitleCount();
        const dividedBy = 200;
        const getInteger = (wordCount + titleCount) / dividedBy;
        const getDecimal = getInteger % 1;
        const roundInteger = Math.floor(getInteger) + Math.ceil(getDecimal * 0.60)

        return roundInteger;
    }

    const handleUpload = evt => {
        const [file] = evt.target.files;
        if (evt.target.id === "poster") {
            const compressedFile = compressImageFile(file);
            compressedFile.then(file => {
                if (file) {
                    setMiscState(state => ({ ...state, poster: file }));
                }
            });
        } else {
            setMiscState(state => ({ ...state, [evt.target.id]: file }))
        }

    }

    const updateMiscs = () => {


        if (misc.content === "" || misc.content === null) {
            AbortableAlert("More information required.");
            return;
        }

        const data = {
            id: params.id,
            title: validator.escape(misc.title.trim()),
            poster: misc.poster,
            contentPlain: misc.content,
            contentHTML: strip(misc.contentInHTML),
            duration: calculatePostDuration(misc.wordCount)
        }

        dispatch(updateMisc(data))
            .then((res) => {
                AbortableAlert("Misc updated successfully.");
                setTimeout(() => {
                    navigate("/admin/misc")
                }, 1000);
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    if (isLoaded(miscs)) {
        return <>

            {/* <!-- Content Header (Page header) --> */}
            <PageHeader
                pName="Miscellaneous"
                pDesc="Manage miscellaneous by ID"
                handleBack={handleNavigate}
            />

            {/* // < !--Main content-- > */}
            < section className="content container-fluid" >

                {/* <!-------------------------- | Your Page Content Here --------------------------> */}
                {/* overview */}
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        {/* <!-- Box Comment --> */}
                        <div className="create-form">
                            <div className="row">
                                <div className="col-md-10">
                                    <div className="box box-primary">
                                        <div className="box-header with-border" style={{ padding: "1rem 3rem" }}>
                                            <h3 className="box-title">{miscs[0].properties.title}</h3>
                                        </div>
                                        <div className="box-body" style={{ padding: "1rem 3rem" }}>
                                            <div className="form-group">
                                                <label htmlFor="poster">Add poster</label>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    id="poster"
                                                    onChange={handleUpload}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputFile">Enter misc contents</label>
                                                <div className="box box-default" >
                                                    <div className="box-header  with-border" style={{ padding: "0" }}>
                                                        <ToolbarButtons
                                                            quillToolbarRef={quillToolbarRef}
                                                            handleFormatStyle={handleFormatStyle}
                                                        />
                                                    </div>
                                                    <div className="box-body box-template">
                                                        <EditorContainer
                                                            quillEditorRef={quillEditorRef}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="box-footer fx" style={{ padding: "1rem 3rem", gap: "1rem" }}>
                                            <button
                                                type="submit"
                                                className="btn btn-default"
                                                onClick={handleNavigate}
                                            >
                                                cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                onClick={updateMiscs}
                                                disabled={isEditLoading}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- /.box --> */}
                    </div>
                    <div className="col-md-2"></div>
                </div >
            </section >
            {/* // <!-- /.content --> */}
        </>
    }

}

export default GetMiscById
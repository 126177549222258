import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Outlet } from 'react-router-dom'
import { compose } from 'redux'

const Featured = ({ features }) => {

    return (
        <div className="page-wrapper" style={{ width: "100%" }}>
            <Outlet context={features} />
        </div >

    )
}

const mapStateToProps = state => {
    const features = state.firestore.ordered.homefeats;
    return {
        features: features,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(
        [
            {
                collection: "inits",
                doc: 'featured',
                storeAs: "homefeats"
            },
        ]
    )
)(Featured)
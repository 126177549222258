import React from 'react'
import { useSelector } from 'react-redux'

const Overview = () => {

    const appeals = useSelector(
        ({ firestore: { ordered } }) => ordered['homeappeals']
    )

    return <>
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="info-box">
                <span className="info-box-icon bg-aqua"><i className="fa fa-dot-circle-o"></i></span>
                <div className="info-box-content">
                    <span className="info-box-text">Appeals</span>
                    <span className="info-box-number">{appeals && appeals.length}<small></small></span>
                </div>
                {/* <!-- /.info-box-content --> */}
            </div>
            {/* <!-- /.info-box --> */}
        </div>
        {/* <!-- /.col --> */}

    </>
}

export default Overview
import React, { useEffect } from 'react'
import moment from 'moment';
import SliderContainer from '../../../../components/SliderContainer';


const $ = window.jQuery;
const ContentInfoBox = ({
    createdAt,
    banners,
    title,
    description,
}) => {



    // console.log(userInfo, username)

    useEffect(() => {
        if (window.jQuery) {
            $('[data-widget="box-widget"]').boxWidget();
        }
    }, []);


    return (
        <div className="box" data-widget="box-widget">
            <div className="box-header with-border">
                <div className="user-block">
                    <span className="description">Shared publicly - {moment(createdAt.toDate()).startOf('hour').fromNow()}</span>
                </div>
                {/* <!-- /.user-block --> */}
                <div className="box-tools">
                    <button
                        type="button"
                        className="btn btn-box-tool"
                        data-widget="collapse" data-toggle="tooltip" title="Collapse"><i className="fa fa-minus"></i>
                    </button>
                </div>
                {/* <!-- /.box-tools --> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body" style={{ padding: "0 2rem" }}>
                <SliderContainer
                    imgArr={banners}
                />

                <div className="post-title" style={{ marginTop: "2rem" }}>
                    <i>Name</i>
                    <h5 style={{ margin: "0" }}>{title}</h5>
                </div>
                <div className="post-content skill" style={{ marginTop: "2rem", padding: "0" }}>
                    <i>Location</i>
                    <p>{description}</p>
                </div>

            </div>
            {/* <!-- /.box-body --> */}

        </div >
    )
}

export default ContentInfoBox
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Link, useParams } from 'react-router-dom';
import BoxCard from '../../../components/BoxCard';
import PageHeader from '../../../components/PageHeader';
import Overview from './Overview';

const FilteredHousing = () => {
    const params = useParams();
    const [param, setParam] = useState({
        status: 0,
        id: ''
    });

    useEffect(() => {
        if (params) {
            const number = (params.id === 'reviewed') ? 0 : (params.id === 'rejected') ? 1 : null;
            setParam(param => ({ ...param, status: number, id: params.id }));
        }
    }, [params]);

    // const { firestore } = useSelector(state => state)
    // console.log(firestore)

    useFirestoreConnect(() => {
        return [
            {
                collection: "ratings",
                where: ['status', '==', param.status],
                orderBy: ["createdAt"],
                storeAs: `${param.id}-ratings`
            }
        ]
    })

    const filteredHousing = useSelector(
        ({ firestore: { ordered } }) => ordered[`${param.id}-ratings`]
    )

    return <>
        {/* <!-- Content Header (Page header) --> */}
        <PageHeader
            pName="Housing"
            pDesc="Manage housing"
        />

        {/* // < !--Main content-- > */}
        < section className="content container-fluid" >

            {/* <!-------------------------- | Your Page Content Here --------------------------> */}
            {/* overview */}
            <div className="row">
                <Overview />
            </div>

            {/* skills lisr */}
            <div className="row">
                <div className="col-xs-12">
                    <section className="sub content-header" >
                        <h4 style={{ margin: "0" }}>
                            {filteredHousing && filteredHousing.length} house(s) available
                        </h4>
                        <div className="btn-group">
                            <button type="button" className="btn btn-default btn-edit dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                Filter by status
                                <span className="caret"></span>
                            </button>
                            <ul className="dropdown-menu pos30">
                                <li><Link to="../status/reviewed"><span className='box-status bg-green'></span> Reviewed</Link></li>
                                <li><Link to="../status/rejected"><span className='box-status bg-red'></span> Rejected</Link></li>
                            </ul>
                        </div>
                    </section >
                    <div className="content-data">
                        <div className="row">
                            {filteredHousing &&
                                filteredHousing.map(house => (
                                    <div key={house.id} className="col-md-3 col-sm-6 col-xs-12">
                                        <BoxCard
                                            id={house.id}
                                            banner={house.building.photos[0]}
                                            message={`${house.building.city}, ${house.building.area}`}
                                            title={house.building.name}
                                            status={house.status}
                                            text="house"
                                            type="child"
                                        />
                                    </div>
                                ))

                            }

                        </div>
                    </div>
                </div>
            </div>
        </section >
        {/* // <!-- /.content --> */}
    </>
}

export default FilteredHousing
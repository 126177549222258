import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import imageCompression from "browser-image-compression";
import { useNavigate, useParams } from "react-router-dom";
import { updateMember } from "../../../stores/edit/EditSlice";
import PageHeader from "../../../components/PageHeader";
import AbortableAlert from "../../../components/AbortableAlert";
import ErrorPage from "../../error/ErrorPage";

const GetMemberById = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();

  const [param, setParam] = useState(null);
  const { isEditLoading } = useSelector((state) => state.edit);

  useEffect(() => {
    if (params) {
      setParam(params.id);
    }
  }, [params]);

  useFirestoreConnect(() => {
    return [
      {
        collection: "members",
        doc: param,
        storeAs: `${param}-member`,
      },
    ];
  });

  const locMember = useSelector(
    ({ firestore: { ordered } }) => ordered[`${param}-member`]
  );

  //   poster: locMember && locMember[0].properties.imgUrl,
  //     name: locMember && locMember[0].properties.name,
  //     type: locMember && locMember[0].properties.type,
  //     category: locMember && locMember[0].properties.category,
  //     title: locMember && locMember[0].properties.title,
  //     description: locMember && locMember[0].properties.description,
  //     link: locMember && locMember[0].properties.linkUrl,
  //     linkedin: locMember && locMember[0].properties.linkedInUrl,

  const [member, setMemberState] = useState({
    poster: "",
    name: "",
    type: "",
    category: "",
    title: "",
    description: "",
    link: "",
    linkedin: "",
  });

  useEffect(() => {
    if (!isEmpty(locMember)) {
      setMemberState((state) => ({
        ...state,
        poster: locMember[0].properties.imgUrl,
        name: locMember[0].properties.name,
        type: locMember[0].properties.type,
        category: locMember[0].properties.category,
        title: locMember[0].properties.title,
        description: locMember[0].properties.description,
        link: locMember[0].properties.linkUrl,
        linkedin: locMember[0].properties.linkedInUrl,
      }));
    }
  }, [locMember]);

  const updateFormValue = (evt) => {
    setMemberState((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  const handleOnChangeOptions = (evt) => {
    const options = [...evt.target.options]
      .filter((o) => o.selected)
      .map((o) => o.value)
      .toString();
    setMemberState((state) => ({ ...state, [evt.target.name]: options }));
  };

  // compressed image
  const compressImageFile = async (file) => {
    const options = {
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: "image/webp",
    };

    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  };

  const handleUpload = (evt) => {
    const [file] = evt.target.files;
    if (evt.target.id === "poster") {
      const compressedFile = compressImageFile(file);
      compressedFile.then((file) => {
        if (file) {
          setMemberState((state) => ({ ...state, poster: file }));
        }
      });
    }
  };

  const handleUpdateMember = () => {
    if (member.poster === null || member.poster === "") {
      AbortableAlert("Add a suitable image for this member!");
      return;
    }

    if (member.name === "" || member.name === null) {
      AbortableAlert("Add a name for this member");
      return;
    }

    if (member.type === "" || member.type === "Select member type") {
      AbortableAlert("Select a type for this member");
      return;
    }

    if (member.title === "" || member.title === null) {
      AbortableAlert("Add a title for this member");
      return;
    }

    const data = {
      id: params.id,
      name: member.name,
      title: member.title,
      poster: member.poster,
      type:
        member.type === "0" || member.type === "Founder" ? "Founder" : "Others",
      category:
        member.category === "0" || member.category === "Generals"
          ? "Generals"
          : member.category === "1" || member.category === "Departments"
          ? "Departments"
          : member.category === "2" || member.category === "Committees"
          ? "Committees"
          : "",
      description: member.description,
      linkedin: member.linkedin,
      link: member.link,
    };

    // console.log(data);

    dispatch(updateMember(data))
      .then((res) => {
        AbortableAlert("Member updated successfully.");
        setTimeout(() => {
          navigate("/admin/members");
        }, 1000);
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  const handleNavigate = () => {
    navigate(-1, { replace: true });
  };

  return (
    <>
      {isEmpty(locMember) ? (
        <ErrorPage />
      ) : isLoaded(locMember) ? (
        <>
          {" "}
          {/* <!-- Content Header (Page header) --> */}
          <PageHeader
            pName="Members"
            pDesc="Manage members by ID"
            handleBack={handleNavigate}
          />
          {/* // < !--Main content-- > */}
          <section className="content container-fluid">
            {/* <!-------------------------- | Your Page Content Here --------------------------> */}
            {/* overview */}
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10">
                {/* <!-- Box Comment --> */}
                <div className="create-form">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="box box-primary">
                        <div
                          className="box-header with-border"
                          style={{ padding: "1rem 3rem" }}
                        >
                          <h3 className="box-title">Create a Member/Founder</h3>
                        </div>
                        <div
                          className="box-body"
                          style={{ padding: "1rem 3rem" }}
                        >
                          <img
                            className="img-responsive max-banner pad"
                            style={{
                              paddingLeft: "0px",
                              paddingRight: "0px",
                              width: "80px",
                              height: "80px",
                            }}
                            src={locMember[0].properties.imgUrl}
                            loading="eager"
                            alt={locMember[0].properties.imgUrl}
                          />
                          <div className="form-group">
                            <label htmlFor="poster">Update Member image</label>
                            <input
                              type="file"
                              accept="image/*"
                              id="poster"
                              onChange={handleUpload}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">
                              Member name (eg: "Soian Churi")
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              id="name"
                              placeholder="Enter a tag (eg, Soian Churi)"
                              value={
                                member.name || locMember[0].properties.name
                              }
                              onChange={updateFormValue}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="type">Member type</label>
                            <select
                              className="form-control"
                              name="type"
                              id="type"
                              onChange={handleOnChangeOptions}
                              defaultValue={locMember[0].properties.type}
                            >
                              <option
                                value="Select member type"
                                disabled
                                defaultValue={
                                  member.type || locMember[0].properties.type
                                }
                              >
                                Select type
                              </option>
                              <option value="0">Founder</option>
                              <option value="1">
                                Generals/Team/Committee/Department
                              </option>
                            </select>
                          </div>
                          {member.type === "1" && (
                            <div className="form-group">
                              <label htmlFor="category">
                                Member category (e.g. Department)
                              </label>
                              <select
                                className="form-control"
                                name="category"
                                id="category"
                                onChange={handleOnChangeOptions}
                                defaultValue={locMember[0].properties.category}
                              >
                                <option
                                  value="Select member category"
                                  disabled
                                  defaultValue={
                                    locMember[0].properties.category
                                  }
                                >
                                  Select category
                                </option>
                                <option value="0">Generals</option>
                                <option value="1">Departments</option>
                                <option value="2">Committees</option>
                              </select>
                            </div>
                          )}

                          <div className="form-group">
                            <label htmlFor="title">
                              Member title (eg: "Speaker, Activist, Media head")
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              id="title"
                              placeholder="Member title (eg: Speaker, Activists)"
                              value={
                                member.title || locMember[0].properties.title
                              }
                              onChange={updateFormValue}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="description">
                              Member description (eg: "Lorem ipsium disium")
                            </label>
                            <textarea
                              type="text"
                              className="form-control"
                              name="description"
                              id="description"
                              placeholder="Member description (eg: Lorem ipsium disium)"
                              value={
                                member.description ||
                                locMember[0].properties.description
                              }
                              onChange={updateFormValue}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="link">
                              Member's personal Url (eg: "www.joeybidden.com")
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="link"
                              id="link"
                              placeholder="Member personal Url (eg: www.joeybidden.com)"
                              value={
                                member.link || locMember[0].properties.linkUrl
                              }
                              onChange={updateFormValue}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="linkedin">
                              Member's LinkedIn URL (eg:
                              "https://www.linkedin.com/12332")
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="linkedin"
                              id="linkedin"
                              placeholder=" Member's LinkeIn URL (eg:
                        https://www.linkedin.com/12332)"
                              value={
                                member.linkedin ||
                                locMember[0].properties.linkedInUrl
                              }
                              onChange={updateFormValue}
                            />
                          </div>
                        </div>
                        <div
                          className="box-footer fx"
                          style={{ padding: "1rem 3rem", gap: "1rem" }}
                        >
                          <button
                            type="submit"
                            className="btn btn-default"
                            onClick={handleNavigate}
                          >
                            cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={handleUpdateMember}
                            disabled={isEditLoading}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* // <!-- /.content --> */}
        </>
      ) : null}
    </>
  );
};

export default GetMemberById;

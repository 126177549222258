import moment from 'moment';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase'
import { Link } from 'react-router-dom';
import { deleteStuffFromDB } from '../../../stores/edit/EditSlice';
import AbortableAlert from '../../../components/AbortableAlert';

const AllResources = ({ resources }) => {

    const [filter, setFilter] = useState("");
    const [loading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    // pagination
    const [indexDisp, setIndexDisp] = useState(0);
    const maxLimit = 10;
    const [maxDispLimit, setMaxDispLimit] = useState(maxLimit);

    const { isEditLoading } = useSelector(state => state.edit);
    const handlePrev = () => {
        setIndexDisp(indexDisp - 1)
        setMaxDispLimit(maxDispLimit - maxLimit)
    }

    const handleNext = () => {
        setIndexDisp(indexDisp + 1)
        setMaxDispLimit(maxDispLimit + maxLimit)
    }

    const handleFilterChange = (evt) => {
        setFilter(evt.target.value)
    }

    const filteredResources = React.useMemo(() => {
        if (isLoaded(resources)) {
            return resources.filter(data => {
                return [data.properties.title,
                data.properties.type,
                data.properties.category
                ]
                    .join("")
                    .toLowerCase()
                    .indexOf(filter.toLowerCase()) !== -1
            })
        }

    }, [filter, resources]);

    const handleDelete = (id, type) => {
        if (window.confirm("Do you want to proceed?")) {
            setIsLoading(false);
            const params = { id: id, type: type };
            dispatch(deleteStuffFromDB(params))
                .then(() => {
                    setIsLoading(true);
                    AbortableAlert("Resource successfully deleted.");
                })
                .catch((err) => {
                    AbortableAlert(err);
                })
        }
    }
    

    if (isLoaded(resources) && loading) {
        return (
            <div className="user-table">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="box no-border">
                            <div className="box-header spx" style={{ width: "100%" }}>
                                <h3 className="box-title" style={{ flex: 1, marginRight: "100px" }}>
                                    Search
                                </h3>
                                <div className="input-group" style={{ width: "400px" }}>
                                    <input
                                        type="text"
                                        name="user-search"
                                        autoComplete='off'
                                        value={filter}
                                        onChange={handleFilterChange}
                                        className="form-control"
                                        placeholder="search by name, category or type"
                                    />
                                </div>
                            </div>
                            {/* <!-- /.box-header --> */}
                            <div className="box-body no-padding">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <th>Title</th>
                                            <th>Type</th>
                                            <th>Category</th>
                                            <th>Published</th>
                                            <th>Actions</th>
                                        </tr>
                                        {
                                            filteredResources.slice(indexDisp, maxDispLimit).map(data => (
                                                <tr key={data.id}>
                                                    <td>
                                                        <div className="user-wrapper">
                                                            <Link to={`./id/${data.id}`} className="user-wrapper">
                                                                {data.properties.title}
                                                            </Link>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="user-wrapper">
                                                            <i>{data.properties.type}</i>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="user-wrapper">
                                                            {data.properties.category}
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <div className="user-wrapper">
                                                            {moment(data.createdAt.toDate()).format('ll')}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="user-wrapper">
                                                            <div className="btn-group">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-default dropdown-toggle"
                                                                    data-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                </button>
                                                                <ul className="dropdown-menu pos30">
                                                                    <li>
                                                                        {
                                                                            <>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn-action"
                                                                                    onClick={() => handleDelete(data.id, 'resource')}
                                                                                    disabled={isEditLoading}
                                                                                >
                                                                                    Delete
                                                                                </button>
                                                                            </>

                                                                        }

                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* <!-- /.box-body --> */}
                            <div className="box-footer spx" style={{ width: "100%" }}>
                                <p className='fx'
                                    style={{ flex: "1", justifyContent: "flex-start" }}
                                >
                                    Showing {indexDisp + 1} - {maxDispLimit > filteredResources.length ? filteredResources.length : maxDispLimit} of {resources.length} entries
                                </p>
                                <div className="btn-group">
                                    <button
                                        onClick={handlePrev}
                                        disabled={indexDisp <= 0}
                                        type="button"
                                        className="btn btn-default"
                                    >
                                        <i className="fa fa-caret-left" aria-hidden="true"></i>
                                    </button>
                                    <button
                                        onClick={handleNext}
                                        disabled={maxDispLimit === resources.length || maxDispLimit > resources.length}
                                        type="button"
                                        className="btn btn-default"
                                    >
                                        <i className="fa fa-caret-right" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default AllResources
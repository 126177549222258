import React, { useEffect, useState } from 'react'
import FormValidate from '../../../auth/controls/FormValidate'
import { createAdminUser } from '../../../stores/auth/AuthSlice';
import { useSelector } from 'react-redux';
import DisplayValidationMessage from '../../../auth/controls/DisplayValidationMessage';
import AbortableAlert from '../../../components/AbortableAlert';
import { isLoaded } from 'react-redux-firebase';

const CreateUser = () => {

    const locUser = useSelector(state => state.firebase.profile.user);
    const { authError } = useSelector(state => state.auth);
    const [view, setView] = useState(false);
    const [elements, setElements] = useState({
        email: "",
        password: "",
        badge: "",
        level: "",
    });

    const rules = {
        email: { required: true, email: true },
        password: { required: true, minLength: "8" },
        badge: { required: true },
        level: { required: true }
    }

    const updateFormValue = (evt) => {
        setElements({ ...elements, [evt.target.name]: evt.target.value });
    }

    const handleOnChangeOptions = (evt) => {
        const options = [...evt.target.options].filter(o => o.selected).map(o => o.value).toString();
        setElements(state => ({ ...state, [evt.target.name]: options }));
    }

    const handleCancel = () => {
        setView(!view);
    }

    const generatePassword = () => {
        const buf = new Uint8Array(6);
        window.crypto.getRandomValues(buf);
        return btoa(String.fromCharCode.apply(null, buf));
    }

    useEffect(() => {
        if (authError === 1) {
            AbortableAlert("User has been created.");
            setView(false);
        }
    }, [authError])

    if (isLoaded(locUser)) {
        return (
            <div className="user-table">
                <button
                    type="button"
                    className="btn btn-sm btn-primary user-btn"
                    onClick={() => setView(true)}
                >
                    Create a user
                </button>
                {
                    view &&
                    <div className="create-form">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="box" style={{ border: "none" }}>
                                    <div className="box-body">
                                        <FormValidate
                                            text={`Create`}
                                            data={elements}
                                            view={`create`}
                                            rules={rules}
                                            submit={createAdminUser}
                                            handleCancel={handleCancel}
                                        >
                                            <div className="form-group">
                                                <label htmlFor="email">Email address</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    autoComplete='off'
                                                    onChange={updateFormValue}
                                                    name="email"
                                                    id="email"
                                                    placeholder="Enter email"
                                                />
                                                <DisplayValidationMessage element="email" authError={authError} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password">Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    autoComplete='off'
                                                    onChange={updateFormValue}
                                                    name="password"
                                                    id="password"
                                                    placeholder="Password"
                                                />
                                                <DisplayValidationMessage element="password" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="badge">Member badge</label>
                                                <select
                                                    className="form-control"
                                                    name='badge'
                                                    id='badge'
                                                    onChange={handleOnChangeOptions}
                                                    defaultValue="Select a badge type"
                                                >
                                                    <option value="Select a badge type" disabled>Select a badge type</option>
                                                    <option value="none">None</option>
                                                    <option value="volunteer">Volunteer</option>
                                                    <option value="royalty">Royalty</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="level">Admin level</label>
                                                <select
                                                    className="form-control"
                                                    name='level'
                                                    id='level'
                                                    onChange={handleOnChangeOptions}
                                                    defaultValue="Select admin level"
                                                >
                                                    <option value="Select admin level" disabled>Select admin level</option>
                                                    <option value="0">None</option>
                                                    <option value="1">Rookie</option>
                                                    <option value="2">Super</option>
                                                    {
                                                        locUser.isAdmin === 3 &&
                                                        <option value="3">Super Dev</option>
                                                    }

                                                </select>
                                            </div>

                                        </FormValidate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    }

}

export default CreateUser
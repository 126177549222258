import React, { useContext } from 'react'
import { ValidationContext } from './ValidationContext'

const DisplayValidationMessage = ({ element, authError }) => {

    const { getElementErrorMessage } = useContext(ValidationContext);
    // const context = useContext(ValidationContext);
    // console.log(context)

    return <>
        <div className="error__wrapper spx">
            <div className="error__text">
                {
                    authError &&
                    <>
                        <p> {authError[element]}</p>
                    </>
                }
                {
                    getElementErrorMessage(element).map((message, index) => {
                        return (
                            <p key={index}> {message}</p>
                        )
                    })
                }
            </div>
            <div className="error__count fx">
                <p className='count'></p>
                <p className='max'></p>
            </div>
        </div>
    </>
}

export default DisplayValidationMessage
import React from 'react'
import { firestoreConnect } from 'react-redux-firebase'
import { Outlet } from 'react-router-dom'
import { compose } from 'redux'

const Surveys = () => {
    return (
        <div className="page-wrapper" style={{ width: "100%" }}>
            <Outlet />
        </div >
    )
}

export default compose(
    firestoreConnect(
        [
            {
                collection: "surveys",
                where: [['status', '==', 0]],
                orderBy: ["createdAt", 'desc'],
                storeAs: "adminSurveys"
            },
        ]
    )
)(Surveys)
import React from "react";
import { Link } from "react-router-dom";
import { PLACE__HOLDER } from "../config/globals";

const BoxCard = ({
  title,
  message,
  banner,
  status,
  id,
  type,
  text,
  where,
  hide,
  view,
}) => {
  return (
    <div className="box-card">
      <div className="box-content">
        {!hide && (
          <div className="box-banner">
            {banner === "" ? (
              <img
                src={PLACE__HOLDER}
                alt={`banner-${PLACE__HOLDER}`}
                loading="eager"
              />
            ) : banner === undefined ? (
              <img
                src={PLACE__HOLDER}
                alt={`banner-${PLACE__HOLDER}`}
                loading="eager"
              />
            ) : (
              <img src={banner} alt={`banner-${banner}`} loading="eager" />
            )}
          </div>
        )}

        <div className="box-desc inner">
          <div className="box-title truncate-text cl1">
            <h5>{title}</h5>
          </div>
          <div className="box-text truncate-text cl1">{message}</div>
        </div>
        <div className="box-action spx">
          <div
            className={`box-status ${
              status === 0
                ? "bg-green"
                : status === 1
                ? "bg-red"
                : status === 2
                ? "bg-yellow"
                : ""
            }`}
          ></div>
          <div className="box-links">
            <Link
              to={
                type === "parent"
                  ? `id/${id}`
                  : type === "outer"
                  ? `/admin/${where}/id/${id}`
                  : `../id/${id}`
              }
            >
              {view === "edit" ? "Edit" : "Review"} {text}
              <i className="fa fa-arrow-circle-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxCard;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import Loader from "../../components/Loader";

const AuthProtected = ({ children }) => {
  const navigate = useNavigate();
  const { auth, profile } = useSelector((state) => state.firebase);
  const [view, setView] = useState(true);

  useEffect(() => {
    if (isLoaded(profile)) {
      if (!auth.uid || profile.user.isAdmin === 0) {
        navigate("/", { replace: true });
        setView(false);
      } else {
        setView(false);
      }
    }
  }, [auth, profile]);

  return (
    <>
      {view && <Loader type="cover" />}
      {children}
    </>
  );
};

export default AuthProtected;

import React from 'react'
import { isLoaded } from 'react-redux-firebase';
import BoxCard from '../../../components/BoxCard';

const AllPublications = ({ posts }) => {
    return <>
        {
            isLoaded(posts) &&
            <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-12">
                    <section className="sub content-header" >
                        <h4 style={{ margin: "0" }}>
                            {posts && posts.length} posts available
                        </h4>
                    </section >
                    <div className="content-data">
                        <div className="row">
                            {posts &&
                                posts.map(post => (
                                    <div key={post.id} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                        <BoxCard
                                            id={post.id}
                                            banner={post.properties.banner}
                                            message={post.properties.contentPlain}
                                            title={post.properties.title}
                                            status={post.status}
                                            text="post"
                                            type="parent"
                                            view={'edit'}
                                            hide={false}
                                        />
                                    </div>
                                ))

                            }

                        </div>
                    </div>
                </div>
            </div>
        }
    </>
}

export default AllPublications
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { useOutletContext } from "react-router-dom";
import AbortableAlert from "../../../components/AbortableAlert";
import PageHeader from "../../../components/PageHeader";
import {
  deleteUserById,
  SET_AUTH_LOADING,
  SET__AUTH__ERROR,
  suspendUserById,
} from "../../../stores/auth/AuthSlice";
import Overview from "./Overview";

const UsersIndex = ({ children }) => {
  const { users, adminusers } = useOutletContext();
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();

  // pagination
  const [indexDisp, setIndexDisp] = useState(0);
  const maxLimit = 10;
  const [maxDispLimit, setMaxDispLimit] = useState(maxLimit);

  // state slice redux
  const { isAuthLoading } = useSelector((state) => state.auth);

  const handleFilterChange = (evt) => {
    setFilter(evt.target.value);
  };

  const filteredUsers = React.useMemo(() => {
    if (isLoaded(users)) {
      return users.filter((data) => {
        return (
          [data.user.username, data.user.fullName]
            .join("")
            .toLowerCase()
            .indexOf(filter.toLowerCase()) !== -1
        );
      });
    }
  }, [filter, users]);

  const filteredAdminUsers = React.useMemo(() => {
    if (isLoaded(adminusers)) {
      return adminusers.filter((data) => {
        return (
          [data.user.username, data.user.fullName]
            .join("")
            .toLowerCase()
            .indexOf(filter.toLowerCase()) !== -1
        );
      });
    }
  }, [filter, adminusers]);

  const handlePrev = () => {
    setIndexDisp(indexDisp - 1);
    setMaxDispLimit(maxDispLimit - maxLimit);
  };

  const handleNext = () => {
    setIndexDisp(indexDisp + 1);
    setMaxDispLimit(maxDispLimit + maxLimit);
  };

  const handleUserDelete = (id) => {
    if (window.confirm("Are you sure you want to delete account?")) {
      let errMessage = "";
      dispatch(deleteUserById(id)).then((result) => {
        // console.log(result)
        console.log(id)
        const { data } = result.payload;
        if (data === "deleted") {
          AbortableAlert("User has been deleted");
          dispatch(SET_AUTH_LOADING());
          dispatch(SET__AUTH__ERROR(null));
        }
        if (result.payload.data.hasOwnProperty("errorInfor")) {
          switch (data.errorInfo.code) {
            default:
              errMessage = "Something went wrong. Try again later";
              dispatch(SET__AUTH__ERROR({ errMessage }));
          }
        }
      });
    }
  };

  const handleUserSuspension = (id, type) => {
    dispatch(suspendUserById({ id: id, type: type }))
      .then((result) => {
        // console.log(result)
        AbortableAlert("User suspension status updated.");
      })
      .catch((err) => {
        AbortableAlert("Something went wrong. Try again");
      });
  };

  let proppedChildren = React.Children.map(children, (c) =>
    React.cloneElement(c, {
      filteredUsers: filteredUsers,
      isAuthLoading: isAuthLoading,
      maxDispLimit: maxDispLimit,
      filter: filter,
      handleFilterChange: handleFilterChange,
      indexDisp: indexDisp,
      users: users,
      handlePrev: handlePrev,
      handleNext: handleNext,
      handleUserDelete: handleUserDelete,
      handleUserSuspension: handleUserSuspension,
      adminusers: adminusers,
      filteredAdminUsers: filteredAdminUsers,
    })
  );

  if (isLoaded(users)) {
    return (
      <>
        {/* <!-- Content Header (Page header) --> */}
        <PageHeader pName="Users" pDesc="Manage users" />

        {/* // < !--Main content-- > */}
        <section className="content container-fluid">
          {/* <!-------------------------- | Your Page Content Here --------------------------> */}
          {/* overview */}
          <div className="row">
            <Overview users={users} />
          </div>

          {proppedChildren}
        </section>
        {/* // <!-- /.content --> */}
      </>
    );
  }
};

export default UsersIndex;

import validator from "validator";


export const ValidateData = (data, rules) => {
    let errors = {};


    Object.keys(data).forEach(field => {
        if (rules.hasOwnProperty(field)) {
            let fieldErr = [];
            let val = data[field];

            //check if field is required
            if (rules[field].required && validator.isEmpty(val)) {
                fieldErr.push(`A value is required`);
            }

            if (rules[field].required && val === "false") {
                fieldErr.push(`You must agree to the Terms of Service`);
            }

            if (field === "password") {
                if (rules[field].minLength && !validator.isLength(val, rules[field].minLength)) {
                    fieldErr.push(`You must enter at least ${rules[field].minLength} characters`);
                } else {
                    if (rules[field].isStrongPassword && !validator.isStrongPassword(val)) {
                        fieldErr.push("Your password is too weak");
                    }
                }

            }
            //if field is not empty
            if (!validator.isEmpty(data[field])) {

                //check specified min length
                if (field !== "password") {
                    if (rules[field].minLength && !validator.isLength(val, rules[field].minLength)) {
                        fieldErr.push(`You must enter at least ${rules[field].minLength} characters`);
                    }
                }
                if (rules[field].maxLength && validator.isLength(val, rules[field].maxLength)) {
                    fieldErr.push(`Enter not more than ${rules[field].maxLength} characters`);
                }
                //check if value is alphabetic
                if (rules[field].alpha && !validator.isAlpha(val, 'en-US', { ignore: ' ' })) {
                    fieldErr.push("Enter a valid character");
                }
                if (rules[field].alphawithdot && !validator.isAlpha(val, 'en-US', { ignore: '.' })) {
                    fieldErr.push("Enter a valid character");
                }
                //check if value is alpha nummeric
                if (rules[field].alphanumeric && !validator.isAlphanumeric(val)) {
                    fieldErr.push("Enter a valid character, whitespaces are not valid");
                }
                //check if it is a valid email address
                if (rules[field].email && !validator.isEmail(val)) {
                    fieldErr.push("Your email must be a valid email.")
                }
            }

            if (fieldErr.length > 0) {
                errors[field] = fieldErr;
            }
        }
    });

    return errors
}
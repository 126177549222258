import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useNavigate, useParams } from "react-router-dom";
import { updateSurvey } from "../../../stores/edit/EditSlice";
import AbortableAlert from "../../../components/AbortableAlert";
import PageHeader from "../../../components/PageHeader";
import ErrorPage from "../../error/ErrorPage";

const GetSurveyById = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [param, setParam] = useState(null);

  const { isEditLoading } = useSelector((state) => state.edit);

  const locSurvey = useSelector(
    ({ firestore: { ordered } }) => ordered[`${param}-locSurvey`]
  );

  const [survey, setSurveyState] = useState({
    title: "",
    link: "",
    displayFrom: "",
    displayTo: "",
  });

  useEffect(() => {
    if (!isEmpty(locSurvey)) {
      setSurveyState((state) => ({
        ...state,
        title: locSurvey[0].properties.title,
        link: locSurvey[0].properties.link,
        displayFrom: moment(locSurvey[0].display.from.toDate()).format(
          "YYYY-MM-DD"
        ),
        displayTo: moment(locSurvey[0].display.from.toDate()).format(
          "YYYY-MM-DD"
        ),
      }));
    }
  }, [locSurvey]);

  const updateFormValue = (evt) => {
    setSurveyState((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  useEffect(() => {
    if (params) {
      setParam(params.id);
    }
  }, [params]);

  useFirestoreConnect(() => {
    return [
      {
        collection: "surveys",
        doc: param,
        storeAs: `${param}-locSurvey`,
      },
    ];
  });

  useEffect(() => {
    if (params.id && !isEmpty(locSurvey)) {
      setSurveyState((state) => ({
        ...state,
        title: locSurvey[0].properties.title,
        link: locSurvey[0].properties.link,
        displayFrom: moment(locSurvey[0].display.from.toDate()).format(
          "YYYY-MM-DD"
        ),
        displayTo: moment(locSurvey[0].display.from.toDate()).format(
          "YYYY-MM-DD"
        ),
      }));
    }
  }, [locSurvey, params]);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(-1, { replace: true });
  };

  const publishSurvey = () => {
    if (survey.title === "" || survey.title === null) {
      AbortableAlert("Add a title for this survey.");
      return;
    }

    if (survey.link === "") {
      AbortableAlert("Add a link for this survey");
      return;
    }

    if (survey.displayFrom === "" && survey.displayTo === "") {
      AbortableAlert("Add a display duration for this survey");
      return;
    }

    const data = {
      id: params.id,
      title: survey.title,
      link: survey.link,
      displayFrom: survey.displayFrom,
      displayTo: survey.displayTo,
    };

    dispatch(updateSurvey(data))
      .then((res) => {
        // console.log(res)
        AbortableAlert("Survey updated successfully.");
        setTimeout(() => {
          navigate("/admin/surveys");
        }, 1000);
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  return (
    <>
      {isEmpty(locSurvey) ? (
        <ErrorPage />
      ) : isLoaded(locSurvey) ? (
        <>
          {/* <!-- Content Header (Page header) --> */}
          <PageHeader
            pName="Surveys"
            pDesc="Manage surveys by ID"
            handleBack={handleNavigate}
          />

          {/* // < !--Main content-- > */}
          <section className="content container-fluid">
            {/* <!-------------------------- | Your Page Content Here --------------------------> */}
            {/* overview */}
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10">
                {/* <!-- Box Comment --> */}
                <div className="create-form">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="box box-primary">
                        <div
                          className="box-body"
                          style={{ padding: "1rem 3rem" }}
                        >
                          <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              id="title"
                              placeholder="Enter a title"
                              defaultValue={locSurvey[0].properties.title}
                              onChange={updateFormValue}
                            />
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-xs-12">
                                <label htmlFor="line">Link</label>
                                <input
                                  type="text"
                                  name="link"
                                  id="link"
                                  className="form-control"
                                  placeholder="Enter https://your url here"
                                  defaultValue={locSurvey[0].properties.link}
                                  onChange={updateFormValue}
                                />
                              </div>
                              <div className="col-xs-12">
                                <div className="form-group"></div>
                              </div>
                              <div className="col-xs-6">
                                <label htmlFor="displayFrom">
                                  Display from:
                                </label>
                                <input
                                  type="date"
                                  name="displayFrom"
                                  id="displayFrom"
                                  className="form-control"
                                  placeholder="Display from"
                                  defaultValue={moment(
                                    locSurvey[0].display.from.toDate()
                                  ).format("YYYY-MM-DD")}
                                  onChange={updateFormValue}
                                />
                              </div>
                              <div className="col-xs-6">
                                <label htmlFor="title">Display to:</label>
                                <input
                                  type="date"
                                  name="displayTo"
                                  id="displayTo"
                                  className="form-control"
                                  placeholder="Display to"
                                  defaultValue={moment(
                                    locSurvey[0].display.to.toDate()
                                  ).format("YYYY-MM-DD")}
                                  onChange={updateFormValue}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="box-footer fx"
                          style={{ padding: "1rem 3rem", gap: "1rem" }}
                        >
                          <button
                            type="submit"
                            className="btn btn-default"
                            onClick={handleNavigate}
                          >
                            cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={publishSurvey}
                            disabled={isEditLoading}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* // <!-- /.content --> */}
        </>
      ) : null}
    </>
  );
};

export default GetSurveyById;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import imageCompression from "browser-image-compression";
import AbortableAlert from "../../../components/AbortableAlert";
import { createPartner } from "../../../stores/edit/EditSlice";

const CreatePartner = () => {
  const { isEditLoading } = useSelector((state) => state.edit);
  const [view, setView] = useState(false);
  const dispatch = useDispatch();
  const [partner, setPartnerState] = useState({
    poster: "",
    name: "",
    link: "",
  });

  const updateFormValue = (evt) => {
    setPartnerState((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  // compressed image
  const compressImageFile = async (file) => {
    const options = {
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: "image/webp",
    };

    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  };

  const handleUpload = (evt) => {
    const [file] = evt.target.files;
    if (evt.target.id === "poster") {
      const compressedFile = compressImageFile(file);
      compressedFile.then((file) => {
        if (file) {
          setPartnerState((state) => ({ ...state, poster: file }));
        }
      });
    }
  };

  const handleAddMember = () => {
    if (partner.poster === null || partner.poster === "") {
      AbortableAlert("Add a suitable logo for this partner!");
      return;
    }

    if (partner.name === "" || partner.name === null) {
      AbortableAlert("Add a name for this partner");
      return;
    }

    if (partner.link === "" || partner.link === null) {
      AbortableAlert("Add a link for this member");
      return;
    }

    const data = {
      name: partner.name,
      poster: partner.poster,
      link: partner.link,
    };

    // console.log(data);
    dispatch(createPartner(data))
      .then((res) => {
        AbortableAlert("Partner created successfully.");
        setView(false);
        setPartnerState((state) => ({
          ...state,
          poster: "",
          name: "",
          link: "",
        }));
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  return (
    <div className="user-table">
      <button
        type="button"
        className="btn btn-sm btn-primary user-btn"
        onClick={() => setView(true)}
      >
        Create a Partner
      </button>
      {view && (
        <div className="create-form">
          <div className="row">
            <div className="col-md-6">
              <div className="box box-primary">
                <div
                  className="box-header with-border"
                  style={{ padding: "1rem 3rem" }}
                >
                  <h3 className="box-title">Create a Partner</h3>
                </div>
                <div className="box-body" style={{ padding: "1rem 3rem" }}>
                  <div className="form-group">
                    <label htmlFor="poster">Partner logo</label>
                    <input
                      type="file"
                      accept="image/*"
                      id="poster"
                      onChange={handleUpload}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">
                      Partner name (eg: "Soian Churi")
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Enter a tag (eg, Soian Churi)"
                      value={partner.name}
                      onChange={updateFormValue}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="link">
                      Partner's Url (eg: "https://www.joeybidden.com")
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="link"
                      id="link"
                      placeholder="Member personal Url (eg: https://www.joeybidden.com)"
                      value={partner.link}
                      onChange={updateFormValue}
                    />
                  </div>
                </div>
                <div
                  className="box-footer fx"
                  style={{ padding: "1rem 3rem", gap: "1rem" }}
                >
                  <button
                    type="submit"
                    className="btn btn-default"
                    onClick={() => setView(false)}
                  >
                    cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleAddMember}
                    disabled={isEditLoading}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatePartner;

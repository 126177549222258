import React from 'react'
import moment from 'moment';
import { isLoaded } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PostInfoBox = ({
    tags,
    duration,
    status,
    createdAt,
    rUsername,
    reviewedAt,
    isEditLoading,
    publishPost,
    handleUnpublish,
    handleAddToFeatured,
    handleRemoveFromFeatured,
    handlePostDelete,
    handleRejectPost,
    handleRevertToPending
}) => {
    const params = useParams();

    const featuredPosts = useSelector(
        ({ firestore: { ordered } }) => ordered[`featuredPosts`] && ordered[`featuredPosts`]
    );

    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
            <div className="box box-widget widget-user-2">
                <div className="widget-user-header bg-white" style={{ padding: "10px 20px" }}>
                    <h5 className="widget-user-desc" style={{ margin: "0px", fontSize: "16px" }}>Post Information</h5>
                </div>
                <div className="box-footer no-padding">
                    <ul className="nav nav-stacked">
                        <li>
                            <a
                                href="/"
                                onClick={(event) => event.preventDefault()}
                            >
                                Tags <span className="pull-right badge bg-grey">{[...tags]}</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/"
                                onClick={(event) => event.preventDefault()}
                            >
                                Duration
                                <span className="pull-right">
                                    {`${duration} ${duration > 1 ? 'mins' : 'min'}`}
                                </span></a>
                        </li>
                        <li>
                            <a
                                href="/"
                                onClick={(event) => event.preventDefault()}
                            >
                                Status
                                <span
                                    className={`pull-right badge ${(status === 0) ? 'bg-green' : (status === 1) ? 'bg-red' : (status === 2) ? 'bg-yellow' : ''}`}
                                >
                                    {`${(status === 0) ? 'Accepted' : (status === 1) ? 'Rejected' : 'pending'}`}
                                </span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/"
                                onClick={(event) => event.preventDefault()}
                            >
                                Created on
                                <span className="pull-right ">
                                    <i>{moment(createdAt.toDate()).format('ll')}</i>
                                </span>
                            </a>
                        </li>

                        {
                            rUsername !== "" ?
                                <>
                                    <li>
                                        <a
                                            href="/"
                                            onClick={(event) => event.preventDefault()}
                                        >
                                            Reviewed on
                                            <span className="pull-right">

                                                <i>{moment(reviewedAt.toDate()).format('llll')}</i>

                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/"
                                            onClick={(event) => event.preventDefault()}
                                        >
                                            Reviewed by
                                            <span className="pull-right">
                                                <span><i>{rUsername}</i></span>
                                            </span>
                                        </a>
                                    </li>
                                </> : null
                        }

                    </ul>
                </div>
            </div>
            <div className="box box-widget widget-user-2">
                <div className="widget-user-header bg-white" style={{ padding: "10px 20px" }}>
                    <h5 className="widget-user-desc" style={{ margin: "0px", fontSize: "16px" }}>Actions</h5>
                </div>
                <div className="box-footer no-padding">
                    <ul className="nav nav-stacked">
                        <li>
                            {
                                status !== 0 ?
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                        disabled={status === 0 || status === 1 || isEditLoading}
                                        onClick={publishPost}
                                    >
                                        Publish post
                                    </button> :
                                    <button
                                        type="button"
                                        className="btn btn-default"
                                        disabled={isEditLoading}
                                        onClick={handleUnpublish}
                                    >
                                        Un-publish post
                                    </button>
                            }
                        </li>
                        <li>
                            {
                                status !== 1 ?
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        disabled={status === 0 || status === 1 || isEditLoading}
                                        onClick={handleRejectPost}
                                    >
                                        Reject post
                                    </button> :
                                    <button
                                        type="button"
                                        className="btn btn-warning"
                                        disabled={isEditLoading}
                                        onClick={handleRevertToPending}
                                    >
                                        Revert to pending
                                    </button>
                            }
                        </li>
                        <li>
                            {
                                isLoaded(featuredPosts) &&
                                    featuredPosts[0].data.includes(params.id) ?
                                    <button
                                        type="button"
                                        className="btn btn-default"
                                        onClick={handleRemoveFromFeatured}
                                    >
                                        Remove from featured list
                                    </button> :
                                    <button
                                        type="button"
                                        className="btn btn-default"
                                        disabled={status === 1 || status === 2}
                                        onClick={handleAddToFeatured}
                                    >
                                        Add to featured list
                                    </button>
                            }

                        </li>
                        <li>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={handlePostDelete}
                            >
                                Delete post
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PostInfoBox
import React from 'react'
import Overview from './Overview'
import { useSelector } from 'react-redux'

const ForumTags = ({
    locUser,
    view,
    setView,
    name,
    updateFormValue,
    handleAddTag,
    handleDeleteTag,
    isEditLoading,
}) => {

    const tags = useSelector(
        ({ firestore: { ordered } }) => ordered.homeTags && ordered.homeTags[0]
    )

    return <>
        {/* overview */}
        <div className="row">
            <Overview handleDeleteTag={handleDeleteTag} tags={tags} />
        </div>

        {
            locUser && (locUser.isAdmin === 2) &&
            <div className="user-table">
                <button
                    type="button"
                    className="btn btn-sm btn-primary user-btn"
                    onClick={() => setView(true)}
                >
                    Create
                </button>

                {
                    view &&
                    <div className="create-form">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box box-primary">
                                    <div className="box-header with-border" style={{ padding: "1rem 3rem" }}>
                                        <h3 className="box-title">Create a Forum Tag</h3>
                                    </div>
                                    <div className="box-body" style={{ padding: "1rem 3rem" }}>
                                        <div className="form-group">
                                            <label htmlFor="name">Tag Name (eg: "General")</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                id="name"
                                                placeholder="Enter a tag (eg, General)"
                                                value={name}
                                                onChange={updateFormValue}
                                            />
                                        </div>
                                    </div>
                                    <div className="box-footer fx" style={{ padding: "1rem 3rem", gap: "1rem" }}>
                                        <button
                                            type="submit"
                                            className="btn btn-default"
                                            onClick={() => setView(false)}
                                        >
                                            cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={handleAddTag}
                                            disabled={isEditLoading}
                                        >
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        }

    </>
}

export default ForumTags
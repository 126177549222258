import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  editError: null,
  isEditLoading: false,
};

// POSTS
export const autoApprove = createAsyncThunk(
  "edit/autoApprove",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("users").doc(data);
      return updateRef.update({
        "account.isAutoApprove": true,
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const removeAutoApprove = createAsyncThunk(
  "edit/removeAutoApprove",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("users").doc(data);
      return updateRef.update({
        "account.isAutoApprove": false,
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const acceptPost = createAsyncThunk(
  "edit/acceptPost",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("posts").doc(data);
      return updateRef.update({
        status: 0,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const revertToPending = createAsyncThunk(
  "edit/revertToPending",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("posts").doc(data);
      return updateRef.update({
        status: 2,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const deletePost = createAsyncThunk(
  "edit/deletePost",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("posts").doc(data);
      return updateRef.delete();
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const rejectPost = createAsyncThunk(
  "edit/rejectPost",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("posts").doc(data);
      return updateRef.update({
        status: 1,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const unPublishPost = createAsyncThunk(
  "edit/unPublishPost",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("posts").doc(data);
      return updateRef.update({
        status: 2,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const unPublishPostAndReject = createAsyncThunk(
  "edit/unPublishPostAndReject",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("posts").doc(data);
      return updateRef.update({
        status: 1,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

// SKILLS
export const acceptSkill = createAsyncThunk(
  "edit/acceptSkill",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("skills").doc(data);
      return updateRef.update({
        status: 0,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const rejectSkill = createAsyncThunk(
  "edit/rejectSkill",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("skills").doc(data);
      return updateRef.update({
        status: 1,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const revertToPendingSkill = createAsyncThunk(
  "edit/revertToPendingSkill",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("skills").doc(data);
      return updateRef.update({
        status: 2,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const deleteSkill = createAsyncThunk(
  "edit/deleteSkill",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("skills").doc(data);
      return updateRef.delete();
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const unPublishSkill = createAsyncThunk(
  "edit/unPublishSkill",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("skills").doc(data);
      return updateRef.update({
        status: 2,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const unPublishSkillAndReject = createAsyncThunk(
  "edit/unPublishSkillAndReject",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("skills").doc(data);
      return updateRef.update({
        status: 1,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

// HOUSE
export const deleteHouse = createAsyncThunk(
  "edit/deleteHouse",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("ratings").doc(data);
      return updateRef.delete();
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const deleteHouseReview = createAsyncThunk(
  "edit/deleteHouseReview",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore
        .collection("ratings")
        .doc(data.id)
        .collection("reviews")
        .doc(data.doc);
      return updateRef.delete();
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const publishHouse = createAsyncThunk(
  "edit/publishHouse",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("ratings").doc(data);
      return updateRef.update({
        status: 0,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const unPublishHouse = createAsyncThunk(
  "edit/unPublishHouse",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("ratings").doc(data);
      return updateRef.update({
        status: 2,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

// OTHERS
export const deleteReports = createAsyncThunk(
  "edit/deleteReports",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      switch (data.type) {
        case "posts":
          const postRef = firestore
            .collection("reports")
            .doc("types")
            .collection("posts")
            .doc(data.id);
          return postRef.delete();
        case "skills":
          const skillRef = firestore
            .collection("reports")
            .doc("types")
            .collection("skills")
            .doc(data.id);
          return skillRef.delete();
        case "ratings":
          const ratingRef = firestore
            .collection("reports")
            .doc("types")
            .collection("ratings")
            .doc(data.id);
          return ratingRef.delete();
        case "comments":
          const commentRef = firestore
            .collection("reports")
            .doc("types")
            .collection("comments")
            .doc(data.id);
          return commentRef.delete();
        default:
          break;
      }
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const deleteReportsComment = createAsyncThunk(
  "edit/deleteReportsComment",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      switch (data.type) {
        case "comment":
          const postRef = firestore
            .collection("posts")
            .doc(data.postId)
            .collection("comments")
            .doc(data.id);
          return postRef.delete();
        case "subcomment":
          const postSubRef = firestore
            .collection("posts")
            .doc(data.postId)
            .collection("comments")
            .doc(data.pid)
            .collection("commentSubComments")
            .doc(data.id);
          return postSubRef.delete();
        case "house":
          const ratingRef = firestore
            .collection("ratings")
            .doc(data.postId)
            .collection("reviews")
            .doc(data.id);
          return ratingRef.delete();
        case "skill":
          const skillRef = firestore
            .collection("skills")
            .doc(data.postId)
            .collection("reviews")
            .doc(data.id);
          return skillRef.delete();
        default:
          break;
      }
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const deleteStuffFromDB = createAsyncThunk(
  "edit/deleteStuffFromDB",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      switch (data.type) {
        case "resource":
          const resRef = firestore.collection("resources").doc(data.id);
          return resRef.delete();
        case "event":
          const eventRef = firestore.collection("events").doc(data.id);
          return eventRef.delete();
        case "survey":
          const surveyRef = firestore.collection("surveys").doc(data.id);
          return surveyRef.delete();
        default:
          break;
      }
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const resolveComplaint = createAsyncThunk(
  "edit/resolveComplaint",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("complaints").doc(data);
      return updateRef.update({
        status: 0,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const unResolveComplaint = createAsyncThunk(
  "edit/unResolveComplaint",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("complaints").doc(data);
      return updateRef.update({
        status: 1,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const resolveAppeal = createAsyncThunk(
  "edit/resolveAppeal",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("appeals").doc(data);
      return updateRef.update({
        status: 0,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const unResolveAppeal = createAsyncThunk(
  "edit/unResolveAppeal",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("appeals").doc(data);
      return updateRef.update({
        status: 1,
        "review.username": user.username,
        "review.id": user.id,
        "review.reviewedAt": new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const deleteComplaint = createAsyncThunk(
  "edit/deleteComplaint",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("complaints").doc(data);
      return updateRef.delete();
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const deleteAppeal = createAsyncThunk(
  "edit/deleteAppeal",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("appeals").doc(data);
      return updateRef.delete();
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const resetAppeal = createAsyncThunk(
  "edit/resetAppeal",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    try {
      switch (data) {
        case "post":
          return firestore
            .collection("alerts")
            .where("payload", "==", "/posts")
            .get()
            .then((querySnapshots) => {
              querySnapshots.docs.forEach((snapshot) => {
                snapshot.ref.update({ status: 0 });
              });
            })
            .catch((err) => {
              return err;
            });
        case "skill":
          return firestore
            .collection("alerts")
            .where("payload", "==", "/skills")
            .get()
            .then((querySnapshots) => {
              querySnapshots.docs.forEach((snapshot) => {
                snapshot.ref.update({ status: 0 });
              });
            })
            .catch((err) => {
              return err;
            });
        case "appeal":
          return firestore
            .collection("appeals")
            .get()
            .then((querySnapshots) => {
              querySnapshots.docs.forEach((snapshot) => {
                snapshot.ref.update({ status: 0 });
              });
            })
            .catch((err) => {
              return err;
            });
        case "complaint":
          return firestore
            .collection("complaints")
            .get()
            .then((querySnapshots) => {
              querySnapshots.docs.forEach((snapshot) => {
                snapshot.ref.update({ status: 0 });
              });
            })
            .catch((err) => {
              return err;
            });
        default:
          break;
      }
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const removeBadge = createAsyncThunk(
  "edit/removeBadge",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("users").doc(data);
      return updateRef.update({
        "user.badgeType": "",
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const assignVolunteerBadge = createAsyncThunk(
  "edit/assignVolunteerBadge",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("users").doc(data);
      return updateRef.update({
        "user.badgeType": "volunteer",
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const assignMemberBadge = createAsyncThunk(
  "edit/assignMemberBadge",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("users").doc(data);
      return updateRef.update({
        "user.badgeType": "member",
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const assignSuperBadge = createAsyncThunk(
  "edit/assignSuperBadge",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("users").doc(data);
      return updateRef.update({
        "user.badgeType": "dev",
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const assignRookieAdmin = createAsyncThunk(
  "edit/assignRookieAdmin",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("users").doc(data);
      return updateRef.update({
        "user.isAdmin": 1,
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const assignSuperAdmin = createAsyncThunk(
  "edit/assignSuperAdmin",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("users").doc(data);
      return updateRef.update({
        "user.isAdmin": 2,
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const removeAdmin = createAsyncThunk(
  "edit/removeAdmin",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("users").doc(data);
      return updateRef.update({
        "user.isAdmin": 0,
      });
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const createResource = createAsyncThunk(
  "edit/createResource",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      let fileUrlFromUpload;
      let fileUrlFromUpload2;

      //upload image first to get url
      if (data.attachment.name !== "" || data.attachment.name !== null) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Resources/attachment/${btoa(data.attachment.name)}`
        );
        await fileRef.put(data.attachment);
        fileUrlFromUpload = await fileRef.getDownloadURL();
      }

      if (data.poster.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Resources/posters/${btoa(data.poster.name)}`
        );
        await fileRef.put(data.poster);
        fileUrlFromUpload2 = await fileRef.getDownloadURL();
      }

      // firebase.firestore.Timestamp.fromDate(new Date())
      return firestore.collection("resources").add({
        interactions: {
          commentCount: 0,
          likeCount: 0,
          saveCount: 0,
          reportCount: 0,
          isCommentDisabled: false,
        },
        author: {
          fullName: user.fullName,
          username: user.username,
          id: user.id,
          photoURL: user.photoURL,
        },
        properties: {
          title: data.title,
          isAttachment: data.isAttachment,
          category: data.category,
          type: data.type,
          contentPlain: data.contentPlain,
          contentHTML: data.contentHTML,
          poster: fileUrlFromUpload2,
          attachment: fileUrlFromUpload,
          duration: data.duration,
        },
        status: 0,
        createdAt: new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createPublish = createAsyncThunk(
  "edit/createPublish",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      let fileUrlFromUpload2;

      if (data.poster.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Posts/${user.id}/${btoa(data.poster.name)}`
        );
        await fileRef.put(data.poster);
        fileUrlFromUpload2 = await fileRef.getDownloadURL();
      }

      // firebase.firestore.Timestamp.fromDate(new Date())
      return firestore.collection("posts").add({
        interactions: {
          commentCount: 0,
          likeCount: 0,
          saveCount: 0,
          reportCount: 0,
          isCommentDisabled: false,
          hidden: false,
        },
        author: {
          fullName: user.fullName,
          username: user.username,
          id: user.id,
          photoURL: user.photoURL,
        },
        properties: {
          title: data.title,
          subtitle: data.subtitle,
          tags: [data.category],
          contentPlain: data.contentPlain,
          contentHTML: data.contentHTML,
          banner: fileUrlFromUpload2,
          duration: data.duration,
        },
        review: {
          id: "",
          username: "",
          reviewedAt: new Date(),
        },
        status: 0,
        createdAt: new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updatePublish = createAsyncThunk(
  "edit/updatePublish",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      let fileUrlFromUpload2;

      if (data.poster.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Posts/${user.id}/${btoa(data.poster.name)}`
        );
        await fileRef.put(data.poster);
        fileUrlFromUpload2 = await fileRef.getDownloadURL();
      } else {
        fileUrlFromUpload2 = data.poster;
      }

      // console.log(fileUrlFromUpload2)

      // firebase.firestore.Timestamp.fromDate(new Date())
      const updateRef = firestore.collection("posts").doc(data.id);
      return updateRef.update({
        properties: {
          title: data.title,
          subtitle: data.subtitle,
          tags: [data.category],
          contentPlain: data.contentPlain,
          contentHTML: data.contentHTML,
          banner: fileUrlFromUpload2,
          duration: data.duration,
        },
        status: 0,
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updateResource = createAsyncThunk(
  "edit/updateResource",
  async (data, { extra, getState, rejectWithValue }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();

    try {
      let fileUrlFromUpload;
      let fileUrlFromUpload2;
      //upload image first to get url
      if (data.attachment.name !== "" || data.attachment.name !== null) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Resources/attachment/${btoa(data.attachment.name)}`
        );
        await fileRef.put(data.attachment);
        fileUrlFromUpload = await fileRef.getDownloadURL();
      } else {
        fileUrlFromUpload = data.attachment;
      }

      if (data.poster.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Resources/posters/${btoa(data.poster.name)}`
        );
        await fileRef.put(data.poster);
        fileUrlFromUpload2 = await fileRef.getDownloadURL();
      } else {
        fileUrlFromUpload2 = data.poster;
      }

      // console.log(fileUrlFromUpload2)

      // firebase.firestore.Timestamp.fromDate(new Date())
      const updateRef = firestore.collection("resources").doc(data.id);
      return updateRef.update({
        properties: {
          title: data.title,
          isAttachment: data.isAttachment,
          category: data.category,
          type: data.type,
          contentPlain: data.contentPlain,
          contentHTML: data.contentHTML,
          poster: fileUrlFromUpload2,
          attachment: fileUrlFromUpload,
          duration: data.duration,
        },
        status: 0,
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updateMisc = createAsyncThunk(
  "edit/updateMisc",
  async (data, { extra, rejectWithValue }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();

    try {
      let fileUrlFromUpload;

      if (data.poster.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Resources/posters/${btoa(data.poster.name)}`
        );
        await fileRef.put(data.poster);
        fileUrlFromUpload = await fileRef.getDownloadURL();
      } else {
        fileUrlFromUpload = data.poster;
      }

      // firebase.firestore.Timestamp.fromDate(new Date())
      const updateRef = firestore.collection("miscellaneous").doc(data.id);
      return updateRef.update({
        properties: {
          title: data.title,
          contentPlain: data.contentPlain,
          contentHTML: data.contentHTML,
          poster: fileUrlFromUpload,
          duration: data.duration,
        },
        status: 1,
        createdAt: new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createMisc = createAsyncThunk(
  "edit/createMisc",
  async (data, { extra, rejectWithValue }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();

    try {
      let fileUrlFromUpload;

      if (data.poster.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Resources/posters/${btoa(data.poster.name)}`
        );
        await fileRef.put(data.poster);
        fileUrlFromUpload = await fileRef.getDownloadURL();
      }

      // firebase.firestore.Timestamp.fromDate(new Date())
      return firestore.collection("miscellaneous").add({
        properties: {
          title: data.title,
          contentPlain: data.contentPlain,
          contentHTML: data.contentHTML,
          poster: fileUrlFromUpload,
          duration: data.duration,
        },
        status: 1,
        createdAt: new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createEvent = createAsyncThunk(
  "edit/createEvent",
  async (data, { extra, rejectWithValue }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();

    try {
      let fileUrlFromUpload;
      let fileUrlFromUpload2;

      //upload image first to get url
      if (data.poster.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Events/poster/${btoa(data.poster.name)}`
        );
        await fileRef.put(data.poster);
        fileUrlFromUpload = await fileRef.getDownloadURL();
      }

      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`Events/icsFiles/${data.icsFile.name}`);
      await fileRef.put(data.icsFile);
      fileUrlFromUpload2 = await fileRef.getDownloadURL();

      console.log(data);
      // firebase.firestore.Timestamp.fromDate(new Date())
      return firestore.collection("events").add({
        interactions: {
          clicks: 0,
        },
        location: {
          city: data.loccity,
          venue: data.locvenue,
        },
        properties: {
          title: data.title,
          poster: fileUrlFromUpload,
          dateStart: new Date(data.dateStart),
          dateEnd: new Date(data.dateEnd),
          contentPlain: data.contentPlain,
          contentHTML: data.contentHTML,
          icsFile: fileUrlFromUpload2,
        },
        display: {
          from: new Date(data.displayFrom),
          to: new Date(data.displayTo),
        },
        status: 0,
        createdAt: new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updateEvent = createAsyncThunk(
  "edit/updateEvent",
  async (data, { extra, rejectWithValue }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();

    try {
      let fileUrlFromUpload;
      let fileUrlFromUpload2;

      //upload image first to get url
      if (data.poster.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Events/poster/${btoa(data.poster.name)}`
        );
        await fileRef.put(data.poster);
        fileUrlFromUpload = await fileRef.getDownloadURL();
      } else {
        fileUrlFromUpload = data.poster;
      }

      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`Events/icsFiles/${data.icsFile.name}`);
      await fileRef.put(data.icsFile);
      fileUrlFromUpload2 = await fileRef.getDownloadURL();

      // firebase.firestore.Timestamp.fromDate(new Date())
      const updateRef = firestore.collection("events").doc(data.id);
      // console.log(data)
      return updateRef.update({
        location: {
          city: data.loccity,
          venue: data.locvenue,
        },
        properties: {
          title: data.title,
          poster: fileUrlFromUpload,
          dateStart: new Date(data.dateStart),
          dateEnd: new Date(data.dateEnd),
          contentPlain: data.contentPlain,
          contentHTML: data.contentHTML,
          icsFile: fileUrlFromUpload2,
        },
        display: {
          from: new Date(data.displayFrom),
          to: new Date(data.displayTo),
        },
      });

      // firebase.firestore.Timestamp.fromDate(new Date())
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createSurvey = createAsyncThunk(
  "edit/createSurvey",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;

    const firestore = getFirestore();

    try {
      // firebase.firestore.Timestamp.fromDate(new Date())
      return firestore.collection("surveys").add({
        interactions: {
          clicks: 0,
        },
        properties: {
          title: data.title,
          link: data.link,
        },
        display: {
          from: new Date(data.displayFrom),
          to: new Date(data.displayTo),
        },
        status: 0,
        createdAt: new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updateSurvey = createAsyncThunk(
  "edit/updateSurvey",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;

    const firestore = getFirestore();

    try {
      // firebase.firestore.Timestamp.fromDate(new Date())
      const updateRef = firestore.collection("surveys").doc(data.id);
      return updateRef.update({
        properties: {
          title: data.title,
          link: data.link,
        },
        display: {
          from: new Date(data.displayFrom),
          to: new Date(data.displayTo),
        },
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const addFeaturedPost = createAsyncThunk(
  "edit/createFeaturedPost",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore, getFirebase } = extra;

    const firestore = getFirestore();
    const firebase = getFirebase();

    try {
      // firebase.firestore.Timestamp.fromDate(new Date())
      return firestore
        .collection("inits")
        .doc("featured")
        .update({
          data: firebase.firestore.FieldValue.arrayUnion(data),
        })
        .catch((err) => {
          // console.log(err.code)
          if (err.code === "not-found") {
            return firestore
              .collection("inits")
              .doc("featured")
              .set({
                data: firebase.firestore.FieldValue.arrayUnion(data),
              });
          }
        });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const removeFeaturedPost = createAsyncThunk(
  "edit/removeFeaturedPost",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore, getFirebase } = extra;

    const firestore = getFirestore();
    const firebase = getFirebase();

    try {
      // firebase.firestore.Timestamp.fromDate(new Date())
      return firestore
        .collection("inits")
        .doc("featured")
        .update({
          data: firebase.firestore.FieldValue.arrayRemove(data),
        });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const addTag = createAsyncThunk(
  "edit/addTag",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore, getFirebase } = extra;

    const firestore = getFirestore();
    const firebase = getFirebase();

    try {
      // firebase.firestore.Timestamp.fromDate(new Date())
      return firestore
        .collection("inits")
        .doc("tags")
        .update({
          data: firebase.firestore.FieldValue.arrayUnion(data),
        })
        .catch((err) => {
          // console.log(err.code)
          if (err.code === "not-found") {
            return firestore
              .collection("inits")
              .doc("tags")
              .set({
                data: firebase.firestore.FieldValue.arrayUnion(data),
              });
          }
        });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const removeTag = createAsyncThunk(
  "edit/removeTag",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore, getFirebase } = extra;

    const firestore = getFirestore();
    const firebase = getFirebase();

    try {
      // firebase.firestore.Timestamp.fromDate(new Date())
      return firestore
        .collection("inits")
        .doc("tags")
        .update({
          data: firebase.firestore.FieldValue.arrayRemove(data),
        });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const addCategory = createAsyncThunk(
  "edit/addCategory",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore, getFirebase } = extra;

    const firestore = getFirestore();
    const firebase = getFirebase();

    try {
      // firebase.firestore.Timestamp.fromDate(new Date())
      return firestore
        .collection("inits")
        .doc("categorys")
        .update({
          data: firebase.firestore.FieldValue.arrayUnion(data),
        })
        .catch((err) => {
          // console.log(err.code)
          if (err.code === "not-found") {
            return firestore
              .collection("inits")
              .doc("categorys")
              .set({
                data: firebase.firestore.FieldValue.arrayUnion(data),
              });
          }
        });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const removeCategory = createAsyncThunk(
  "edit/removeCategory",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore, getFirebase } = extra;

    const firestore = getFirestore();
    const firebase = getFirebase();

    try {
      // firebase.firestore.Timestamp.fromDate(new Date())
      return firestore
        .collection("inits")
        .doc("categorys")
        .update({
          data: firebase.firestore.FieldValue.arrayRemove(data),
        });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createMember = createAsyncThunk(
  "edit/createMember",
  async (data, { extra, rejectWithValue }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();

    try {
      let fileUrlFromUpload;

      if (data.poster.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Members/posters/${btoa(data.poster.name)}`
        );
        await fileRef.put(data.poster);
        fileUrlFromUpload = await fileRef.getDownloadURL();
      }

      // firebase.firestore.Timestamp.fromDate(new Date())
      return firestore.collection("members").add({
        properties: {
          name: data.name,
          title: data.title,
          imgUrl: fileUrlFromUpload,
          category: data.category,
          type: data.type,
          description: data.description,
          linkUrl: data.link,
          linkedInUrl: data.linkedin,
        },
        status: 0,
        createdAt: new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updateMember = createAsyncThunk(
  "edit/updateMember",
  async (data, { extra, rejectWithValue }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();

    try {
      let fileUrlFromUpload;
      //upload image first to get url

      if (data.poster.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Members/posters/${btoa(data.poster.name)}`
        );
        await fileRef.put(data.poster);
        fileUrlFromUpload = await fileRef.getDownloadURL();
      } else {
        fileUrlFromUpload = data.poster;
      }

      // firebase.firestore.Timestamp.fromDate(new Date())
      const updateRef = firestore.collection("members").doc(data.id);
      return updateRef.update({
        properties: {
          name: data.name,
          title: data.title,
          imgUrl: fileUrlFromUpload,
          category: data.category,
          type: data.type,
          description: data.description,
          linkUrl: data.link,
          linkedInUrl: data.linkedin,
        },
        status: 0,
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const deleteMember = createAsyncThunk(
  "edit/deleteMember",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("members").doc(data);
      return updateRef.delete();
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

export const createPartner = createAsyncThunk(
  "edit/createPartner",
  async (data, { extra, rejectWithValue }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();

    try {
      let fileUrlFromUpload;

      if (data.poster.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Partners/posters/${btoa(data.poster.name)}`
        );
        await fileRef.put(data.poster);
        fileUrlFromUpload = await fileRef.getDownloadURL();
      }

      // firebase.firestore.Timestamp.fromDate(new Date())
      return firestore.collection("partners").add({
        properties: {
          name: data.name,
          logoUrl: fileUrlFromUpload,
          linkUrl: data.link,
        },
        createdAt: new Date(),
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updatePartner = createAsyncThunk(
  "edit/updatePartner",
  async (data, { extra, rejectWithValue }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();

    try {
      let fileUrlFromUpload;
      //upload image first to get url

      if (data.poster.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Partners/posters/${btoa(data.poster.name)}`
        );
        await fileRef.put(data.poster);
        fileUrlFromUpload = await fileRef.getDownloadURL();
      } else {
        fileUrlFromUpload = data.poster;
      }

      // firebase.firestore.Timestamp.fromDate(new Date())
      const updateRef = firestore.collection("partners").doc(data.id);
      return updateRef.update({
        properties: {
          name: data.name,
          logoUrl: fileUrlFromUpload,
          linkUrl: data.link,
        },
        status: 0,
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const deletePartner = createAsyncThunk(
  "edit/deletePartner",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("partners").doc(data);
      return updateRef.delete();
    } catch (error) {
      rejectWithValue(error);
      SET__EDIT__ERROR(error);
    }
  }
);

const EditSlice = createSlice({
  name: "create",
  initialState,
  reducers: {
    SET__EDIT__ERROR: (state, { payload }) => {
      state.editError = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(acceptPost.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(acceptPost.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(deletePost.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(deletePost.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(resolveComplaint.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(resolveComplaint.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(unResolveComplaint.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(unResolveComplaint.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(resolveAppeal.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(resolveAppeal.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(unResolveAppeal.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(unResolveAppeal.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(rejectPost.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(rejectPost.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(unPublishPost.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(unPublishPost.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(unPublishPostAndReject.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(unPublishPostAndReject.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(unPublishSkill.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(unPublishSkill.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(unPublishSkillAndReject.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(unPublishSkillAndReject.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(autoApprove.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(autoApprove.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(assignVolunteerBadge.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(assignVolunteerBadge.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(assignMemberBadge.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(assignMemberBadge.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(assignSuperBadge.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(assignSuperBadge.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(assignRookieAdmin.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(assignRookieAdmin.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(assignSuperAdmin.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(assignSuperAdmin.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(removeAdmin.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(removeAdmin.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(removeBadge.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(removeBadge.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(removeAutoApprove.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(removeAutoApprove.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(acceptSkill.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(acceptSkill.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(rejectSkill.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(rejectSkill.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(createResource.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(createResource.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(createMember.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(createMember.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(createPartner.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(createPartner.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(createPublish.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(createPublish.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(createMisc.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(createMisc.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(updateMisc.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(updateMisc.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(updatePublish.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(updatePublish.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(updateResource.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(updateResource.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(updateMember.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(updateMember.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(updatePartner.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(updatePartner.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(createEvent.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(createEvent.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(updateEvent.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(updateEvent.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(createSurvey.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(createSurvey.fulfilled, (state) => {
        state.isEditLoading = false;
      })
      .addCase(updateSurvey.pending, (state) => {
        state.isEditLoading = true;
      })
      .addCase(updateSurvey.fulfilled, (state) => {
        state.isEditLoading = false;
      });
  },
});

export const { SET__EDIT__ERROR } = EditSlice.actions;
export default EditSlice.reducer;

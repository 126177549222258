import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/bootstrap/bootstrap.min.css';
import './css/font-awesome/font-awesome.min.css';
import './css/ionicons/ionicons.min.css';
import './css/lte/lte.min.css';
import './css/skins/skin-blue.min.css';
import './css/index.css';
import fbConfig from './config/fbConfig'
import { store } from './stores/store'
import App from './App';
import { createFirestoreInstance } from 'redux-firestore';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import AuthIsLoaded from './auth/controls/AuthIsLoaded';


//enabled for direct access to user profile //firestore
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true
}

//react redux provide props

const rrfProps = {
  firebase: fbConfig,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <AuthIsLoaded>
          <App />
        </AuthIsLoaded>

      </ReactReduxFirebaseProvider>

    </Provider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

export const BLANK__IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/vois-cyprus-org.appspot.com/o/utilities%2Favatar.png?alt=media&token=29680e9e-1400-4fae-a396-137e191b81ef";
export const ILLUSTRATION__1 =
  "https://firebasestorage.googleapis.com/v0/b/vois-cyprus-org.appspot.com/o/utilities%2Fillustration1.png?alt=media&token=8f1ca424-d231-42cc-9fa5-018b5dbcdb19";
export const ILLUSTRATION__2 =
  "https://firebasestorage.googleapis.com/v0/b/vois-cyprus-org.appspot.com/o/utilities%2Fillustration2.png?alt=media&token=267c56ad-340e-4bb8-b821-1b401cc18132";
export const ILLUSTRATION__3 =
  "https://firebasestorage.googleapis.com/v0/b/vois-cyprus-org.appspot.com/o/utilities%2Fillustration3.png?alt=media&token=2427546a-c41b-4a25-8936-77d51aa8dded";
export const VOLUNTEER__BADGE =
  "https://firebasestorage.googleapis.com/v0/b/vois-cyprus-org.appspot.com/o/utilities%2Fvolunteer-badge.png?alt=media&token=d778cc13-0bfe-4232-b394-96b3e7265573";
export const ROYALTY__BADGE =
  "https://firebasestorage.googleapis.com/v0/b/vois-cyprus-org.appspot.com/o/utilities%2Froyalty-badge.png?alt=media&token=cc0580c7-8889-4b4f-9348-607afda58c11";
export const DEV__BADGE =
  "https://firebasestorage.googleapis.com/v0/b/vois-cyprus-org.appspot.com/o/utilities%2Fdeveloper-badge.png?alt=media&token=7e5c665d-e1b5-4c7f-94f0-4b7b4fe5d0d2";
export const PLACE__HOLDER =
  "https://firebasestorage.googleapis.com/v0/b/voisapp-5deb7.appspot.com/o/misc%2Fplaceholder.jpg?alt=media&token=a1d6a29a-7f54-4cff-8303-29c6aec175fd";

export const POST__COUNT = 500;
export const POST__LENGTH = 3160;
export const SERVICES__COUNT = 200;
export const SERVICES__LENGTH = 1100;

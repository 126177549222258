import React, { useEffect, useState } from "react";
import moment from "moment";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import SliderContainer from "../../../../components/SliderContainer";

const $ = window.jQuery;
const ContentInfoBox = ({
  photoUrl,
  username,
  createdAt,
  status,
  banners,
  title,
  description,
  contentHTML,
  text,
  handlePublish,
  handleUnpublish,
  handleUnpublishAndReject,
}) => {
  const [param, setParam] = useState(null);
  const { isEditLoading } = useSelector((state) => state.edit);

  useEffect(() => {
    setParam(username);
  }, [username]);

  useFirestoreConnect(() => {
    return [
      {
        collection: "users",
        where: ["user.username", "==", param],
        storeAs: `${param}-data`,
      },
    ];
  });

  const userInfo = useSelector(
    ({ firestore: { ordered } }) => ordered[`${param}-data`]
  );
  // console.log(userInfo, username)

  useEffect(() => {
    if (window.jQuery) {
      $('[data-widget="box-widget"]').boxWidget();
    }
  }, []);
  return (
    <div className="box" data-widget="box-widget">
      <div className="box-header with-border">
        <div className="user-block">
          <img
            className="img-circle"
            src={photoUrl}
            alt={photoUrl}
            loading={"eager"}
          />
          <span className="username">
            <a href="#!">{username}</a>
          </span>
          <span className="description">
            Shared publicly -{" "}
            {moment(createdAt.toDate()).startOf("hour").fromNow()}
          </span>
        </div>
        {/* <!-- /.user-block --> */}
        <div className="box-tools">
          <button
            type="button"
            className="btn btn-box-tool"
            data-widget="collapse"
            data-toggle="tooltip"
            title="Collapse"
          >
            <i className="fa fa-minus"></i>
          </button>
          <button
            type="button"
            className="btn btn-box-tool dropdown-toggle"
            data-toggle="dropdown"
            title="actions"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v"></i>
          </button>
          <ul className="dropdown-menu" role="menu">
            {status !== 0 ? (
              <li>
                <button
                  type="button"
                  className="btn btn-default btn-action"
                  disabled={status === 0 || status === 1 || isEditLoading}
                  onClick={handlePublish}
                >
                  Publish {text}
                </button>
              </li>
            ) : (
              <li>
                <button
                  type="button"
                  className="btn btn-default btn-action"
                  onClick={handleUnpublish}
                >
                  Un-publish {text}
                </button>
              </li>
            )}
            <li>
              <button
                type="button"
                className="btn btn-default btn-action"
                disabled={status === 1 || status === 2 || isEditLoading}
                onClick={handleUnpublishAndReject}
              >
                Un-publish {text} and reject
              </button>
            </li>
            {/* <li className="divider"></li>
                        <li>
                            <button
                                type="button"
                                className="btn btn-default btn-action"
                                disabled={(userInfo && userInfo[0].user.isAdmin === 1) || isEditLoading}
                            >
                                Enable Auto-publish ({username})
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                className="btn btn-default btn-action"
                                disabled={(userInfo && userInfo[0].user.isAdmin === 0) || isEditLoading}
                            >
                                Disable Auto-publish({username})
                            </button>
                        </li> */}
          </ul>
        </div>
        {/* <!-- /.box-tools --> */}
      </div>
      {/* <!-- /.box-header --> */}
      <div className="box-body" style={{ padding: "0 2rem" }}>
        {banners.length > 0 && <SliderContainer imgArr={banners} />}

        <div className="post-title" style={{ marginTop: "2rem" }}>
          <i>Title</i>
          <h5 style={{ margin: "0" }}>{title}</h5>
        </div>
        <div
          className="post-content skill"
          style={{ marginTop: "2rem", padding: "0" }}
        >
          <i>Description</i>
          <p>{description}</p>
        </div>

        <div
          className="post-content skill"
          style={{ marginTop: "2rem", padding: "0" }}
        >
          <i>Services</i>
          <div dangerouslySetInnerHTML={{ __html: contentHTML }}></div>
        </div>
      </div>
      {/* <!-- /.box-body --> */}
    </div>
  );
};

export default ContentInfoBox;

import React from 'react'

const Overview = ({ handleDeleteTag, tags }) => {


    return <>
        {
            tags && tags.data.map(tag => (
                <div className="col-md-3 col-sm-6 col-xs-12" key={tag}>
                    <div className="info-box flex">
                        <span className={`info-box-icon bg-aqua`}><i className="fa fa-tags"></i></span>
                        <div className="info-box-content">
                            <span className="info-box-text">{tag}</span>
                        </div>
                        <span className='info-action bg-red' onClick={() => handleDeleteTag(tag)}><i className="fa fa-trash"></i></span>
                        {/* <!-- /.info-box-content --> */}
                    </div>
                    {/* <!-- /.info-box --> */}
                </div>
            ))
        }

    </>
}

export default Overview
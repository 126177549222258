import React from 'react'

const ToolbarButtons = ({
    quillToolbarRef,
    handleFormatStyle
}) => {
    return (
        <div id="toolbar-controls" ref={quillToolbarRef}>
            <button onClick={handleFormatStyle} id="bold"><i className="fa fa-bold"></i></button>
            <button onClick={handleFormatStyle} id="italic"><i className="fa fa-italic"></i></button>
            <button onClick={handleFormatStyle} id="blockquote"><i className="fa fa-quote-right"></i></button>
            <button onClick={handleFormatStyle} id="header-1"><i className="fa fa-header" ></i></button>
            <button onClick={handleFormatStyle} id="list"><i className="fa fa-list" ></i></button>
        </div>
    )
}

export default ToolbarButtons
import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Outlet } from 'react-router-dom'
import { compose } from 'redux'

const Resources = ({ resources }) => {
    return (
        <div className="page-wrapper" style={{ width: "100%" }}>
            <Outlet context={resources} />
        </div >

    )
}

const mapStateToProps = state => {
    const resources = state.firestore.ordered.adminresources;
    return {
        resources: resources,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(
        [
            {
                collection: "resources",
                where: [['status', '==', 0]],
                orderBy: ["createdAt", 'desc'],
                storeAs: "adminresources"
            },
            {
                collection: "resources",
                where: [['properties.category', '==', 'Healthcare'], ['status', '==', 0]],
                storeAs: "healthcareResource"
            },
            {
                collection: "resources",
                where: [['properties.category', '==', 'Immigration'], ['status', '==', 0]],
                storeAs: "immigrationResource"
            },
            {
                collection: "resources",
                where: [['properties.category', '==', 'Working'], ['status', '==', 0]],
                storeAs: "workingResource"
            },
            {
                collection: "resources",
                where: [['properties.category', '==', 'Gender'], ['status', '==', 0]],
                storeAs: "genderResource"
            },
            {
                collection: "resources",
                where: [['properties.category', '==', 'Discrimination'], ['status', '==', 0]],
                storeAs: "discriminationResource"
            },
            {
                collection: "resources",
                where: [['properties.category', '==', 'Education'], ['status', '==', 0]],
                storeAs: "educationResource"
            },
            {
                collection: "resources",
                where: [['properties.category', '==', 'General'], ['status', '==', 0]],
                storeAs: "generalResource"
            },
            {
                collection: "resources",
                where: [['properties.category', '==', 'Housing'], ['status', '==', 0]],
                storeAs: "housingResource"
            },
        ]
    )
)(Resources)
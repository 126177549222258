import React from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import BoxCard from '../../../components/BoxCard'
import PageHeader from '../../../components/PageHeader'
import Overview from './Overview'
import { resolveAppeal, unResolveAppeal } from '../../../stores/edit/EditSlice'
import AbortableAlert from '../../../components/AbortableAlert'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

const AppealIndex = () => {

    const { appeal, resolvedAppeals } = useOutletContext();
    const { isEditLoading } = useSelector(state => state.edit);
    const dispatch = useDispatch();

    const handleResolve = (id) => {
        dispatch(resolveAppeal(id))
            .then(() => {
                AbortableAlert("Appeal status successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleUnresolve = (id) => {
        dispatch(unResolveAppeal(id))
            .then(() => {
                AbortableAlert("Appeal status successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    return <>
        {/* <!-- Content Header (Page header) --> */}
        <PageHeader
            pName="Appeals"
            pDesc="Manage appeals"
        />

        {/* // < !--Main content-- > */}
        < section className="content container-fluid" >

            {/* <!-------------------------- | Your Page Content Here --------------------------> */}
            {/* overview */}
            <div className="row">
                <Overview />
            </div>

            {/* appeal lisr */}
            <div className="row" style={{ marginTop: "4rem" }}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#recent" data-toggle="tab" aria-expanded="true">Recent</a></li>
                            <li className=""><a href="#older" data-toggle="tab" aria-expanded="false">Older</a></li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane active" id="recent">
                                <div className="row greybg">
                                    {appeal &&
                                        appeal.length > 0 ?
                                        appeal.map(appeal => (
                                            <div key={appeal.id} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                                <BoxCard
                                                    id={appeal.id}
                                                    banner={appeal.properties.banner}
                                                    message={appeal.properties.description}
                                                    title={appeal.properties.title}
                                                    status={appeal.status}
                                                    text="appeal"
                                                    type="parent"
                                                    hide={true}
                                                />
                                            </div>
                                        ))
                                        :
                                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">No new appeals</div>

                                    }
                                </div>
                            </div>
                            <div className="tab-pane" id="older">
                                <div className="box-body no-padding">
                                    <table className="table table-striped">
                                        <tbody>
                                            <tr>
                                                <th>Title</th>
                                                <th>Category</th>
                                                <th>Date created</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                            {
                                                resolvedAppeals &&
                                                resolvedAppeals.map(data => (
                                                    <tr key={data.id}>
                                                        <td>
                                                            <div className="user-wrapper">
                                                                <Link to={`./id/${data.id}`} className="user-wrapper">
                                                                    {data.properties.title}
                                                                </Link>

                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="user-wrapper">
                                                                {data.properties.category}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="user-wrapper">
                                                                <i>{moment(data.createdAt.toDate()).format('ll')}</i>
                                                            </div>

                                                        </td>
                                                        <td>
                                                            <div className="user-wrapper">
                                                                <i>{data.status === 0 ? 'Resolved' : 'Un-resolved'}</i>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="user-wrapper">
                                                                <div className="btn-group">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-default dropdown-toggle"
                                                                        data-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu pos30">
                                                                        <li>
                                                                            {
                                                                                data.status === 0 ?
                                                                                    <>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-default btn-action"
                                                                                            onClick={() => handleUnresolve(data.id)}
                                                                                            disabled={isEditLoading}
                                                                                        >
                                                                                            Un-resolve complaint
                                                                                        </button>

                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-default btn-action"
                                                                                            onClick={() => handleResolve(data.id)}
                                                                                            disabled={isEditLoading}
                                                                                        >
                                                                                            Resolve complaint
                                                                                        </button>
                                                                                    </>

                                                                            }

                                                                        </li>

                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section >
        {/* // <!-- /.content --> */}
    </>
}

export default AppealIndex
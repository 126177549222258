import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';


const $ = window.jQuery;
const ContentInfoBox = ({
    photoUrl,
    username,
    createdAt,
    banner,
    title,
    subTitle,
    contentHTML,
    status,
    handleUnpublish,
    handleUnpublishAndReject,
    // handleAutoPublish,
    // handleRemoveAutoPublish
}) => {

    const [param, setParam] = useState(null);
    const { isEditLoading } = useSelector(state => state.edit)

    useEffect(() => {
        setParam(username)
    }, [username]);

    useFirestoreConnect(() => {
        return [
            {
                collection: "users",
                where: ['user.username', '==', param],
                storeAs: `${param}-data`
            }
        ]
    });

    const userInfo = useSelector(
        ({ firestore: { ordered } }) => ordered[`${param}-data`]
    );
    // console.log(userInfo, username)

    useEffect(() => {
        if (window.jQuery) {
            $('[data-widget="box-widget"]').boxWidget();
        }
    }, []);


    return (
        <div className="box" data-widget="box-widget">
            <div className="box-header with-border">
                <div className="user-block">
                    <img
                        className="img-circle"
                        src={photoUrl}
                        alt={photoUrl}
                        loading={'eager'}
                    />
                    <span
                        className="username">
                        <a href="#!">{username}</a>
                    </span>
                    <span className="description">Shared publicly - {moment(createdAt.toDate()).startOf('hour').fromNow()}</span>
                </div>
                {/* <!-- /.user-block --> */}
                <div className="box-tools">
                    <button
                        type="button"
                        className="btn btn-box-tool"
                        data-widget="collapse" data-toggle="tooltip" title="Collapse"><i className="fa fa-minus"></i>
                    </button>
                    
                </div>
                {/* <!-- /.box-tools --> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body">
                <img
                    className="img-responsive max-banner pad"
                    src={banner}
                    loading="eager"
                    alt={banner}
                />

                <div className="post-title padSide">
                    <h5>{title}</h5>
                </div>
                <div className="post-title padSide sub">
                    <h5>{subTitle}</h5>
                </div>
                <div
                    className="post-content"
                    dangerouslySetInnerHTML={{ __html: contentHTML }}
                >
                </div>
            </div>
            {/* <!-- /.box-body --> */}

        </div>
    )
}

export default ContentInfoBox
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Overview = () => {

    const homesMiscs = useSelector(
        ({ firestore: { ordered } }) => ordered['homemiscs']
    )

    return <>
        {
            homesMiscs &&
            homesMiscs.map((misc, index) => (
                <Link to={`./${misc.id}`} key={misc.id} className='box-link'>
                    <div className="col-md-3 col-sm-6 col-xs-12" style={{ cursor: "pointer" }}>
                        <div className="info-box">
                            <span className={`info-box-icon ${index === 0 ? 'bg-aqua' : index === 1 ? 'bg-green' : 'bg-yellow'} `}><i className="fa fa-tags"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text">{misc.properties.title}</span>
                            </div>
                            {/* <!-- /.info-box-content --> */}
                        </div>
                        {/* <!-- /.info-box --> */}
                    </div>
                </Link>

            ))
        }

    </>
}

export default Overview
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  authError: null,
  authMessage: "",
  isAuthLoading: false,
};

export const signInWithCredentials = createAsyncThunk(
  "auth/signInWithCredentials ",
  async (credentials, { dispatch, extra, rejectWithValue }) => {
    const { getFirebase, getFirestore } = extra;
    const firebase = getFirebase();
    const firestore = getFirestore();

    try {
      return firestore
        .collection("users")
        .where("user.email", "==", credentials.email)
        .get()
        .then((querySnapshot) => {
          const { docs } = querySnapshot;

          if (
            docs.length === 0 ||
            docs[docs.length - 1].data().user.isAdmin === 0
          ) {
            let errMessage = "Invalid Login Credentials";
            dispatch(SET__AUTH__ERROR(errMessage));
          } else {
            firebase
              .auth()
              .signInWithEmailAndPassword(
                credentials.email,
                credentials.password
              )
              .then((credentials) => {
                dispatch(SET__AUTH__ERROR(null));
              })
              .catch((err) => {
                let errMessage = "";
                // console.log(err.code);
                switch (err.code) {
                  case "auth/wrong-password":
                    errMessage = "You have entered an invalid password";
                    dispatch(SET__AUTH__ERROR(errMessage));
                    break;
                  case "auth/user-not-found":
                    errMessage = "Invalid Login Credentials";
                    dispatch(SET__AUTH__ERROR(errMessage));
                    break;
                  default:
                    errMessage = "Something went wrong. Try again later";
                    dispatch(SET__AUTH__ERROR({ errMessage }));
                }
              });
          }
        })
        .catch((err) => {
          dispatch(SET__AUTH__ERROR({ err }));
        });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const signOut = createAsyncThunk(
  "auth/signOut",
  async (credential, { extra }) => {
    const { getFirebase } = extra;
    const firebase = getFirebase();

    return firebase.auth().signOut();
  }
);

export const createAdminUser = createAsyncThunk(
  "auth/createAdminUser ",
  async (credentials, { dispatch, extra, rejectWithValue }) => {
    const { getFirebase } = extra;
    const firebase = getFirebase();
    let errMessage = "";

    try {
      const createUser = firebase
        .functions()
        .httpsCallable("createUserWithData");
      return createUser(credentials).then((result) => {
        // console.log(result)
        const { data } = result;
        if (data === 1) {
          dispatch(SET_AUTH_LOADING());
          dispatch(SET__AUTH__ERROR(data));
        }
        switch (data.errorInfo.code) {
          case "auth/email-already-exists":
            errMessage = {
              email: "The email address is already in use by another account",
            };
            dispatch(SET__AUTH__ERROR(errMessage));
            break;
          default:
            errMessage = "Something went wrong. Try again later";
            dispatch(SET__AUTH__ERROR({ errMessage }));
        }
      });
    } catch (error) {
      // Getting the Error details.
      rejectWithValue(error);
    }
  }
);

export const deleteUserById = createAsyncThunk(
  "auth/deleteUserById ",
  async (id, { extra, rejectWithValue }) => {
    const { getFirebase } = extra;
    const firebase = getFirebase();

    try {
      const deleteUser = firebase.functions().httpsCallable("deleteUserById");
      return deleteUser(id);
    } catch (error) {
      // Getting the Error details.
      rejectWithValue(error);
    }
  }
);

export const suspendUserById = createAsyncThunk(
  "auth/suspendUserById ",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("users").doc(data.id);
      const status = data.type === 1 ? true : false;
      return updateRef.update({
        "account.isSuspended": status,
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updateDonorById = createAsyncThunk(
  "auth/updateDonorById ",
  async (data, { extra, rejectWithValue }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    try {
      const updateRef = firestore.collection("donations").doc(data);
      return updateRef.update({
        status: 0,
      });
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET__AUTH__ERROR: (state, { payload }) => {
      state.authError = payload;
    },
    SET_AUTH_LOADING: (state) => {
      state.isAuthLoading = false;
    },
    SET__AUTH__MESSAGE: (state, { payload }) => {
      state.authMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInWithCredentials.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(signInWithCredentials.fulfilled, (state) => {
        state.isAuthLoading = false;
      })
      .addCase(createAdminUser.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(createAdminUser.fulfilled, (state) => {
        state.isAuthLoading = false;
      })
      .addCase(deleteUserById.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(deleteUserById.fulfilled, (state) => {
        state.isAuthLoading = false;
      })
      .addCase(suspendUserById.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(suspendUserById.fulfilled, (state) => {
        state.isAuthLoading = false;
      })
      .addCase(updateDonorById.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(updateDonorById.fulfilled, (state) => {
        state.isAuthLoading = false;
      });
  },
});

export const { SET__AUTH__ERROR, SET__AUTH__MESSAGE, SET_AUTH_LOADING } =
  AuthSlice.actions;
export default AuthSlice.reducer;

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { signInWithCredentials } from '../../stores/auth/AuthSlice';
import DisplayValidationMessage from '../controls/DisplayValidationMessage';
import FormValidate from '../controls/FormValidate';

const Login = () => {

    useEffect(() => {
        document.body.className = "";
        document.body.classList.add("hold-transition");
        document.body.classList.add("login-page");
    });

    // state slice redux
    const { authError } = useSelector(state => state.auth);

    const [elements, setElements] = useState({
        email: "",
        password: ""
    });

    const rules = {
        email: { required: true, email: true },
        password: { required: true, minLength: "8" },
    }

    const updateFormValue = (evt) => {
        setElements({ ...elements, [evt.target.name]: evt.target.value });
    }

    return (
        <div className="login-box">
            <div className="login-logo">
                <a href="../../index2.html"><b>Admin</b>VOIS</a>
            </div>
            {/* <!-- /.login-logo --> */}
            <div className="login-box-body">
                <p className={`login-box-msg ${authError !== null ? 'error__text' : ''}`}>
                    {
                        authError !== null ?
                            authError :
                            "Enter your credentials to start a session"
                    }
                </p>

                <FormValidate
                    text={`Sign In`}
                    data={elements}
                    view={`login`}
                    rules={rules}
                    submit={signInWithCredentials}
                >
                    <div className="form-group has-feedback">
                        <input
                            type="email"
                            name='email'
                            autoComplete='off'
                            className="form-control"
                            onChange={updateFormValue}
                            placeholder="Email"
                        />
                        <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                        <DisplayValidationMessage element="email" />
                    </div>
                    <div className="form-group has-feedback">
                        <input
                            type="password"
                            name='password'
                            autoComplete='off'
                            onChange={updateFormValue}
                            className="form-control"
                            placeholder="Password"
                        />
                        <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                        <DisplayValidationMessage element="password" />
                    </div>

                </FormValidate>

            </div>
            {/* <!-- /.login-box-body --> */}
        </div >
    )
}

export default Login
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

const Allmembers = ({
  members,
  handleFilterChange,
  filter,
  handleNext,
  handlePrev,
  handleUserDelete,
  maxDispLimit,
  indexDisp,
  filteredMembers,
}) => {
  const navigate = useNavigate();
  return (
    <div className="user-table">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="box no-border">
            <div className="box-header spx" style={{ width: "100%" }}>
              <h3
                className="box-title"
                style={{ flex: 1, marginRight: "100px" }}
              >
                Search
              </h3>
              <div
                className="input-group exp"
                style={{
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <input
                  type="text"
                  name="user-search"
                  autoComplete="off"
                  value={filter}
                  onChange={handleFilterChange}
                  className="form-control"
                  placeholder="search for member by name or type..."
                />
                {/* <CSVLink data={handleCSVData()} filename={"admin_users.csv"}>
                  Export CSV
                </CSVLink> */}
              </div>
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body no-padding">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Date Added</th>
                    <th>Actions</th>
                  </tr>
                  {filteredMembers
                    .slice(indexDisp, maxDispLimit)
                    .map((data) => (
                      <tr key={data.id}>
                        <td>
                          <div className="user-wrapper">
                            <div className="user-img">
                              <img
                                src={data.properties.imgUrl}
                                className="img-circle"
                                alt={data.properties.imgUrl}
                              />
                            </div>
                            <div className="user-desc">
                              <p className="name">{data.properties.name}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="user-wrapper">
                            <i>
                              {data.properties.type}
                              {data.properties.type === "Others"
                                ? ` | ${data.properties.category}`
                                : ""}
                            </i>
                          </div>
                        </td>
                        <td>
                          <div className="user-wrapper">
                            <i>
                              {moment(data.createdAt.toDate()).format("ll")}
                            </i>
                          </div>
                        </td>
                        <td>
                          <div className="user-wrapper">
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-default dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i
                                  className="fa fa-ellipsis-v"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <ul className="dropdown-menu pos30">
                                <li>
                                  <button
                                    type="button"
                                    className="btn btn-default btn-action"
                                    onClick={() =>
                                      navigate(`./${data.id}`)
                                    }
                                  >
                                    Edit
                                  </button>
                                </li>
                                <li>
                                  <button
                                    type="button"
                                    className="btn btn-default btn-action"
                                    onClick={() => handleUserDelete(data.id)}
                                  >
                                    Delete
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {/* <!-- /.box-body --> */}
            <div className="box-footer spx" style={{ width: "100%" }}>
              <p
                className="fx"
                style={{ flex: "1", justifyContent: "flex-start" }}
              >
                Showing {indexDisp + 1} -{" "}
                {maxDispLimit > members.length ? members.length : maxDispLimit}{" "}
                of {members.length} entries
              </p>
              <div className="btn-group">
                <button
                  onClick={handlePrev}
                  disabled={indexDisp <= 0}
                  type="button"
                  className="btn btn-default"
                >
                  <i className="fa fa-caret-left" aria-hidden="true"></i>
                </button>
                <button
                  onClick={handleNext}
                  disabled={
                    maxDispLimit === members.length ||
                    maxDispLimit > members.length
                  }
                  type="button"
                  className="btn btn-default"
                >
                  <i className="fa fa-caret-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Allmembers;

import React from 'react'
import { firestoreConnect } from 'react-redux-firebase'
import { Outlet } from 'react-router-dom'
import { compose } from 'redux'

const Events = () => {
    return (
        <div className="page-wrapper" style={{ width: "100%" }}>
            <Outlet />
        </div >

    )
}

export default compose(
    firestoreConnect(
        [
            {
                collection: "events",
                where: [['status', '==', 0]],
                orderBy: ["createdAt", 'desc'],
                storeAs: "adminEvents"
            },
        ]
    )
)(Events)
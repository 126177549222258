import React from 'react'
import moment from 'moment';

const AppealInfoBox = ({
    tag,
    status,
    createdAt,
    rUsername,
    reviewedAt,
    isEditLoading,
    handleResolve,
    handleUnResolve,
    handleAppealDelete
}) => {
    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
            <div className="box box-widget widget-user-2">
                <div className="widget-user-header bg-white" style={{ padding: "10px 20px" }}>
                    <h5 className="widget-user-desc" style={{ margin: "0px", fontSize: "16px" }}>Post Information</h5>
                </div>
                <div className="box-footer no-padding">
                    <ul className="nav nav-stacked">
                        <li>
                            <a
                                href="/"
                                onClick={(event) => event.preventDefault()}
                            >
                                Category <span className="pull-right badge bg-grey">{tag}</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/"
                                onClick={(event) => event.preventDefault()}
                            >
                                Status
                                <span
                                    className={`pull-right badge ${(status === 0) ? 'bg-green' : 'bg-red'}`}
                                >
                                    {`${(status === 0) ? 'Accepted' : (status === 1) ? 'Pending' : 'Resolved'}`}
                                </span></a>
                        </li>
                        <li>
                            <a
                                href="/"
                                onClick={(event) => event.preventDefault()}
                            >
                                Created on
                                <span className="pull-right ">
                                    <i>{moment(createdAt.toDate()).format('ll')}</i>
                                </span></a>
                        </li>

                        {
                            rUsername !== "" ?
                                <>
                                    <li>
                                        <a
                                            href="/"
                                            onClick={(event) => event.preventDefault()}
                                        >
                                            Reviewed on
                                            <span className="pull-right">

                                                <i>{moment(reviewedAt.toDate()).format('llll')}</i>

                                            </span></a>
                                    </li>
                                    <li>
                                        <a
                                            href="/"
                                            onClick={(event) => event.preventDefault()}
                                        >
                                            Reviewed by
                                            <span className="pull-right">
                                                <a href="/"><i>{rUsername}</i></a>
                                            </span></a>
                                    </li>
                                </> : null
                        }

                    </ul>
                </div>
            </div>
            <div className="box box-widget widget-user-2">
                <div className="widget-user-header bg-white" style={{ padding: "10px 20px" }}>
                    <h5 className="widget-user-desc" style={{ margin: "0px", fontSize: "16px" }}>Actions</h5>
                </div>
                <div className="box-footer no-padding">
                    <ul className="nav nav-stacked">
                        <li>
                            {
                                status !== 0 ?
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                        disabled={status === 0 || isEditLoading}
                                        onClick={handleResolve}
                                    >
                                        resolve appeal
                                    </button> :
                                    <button
                                        type="button"
                                        className="btn btn-default"
                                        disabled={isEditLoading}
                                        onClick={handleUnResolve}
                                    >
                                        Un-resolve appeal
                                    </button>
                            }
                        </li>
                        <li>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={handleAppealDelete}
                            >
                                Delete appeal
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default AppealInfoBox
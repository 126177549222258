import React from "react";

const Overview = ({ donors, donated, notDonated }) => {

    console.log(notDonated)
  return (
    <>
      <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <div className="info-box">
          <span className="info-box-icon bg-aqua">
            <i className="fa fa-dot-circle-o"></i>
          </span>
          <div className="info-box-content">
            <span className="info-box-text">Total Donors</span>
            <span className="info-box-number">
              {donors && donors.length}
              <small></small>
            </span>
          </div>
          {/* <!-- /.info-box-content --> */}
        </div>
        {/* <!-- /.info-box --> */}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <div className="info-box">
          <span className="info-box-icon bg-red">
            <i className="fa fa-dot-circle-o"></i>
          </span>
          <div className="info-box-content">
            <span className="info-box-text">Donated</span>
            <span className="info-box-number">
              {donated && donated.length}
              <small></small>
            </span>
          </div>
          {/* <!-- /.info-box-content --> */}
        </div>
        {/* <!-- /.info-box --> */}
      </div>
      {/* <!-- /.col --> */}
      <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <div className="info-box">
          <span className="info-box-icon bg-green">
            <i className="fa fa-check-square-o"></i>
          </span>

          <div className="info-box-content">
            <span className="info-box-text">Yet to donate</span>
            <span className="info-box-number">
              {notDonated && notDonated.length}
            </span>
          </div>
          {/* <!-- /.info-box-content --> */}
        </div>
        {/* <!-- /.info-box --> */}
      </div>
      {/* <!-- /.col --> */}

      {/* <!-- fix for small devices only --> */}
      <div className="clearfix visible-sm-block"></div>

      {/* <!-- /.col --> */}
    </>
  );
};

export default Overview;

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import AsideLeftNav from "./Navbar/AsideLeftNav";
// import AsideRightNav from './Navbar/AsideRightNav'
import Navbar from "./Navbar/Navbar";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

// const $ = window.jQuery;
const BuildWrapper = () => {
  const { auth } = useSelector((state) => state.firebase);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.uid) {
      navigate("/", { replace: true });
    }
  }, [auth, navigate]);

  useEffect(() => {
    document.body.className = "";
    document.body.classList.add("skin-blue");
    document.body.classList.add("fixed");
    document.body.classList.add("sidebar-mini");
  });

  // useEffect(() => {
  //     if (window.jQuery) {
  //         $('[data-widget="tree"]').tree();
  //         $('[data-widget="box-widget"]').boxWidget();
  //     }
  // }, []);

  const posts = useSelector(
    ({ firestore: { ordered } }) => ordered[`alertPosts`]
  );
  const skills = useSelector(
    ({ firestore: { ordered } }) => ordered[`alertSkills`]
  );
  const ratings = useSelector(
    ({ firestore: { ordered } }) => ordered[`alertRatings`]
  );
  const appeals = useSelector(
    ({ firestore: { ordered } }) => ordered[`alertAppeals`]
  );
  const complaints = useSelector(
    ({ firestore: { ordered } }) => ordered[`alertComplaints`]
  );
  const donations = useSelector(
    ({ firestore: { ordered } }) => ordered[`alertDonations`]
  );

  return (
    <>
      <Navbar
        posts={posts}
        skills={skills}
        ratings={ratings}
        appeals={appeals}
        complaints={complaints}
        donations={donations}
      />
      <AsideLeftNav />
      {/* <!-- Content Wrapper. Contains page content --> */}
      <div className="content-wrapper" style={{ minHeight: "950px" }}>
        {/* outlet */}
        <Outlet />
      </div>
      {/* <!-- /.content-wrapper --> */}
      <Footer />
      {/* <AsideRightNav /> */}
    </>
  );
};

export default compose(
  firestoreConnect([
    {
      collection: "alerts",
      where: [
        ["payload", "==", "/posts"],
        ["status", "==", 1],
      ],
      orderBy: ["createdAt", "desc"],
      storeAs: "alertPosts",
    },
    {
      collection: "alerts",
      where: [
        ["payload", "==", "/skills"],
        ["status", "==", 1],
      ],
      orderBy: ["createdAt", "desc"],
      storeAs: "alertSkills",
    },
    {
      collection: "alerts",
      where: [
        ["payload", "==", "/ratings"],
        ["status", "==", 1],
      ],
      orderBy: ["createdAt", "desc"],
      storeAs: "alertRatings",
    },
    {
      collection: "complaints",
      where: ["status", "==", 1],
      orderBy: ["createdAt", "desc"],
      storeAs: "alertComplaints",
    },
    {
      collection: "donations",
      where: ["status", "==", 1],
      orderBy: ["createdAt", "desc"],
      storeAs: "alertDonations",
    },
    {
      collection: "appeals",
      where: ["status", "==", 1],
      orderBy: ["createdAt", "desc"],
      storeAs: "alertAppeals",
    },
  ])
)(BuildWrapper);

import React from 'react'
import { BackIcon } from '../assets/icons/Icons'

const PageHeader = ({
    pName,
    pDesc,
    handleBack
}) => {
    return (
        < section className="content-header fx">
            {
                handleBack !== undefined ?
                    <>
                        <p
                            onClick={handleBack}
                            style={{
                                marginBottom: "0",
                                lineHeight: "0",
                                marginRight: "2rem",
                                cursor: "pointer"
                            }}>
                            <BackIcon
                                width={20}
                                height={20}
                            />
                        </p>
                    </>
                    : null
            }

            <h1>
                {pName}
                <small>{pDesc}</small>
            </h1>
            <ol className="breadcrumb">
                <li><a href="#!!"><i className="fa fa-dashboard"></i>Admin</a></li>
                <li className="active">{pName}</li>
            </ol>
        </section >
    )
}

export default PageHeader
import React from "react";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";

const Overview = () => {
  const founders = useSelector(
    ({ firestore: { ordered } }) => ordered.founders
  );
  const members = useSelector(({ firestore: { ordered } }) => ordered.members);

  const filteredMembers = React.useMemo(() => {
    if (isLoaded(members)) {
      return members.filter((data) => {
        return (
          [data.properties.type].join("").toLowerCase().indexOf("others") !== -1
        );
      });
    }
  }, [members]);

  return (
    <>
      {/* <!-- /.col --> */}
      <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <div className="info-box">
          <span className="info-box-icon bg-green">
            <i className="fa fa-check-square-o"></i>
          </span>

          <div className="info-box-content">
            <span className="info-box-text">Founders</span>
            <span className="info-box-number">
              {founders && founders.length}
            </span>
          </div>
          {/* <!-- /.info-box-content --> */}
        </div>
        {/* <!-- /.info-box --> */}
      </div>
      {/* <!-- /.col --> */}
      <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <div className="info-box">
          <span className="info-box-icon bg-green">
            <i className="fa fa-check-square-o"></i>
          </span>

          <div className="info-box-content">
            <span className="info-box-text">Others</span>
            <span className="info-box-number">
              {filteredMembers && filteredMembers.length}
            </span>
          </div>
          {/* <!-- /.info-box-content --> */}
        </div>
        {/* <!-- /.info-box --> */}
      </div>
    </>
  );
};

export default Overview;

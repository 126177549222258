import React, { useState } from 'react'

const AbortableAlert = (text) => {

    const timeOutInSecs = 3000;
    let timeOutControl;

    const html = `<div class="alert-box" style="
        position: fixed;
        right: 15px;
        top: 20px;
        z-index: 2001;"
    >
        <div class="alert alert-dismissible" style="background-color: #222d32; color: white">
            <button type="button" class="close" data-dismiss="alert" aria-hidden="true" style="color: white;">×</button>
            ${text}
        </div>
    </div>`

    const dismissAll = () => {
        if (document.querySelector('.alert-box')) {
            document.querySelector('.alert-box').remove();
        }
    }

    const handleDelete = () => {
        clearTimeout(timeOutControl);
        timeOutControl = null;
        document.querySelector('.alert-box').remove();
    }
    const handleDisplay = (html) => {
        const body = document.body;
        body.insertAdjacentHTML('beforeend', html);
        const close = document.querySelector('.close');
        close.addEventListener("click", handleDelete);
        timeOutControl = setTimeout(() => {
            if (document.querySelector('.alert-box')) {
                document.querySelector('.alert-box').remove();
            }
        }, timeOutInSecs);
    }
    dismissAll()
    handleDisplay(html);

}

export default AbortableAlert
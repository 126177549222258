import React from "react";
import { useOutletContext } from "react-router-dom";
import BoxCard from "../../../components/BoxCard";
import PageHeader from "../../../components/PageHeader";
import {
  firestoreConnect,
  isLoaded,
  useFirestoreConnect,
} from "react-redux-firebase";
import { connect } from "react-redux";
import { compose } from "redux";

const FeaturedIndex = ({ posts, featured }) => {
  const features = useOutletContext();

  // const taskQuery = [...features[0].data].map(feature => ({
  //     collection: "posts",
  //     doc: feature,
  //     storeAs: 'featured'
  // }))

  useFirestoreConnect(() => {
    if (isLoaded(features)) {
      return [...features[0].data].map((feature) => ({
        collection: "posts",
        doc: feature,
        storeAs: feature,
      }));
    }
  });

  const featuredNews = React.useMemo(() => {
    if (isLoaded(posts, featured)) {
      return posts.filter((data) => {
        return (
          [...featured[0].data]
            .map((s) => s)
            .join("")
            .indexOf(data.id) !== -1
        );
      });
    }
  }, [posts, featured]);

  if (isLoaded(featured, featuredNews)) {
    return (
      <>
        {/* <!-- Content Header (Page header) --> */}
        <PageHeader pName="Features" pDesc="Manage features" />

        {/* // < !--Main content-- > */}
        <section className="content container-fluid">
          {/* <!-------------------------- | Your Page Content Here --------------------------> */}

          {/* features lisr */}
          <div className="row">
            <div className="col-xs-12 col-md-12 col-lg-12">
              <section className="sub content-header">
                <h4 style={{ margin: "0" }}>
                  {featuredNews.length} features available
                </h4>
              </section>
              <div className="content-data">
                <div className="row">
                  {featuredNews.map((post) => (
                    <div
                      key={post.id}
                      className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                    >
                      <BoxCard
                        id={post.id}
                        banner={post.properties.banner}
                        message={post.properties.contentPlain}
                        title={post.properties.title}
                        status={post.status}
                        text="post"
                        type="outer"
                        where="posts"
                        hide={false}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* // <!-- /.content --> */}
      </>
    );
  }
};

const mapStateToProps = (state) => {
  const posts = state.firestore.ordered.homeposts;
  const featured = state.firestore.ordered.featuredposts;
  return {
    posts: posts,
    featured: featured,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: "posts",
      orderBy: ["createdAt", "desc"],
      storeAs: "homeposts",
    },
    {
      collection: "inits",
      doc: "featured",
      storeAs: "featuredposts",
    },
  ])
)(FeaturedIndex);

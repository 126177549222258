import React, { useEffect, useRef, useState } from "react";
import Quill from "quill/core";
import Block from "quill/blots/block";
import Inline from "quill/blots/inline";
import List, { ListItem } from "quill/formats/list";
import imageCompression from "browser-image-compression";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../../components/PageHeader";
import EditorContainer from "../../resources/partials/comps/EditorContainer";
import ToolbarButtons from "../../resources/partials/comps/ToolbarButtons";
import { createEvent } from "../../../stores/edit/EditSlice";
import AbortableAlert from "../../../components/AbortableAlert";
import { createEvent as makeEvent } from "ics";
import AllEvents from "./AllEvents";

class BoldBlot extends Inline {}

BoldBlot.blotName = "bold";
BoldBlot.tagName = "strong";

class ItalicBlot extends Inline {}

ItalicBlot.blotName = "italic";
ItalicBlot.tagName = "em";

class BlockBlot extends Block {}

BlockBlot.blotName = "blockquote";
BlockBlot.tagName = "blockquote";

class HeaderBlot extends Block {}

HeaderBlot.blotName = "header";
HeaderBlot.tagName = ["h1", "h2"];

Quill.register(BoldBlot);
Quill.register(ItalicBlot);
Quill.register(BlockBlot);
Quill.register(HeaderBlot);
Quill.register({
  "formats/list": List,
  "formats/list-item": ListItem,
});
Quill.debug(false);

const strip = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.innerHTML || "";
};
const $ = window.jQuery;
const EventsIndex = () => {
  const [view, setView] = useState(false);

  //quill ref to store quill instance
  const quillInstanceRef = useRef();
  //toolbar ref to access toolbar div
  const quillToolbarRef = useRef();
  //quill editor div
  const quillEditorRef = useRef();

  const dispatch = useDispatch();
  const { isEditLoading } = useSelector((state) => state.edit);

  const [eventState, setEventState] = useState({
    title: "",
    eventDateStart: "",
    eventDateEnd: "",
    displayFrom: "",
    displayTo: "",
    contentPlain: "",
    contentHTML: "",
    poster: "",
    loccity: "Select a city",
    locvenue: "",
  });

  // quill initialize
  useEffect(() => {
    quillInstanceRef.current = new Quill(quillEditorRef.current, {
      placeholder: "Start writing here...",
      scrollingContainer: ".box-template",
    });
    // quillInstanceRef.current.addContainer(quillToolbarRef.current);
    // console.log(quillInstanceRef.current)
  }, [quillInstanceRef, view]);

  // ontextchange
  useEffect(() => {
    const quill = quillInstanceRef.current;
    const onTextChange = (type) => {
      const pGrafs = document.querySelectorAll(`#editor-container p`);
      const ulGrafs = document.querySelectorAll(`#editor-container ul`);
      setEventState((state) => ({
        ...state,
        contentPlain: quillInstanceRef.current.container.textContent,
        contentHTML: quillInstanceRef.current.root.innerHTML,
      }));
      if (quillInstanceRef.current.hasFocus()) {
        pGrafs.forEach((p) => {
          if (p.classList.length === 0) {
            p.classList.add("editor-p");
          }
        });
      }

      ulGrafs.forEach((ul) => {
        if (ul.classList.length === 0) {
          ul.classList.add("browser-default");
          ul.classList.add("editor-ul");
        }
      });

      handleContentHeight();
    };
    if (view) {
      quill.on("text-change", onTextChange);
      return () => quill.off("text-change", onTextChange);
    }
  }, [quillInstanceRef, view]);

  const getWordCount = () => {
    const editorContainer = $("#editor-container").get(0);
    const regex = /\s+/gi;
    const count =
      $(editorContainer).find(".ql-editor").text().length > 0
        ? $(editorContainer)
            .find(".ql-editor")
            .text()
            .trim()
            .replace(regex, " ")
            .split(" ").length
        : 0;
    return count;
  };

  // handle height modal ocntent
  const handleContentHeight = () => {
    if (getWordCount() >= 100 && getWordCount() <= 300) {
      document.querySelector("#editor-container").style.height = "350px";
      // console.log(getWordCount())
    } else if (getWordCount() >= 301 && getWordCount() <= 500) {
      document.querySelector("#editor-container").style.height = "601px";
      document.querySelector("#editor-container").style.top = "10%";
      // console.log(getWordCount())
    } else if (getWordCount() >= 501 && getWordCount() <= 2000) {
      document.querySelector("#editor-container").style.height = "701px";
    } else {
      document.querySelector("#editor-container").style.height = "unset";
      document.querySelector("#editor-container").style.minHeight = "229px";
    }
  };

  const handleOnChangeOptions = (evt) => {
    const options = [...evt.target.options]
      .filter((o) => o.selected)
      .map((o) => o.value)
      .toString();
    setEventState((state) => ({ ...state, [evt.target.name]: options }));
  };

  const options = [
    "Select a city",
    "Famagusta",
    "Iskele",
    "Nicosia",
    "Kyrenia",
  ];

  const updateFormValue = (evt) => {
    setEventState((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  // compressed image
  const compressImageFile = async (file) => {
    const options = {
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: "image/webp",
    };

    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  };

  const handleUpload = (evt) => {
    const [file] = evt.target.files;
    if (evt.target.id === "poster") {
      const compressedFile = compressImageFile(file);
      compressedFile.then((file) => {
        if (file) {
          setEventState((state) => ({ ...state, poster: file }));
        }
      });
    }
  };

  // toolbar formatting
  const handleFormatStyle = (evt) => {
    const quill = quillInstanceRef.current;
    const target = evt.currentTarget.id;
    const selection = quill.getSelection(() => false);
    const selectedFormat = quill.getFormat();

    const isSelectedFormatted = selectedFormat.hasOwnProperty(target)
      ? true
      : false;

    switch (target) {
      case "bold":
        isSelectedFormatted
          ? quill.formatText(selection.index, selection.length, "bold", false)
          : quill.formatText(selection.index, selection.length, "bold", true);
        break;
      case "italic":
        isSelectedFormatted
          ? quill.formatText(selection.index, selection.length, "italic", false)
          : quill.formatText(selection.index, selection.length, "italic", true);
        break;
      case "blockquote":
        isSelectedFormatted
          ? quill.format("blockquote", false)
          : quill.format("blockquote", true);
        break;
      case "header-1":
        isSelectedFormatted
          ? quill.removeFormat(selection.index, selection.length, "header")
          : quill.format("header", 1);
        break;
      case "list":
        isSelectedFormatted
          ? quill.format("list", false)
          : quill.format("list", true);
        break;
      case "list-ul":
        isSelectedFormatted
          ? quill.format("ulist", false)
          : quill.format("ulist", true);
        break;
      default:
    }
  };

  const handleSave = (evt) => {
    let blob;
    const name = "vois-event.ics";
    makeEvent(evt, (error, value) => {
      blob = new File([value], name, { type: "plain/text" });
    });
    return blob;
  };

  const publishEvents = () => {
    if (eventState.title === "" || eventState.title === null) {
      AbortableAlert("Add a title for this event.");
      return;
    }

    if (eventState.eventDateStart === "") {
      AbortableAlert("Add an event date and start time");
      return;
    }

    if (eventState.eventDateEnd === "") {
      AbortableAlert("Add an event date and end time");
      return;
    }

    if (eventState.displayFrom === "" && eventState.displayTo === "") {
      AbortableAlert("Add a display duration for this event");
      return;
    }

    if (eventState.contentPlain === "" || eventState.contentPlain === null) {
      AbortableAlert("More information required for this event.");
      return;
    }

    const dateStart = new Date(eventState.eventDateStart);
    const dateEnd = new Date(eventState.eventDateEnd);

    const event = {
      start: [
        dateStart.getFullYear(),
        dateStart.getMonth() + 1,
        dateStart.getDate(),
        dateStart.getHours(),
        dateStart.getMinutes(),
      ],
      duration: {
        hours: dateEnd.getHours() - dateStart.getHours(),
        minutes: dateEnd.getMinutes() - dateStart.getMinutes(),
      },
      title: eventState.title,
      description: eventState.title,
      location: `${eventState.locvenue}-${eventState.loccity}`,
      url: "https://www.voiscyprus.org",
      status: "CONFIRMED",
      busyStatus: "BUSY",
      organizer: { name: "Vois Cyprus", email: "voiscyprus@gmail.com" },
      attendees: [],
    };

    const data = {
      title: validator.escape(eventState.title.trim()),
      dateStart: eventState.eventDateStart,
      dateEnd: eventState.eventDateEnd,
      displayFrom: eventState.displayFrom,
      displayTo: eventState.displayTo,
      contentPlain: eventState.contentPlain,
      contentHTML: strip(eventState.contentHTML),
      loccity: eventState.loccity,
      locvenue: eventState.locvenue,
      poster: eventState.poster,
      icsFile: handleSave(event),
    };

    // console.log(data)

    dispatch(createEvent(data))
      .then((res) => {
        AbortableAlert("Event created successfully.");
        setView(false);
        setEventState((state) => ({
          ...state,
          title: "",
          eventDate: "",
          displayFrom: "",
          displayTo: "",
          contentPlain: "",
          contentHTML: "",
          poster: "",
          loccity: "",
          locvenue: "",
        }));
      })
      .catch((err) => {
        AbortableAlert(err);
      });
  };

  return (
    <>
      {/* <!-- Content Header (Page header) --> */}
      <PageHeader pName="Events" pDesc="Manage events" />

      {/* // < !--Main content-- > */}
      <section className="content container-fluid">
        <div className="user-table">
          <button
            type="button"
            className="btn btn-sm btn-primary user-btn"
            onClick={() => setView(true)}
          >
            Create an event
          </button>
          {view && (
            <div className="create-form">
              <div className="row">
                <div className="col-md-8">
                  <div className="box box-primary">
                    <div
                      className="box-header with-border"
                      style={{ padding: "1rem 3rem" }}
                    >
                      <h3 className="box-title">Create an event</h3>
                    </div>
                    <div className="box-body" style={{ padding: "1rem 3rem" }}>
                      <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          id="title"
                          placeholder="Enter a title"
                          onChange={updateFormValue}
                        />
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-xs-6">
                            <label htmlFor="displayFrom">Display from:</label>
                            <input
                              type="date"
                              name="displayFrom"
                              id="displayFrom"
                              className="form-control"
                              placeholder="Display from"
                              onChange={updateFormValue}
                            />
                          </div>
                          <div className="col-xs-6">
                            <label htmlFor="displayTo">Display to:</label>
                            <input
                              type="date"
                              name="displayTo"
                              id="displayTo"
                              className="form-control"
                              placeholder="Display to"
                              onChange={updateFormValue}
                            />
                          </div>
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Error ea quae, maxime voluptatibus dicta cum sunt tenetur minus provident a.
                          <div className="col-xs-12 form-group"></div>
                          <div className="col-xs-6">
                            <label htmlFor="eventDateStart">
                              Event (date and start time)
                            </label>
                            <input
                              type="datetime-local"
                              name="eventDateStart"
                              id="eventDateStart"
                              className="form-control"
                              placeholder="Event (Local date and start time)"
                              onChange={updateFormValue}
                            />
                          </div>
                          <div className="col-xs-6">
                            <label htmlFor="eventDateEnd">
                              Event (date and end time)
                            </label>
                            <input
                              type="datetime-local"
                              name="eventDateEnd"
                              id="eventDateEnd"
                              className="form-control"
                              placeholder="Event (Local date and end time)"
                              onChange={updateFormValue}
                            />
                          </div>
                          <div className="col-xs-12 form-group"></div>
                          <div className="col-xs-6">
                            <label>Location (Select a city)</label>
                            <select
                              className="form-control"
                              defaultValue="Select a city"
                              name="loccity"
                              onChange={handleOnChangeOptions}
                            >
                              {options.map((category) => (
                                <option
                                  key={category}
                                  value={category}
                                  disabled={category === "Select a city"}
                                >
                                  {category}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-xs-6">
                            <label htmlFor="locvenue">Location (venue)</label>
                            <input
                              type="text"
                              name="locvenue"
                              id="locvenue"
                              className="form-control"
                              placeholder="Enter a venue for the event"
                              onChange={updateFormValue}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="poster">Add event poster</label>
                        <input
                          type="file"
                          accept="image/*"
                          id="poster"
                          onChange={handleUpload}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">
                          More information needed for event
                        </label>
                        <div className="box box-default">
                          <div
                            className="box-header  with-border"
                            style={{ padding: "0" }}
                          >
                            <ToolbarButtons
                              quillToolbarRef={quillToolbarRef}
                              handleFormatStyle={handleFormatStyle}
                            />
                          </div>
                          <div className="box-body box-template">
                            <EditorContainer quillEditorRef={quillEditorRef} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="box-footer fx"
                      style={{ padding: "1rem 3rem", gap: "1rem" }}
                    >
                      <button
                        type="submit"
                        className="btn btn-default"
                        onClick={() => setView(false)}
                      >
                        cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={publishEvents}
                        disabled={isEditLoading}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <AllEvents />
      </section>
    </>
  );
};

export default EventsIndex;

import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Outlet } from 'react-router-dom'
import { compose } from 'redux'

const Publish = ({ posts }) => {
    return (
        <div className="page-wrapper" style={{ width: "100%" }}>
            <Outlet context={posts} />
        </div >

    )
}

const mapStateToProps = state => {
    const posts = state.firestore.ordered.adminposts;
    return {
        posts: posts,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(
        [
            {
                collection: "posts",
                where: [['author.username', '==', 'testacct1'], ['status', '==', 0]],
                orderBy: ["createdAt", 'desc'],
                storeAs: "adminposts"
            },
            {
                collection: "posts",
                where: [['author.username', '==', 'testacct1'], ['properties.tags', 'array-contains', 'Healthcare'], ['status', '==', 0]],
                storeAs: "healthcarePost"
            },
            {
                collection: "posts",
                where: [['author.username', '==', 'testacct1'], ['properties.tags', 'array-contains', 'Immigration'], ['status', '==', 0]],
                storeAs: "immigrationPost"
            },
            {
                collection: "posts",
                where: [['author.username', '==', 'testacct1'], ['properties.tags', 'array-contains', 'Working'], ['status', '==', 0]],
                storeAs: "workingPost"
            },
            {
                collection: "posts",
                where: [['author.username', '==', 'testacct1'], ['properties.tags', 'array-contains', 'Gender'], ['status', '==', 0]],
                storeAs: "genderPost"
            },
            {
                collection: "posts",
                where: [['author.username', '==', 'testacct1'], ['properties.tags', 'array-contains', 'Discrimination'], ['status', '==', 0]],
                storeAs: "discriminationPost"
            },
            {
                collection: "posts",
                where: [['author.username', '==', 'testacct1'], ['properties.tags', 'array-contains', 'Education'], ['status', '==', 0]],
                storeAs: "educationPost"
            },
            {
                collection: "posts",
                where: [['author.username', '==', 'testacct1'], ['properties.tags', 'array-contains', 'General'], ['status', '==', 0]],
                storeAs: "generalPost"
            },
            {
                collection: "posts",
                where: [['author.username', '==', 'testacct1'], ['properties.tags', 'array-contains', 'Housing'], ['status', '==', 0]],
                storeAs: "housingPost"
            },
        ]
    )
)(Publish)
import React from 'react'
import { firestoreConnect } from 'react-redux-firebase'
import { Outlet } from 'react-router-dom'
import { compose } from 'redux'

const Miscellaneous = () => {
    return (
        <div className="page-wrapper" style={{ width: "100%" }}>
            <Outlet />
        </div >

    )
}


export default compose(
    firestoreConnect(
        [
            {
                collection: "miscellaneous",
                orderBy: ["createdAt", 'desc'],
                storeAs: "homemiscs"
            },
        ]
    )
)(Miscellaneous)
import React from 'react'
import { Link, useOutletContext } from 'react-router-dom';
import BoxCard from '../../../components/BoxCard';
import PageHeader from '../../../components/PageHeader';
import Overview from './Overview';

const HousingIndex = () => {
    const housing = useOutletContext();
    return <>
        {/* <!-- Content Header (Page header) --> */}
        <PageHeader
            pName="Housing"
            pDesc="Manage housing"
        />

        {/* // < !--Main content-- > */}
        < section className="content container-fluid" >

            {/* <!-------------------------- | Your Page Content Here --------------------------> */}
            {/* overview */}
            <div className="row">
                <Overview />
            </div>

            {/* housing lisr */}
            <div className="row">
                <div className="col-xs-12">
                    <section className="sub content-header" >
                        <h4 style={{ margin: "0" }}>
                            {housing && housing.length} housing available
                        </h4>
                        <div className="btn-group">
                            <button type="button" className="btn btn-default btn-edit dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                Filter by status
                                <span className="caret"></span>
                            </button>
                            <ul className="dropdown-menu pos30">
                                <li><Link to="status/reviewed"><span className='box-status bg-green'></span> Reviewed</Link></li>
                                <li><Link to="status/rejected"><span className='box-status bg-red'></span> Rejected</Link></li>
                            </ul>
                        </div>
                    </section >
                    <div className="content-data">
                        <div className="row">
                            {housing &&
                                housing.map(house => (
                                    <div key={house.id} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                        <BoxCard
                                            id={house.id}
                                            banner={house.building.photos[0]}
                                            message={`${house.building.city}, ${house.building.area}`}
                                            title={house.building.name}
                                            status={house.status}
                                            text="house"
                                            type="parent"
                                            hide={false}
                                        />
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section >
        {/* // <!-- /.content --> */}
    </>
}

export default HousingIndex
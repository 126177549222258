import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/storage";
import 'firebase/compat/functions'


// const firebaseConfig = {
//     apiKey: "AIzaSyBJ4nzX8E0xx0XkbEoijsV2S_OiIBbrJGE",
//     authDomain: "vois-cyprus-org.firebaseapp.com",
//     projectId: "vois-cyprus-org",
//     storageBucket: "vois-cyprus-org.appspot.com",
//     messagingSenderId: "278443664457",
//     appId: "1:278443664457:web:6398cea421c1e9ae81cb98",
//     measurementId: "G-CH5E2Y83DK"
// };
const firebaseConfig = {
    apiKey: "AIzaSyA9oqGvYUc5NZOP3ltSTbuNs32gJdgwwRs",
    authDomain: "voisapp-5deb7.firebaseapp.com",
    projectId: "voisapp-5deb7",
    storageBucket: "voisapp-5deb7.appspot.com",
    messagingSenderId: "379924387036",
    appId: "1:379924387036:web:2c8748cf451080cb471019",
    measurementId: "G-X43Z1T30P4"
};


firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ timestamp: true, merge: true });

export default firebase;


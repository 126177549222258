import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { useOutletContext } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";

import Overview from "./Overview";
import {
  updateDonorById,
} from "../../../stores/auth/AuthSlice";
import AbortableAlert from "../../../components/AbortableAlert";

const DonatedIndex = ({ children }) => {
  const { donors, donated, notDonated } = useOutletContext();
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();

  // pagination
  const [indexDisp, setIndexDisp] = useState(0);
  const maxLimit = 10;
  const [maxDispLimit, setMaxDispLimit] = useState(maxLimit);

  // state slice redux
  const { isAuthLoading } = useSelector((state) => state.auth);

  const handleFilterChange = (evt) => {
    setFilter(evt.target.value);
  };

  const filteredDonors = React.useMemo(() => {
    if (isLoaded(donors)) {
      return donors.filter((data) => {
        return (
          [data.donor.name]
            .join("")
            .toLowerCase()
            .indexOf(filter.toLowerCase()) !== -1
        );
      });
    }
  }, [filter, donors]);

  const handlePrev = () => {
    setIndexDisp(indexDisp - 1);
    setMaxDispLimit(maxDispLimit - maxLimit);
  };

  const handleNext = () => {
    setIndexDisp(indexDisp + 1);
    setMaxDispLimit(maxDispLimit + maxLimit);
  };

  const handleDonorUpdate = (id) => {
    if (window.confirm("Are you sure you want to update donor status?")) {
      dispatch(updateDonorById(id))
        .then((result) => {
          // console.log(result)
          AbortableAlert("Donor status has been updated.");
        })
        .catch((err) => {
          AbortableAlert("Something went wrong. Try again");
        });
    }
  };

  let proppedChildren = React.Children.map(children, (c) =>
    React.cloneElement(c, {
      filteredDonors: filteredDonors,
      isAuthLoading: isAuthLoading,
      maxDispLimit: maxDispLimit,
      filter: filter,
      handleFilterChange: handleFilterChange,
      indexDisp: indexDisp,
      donors: donors,
      handlePrev: handlePrev,
      handleNext: handleNext,
      handleDonorUpdate: handleDonorUpdate,
    })
  );

  if (isLoaded(donors)) {
    return (
      <>
        {/* <!-- Content Header (Page header) --> */}
        <PageHeader pName="Donations" pDesc="Manage donations" />

        {/* // < !--Main content-- > */}
        <section className="content container-fluid">
          {/* <!-------------------------- | Your Page Content Here --------------------------> */}
          {/* overview */}
          <div className="row">
            <Overview
              donors={donors}
              donated={donated}
              notDonated={notDonated}
            />
          </div>

          {proppedChildren}
        </section>
        {/* // <!-- /.content --> */}
      </>
    );
  }
};

export default DonatedIndex;

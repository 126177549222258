import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'

const SliderContainer = ({ imgArr }) => {


    const sliderIndexRef = useRef();
    sliderIndexRef.current = 1;

    const toggleNextSlider = (n) => {
        showSlides(sliderIndexRef.current += n);
    }

    const currentSlide = (n) => {
        showSlides(sliderIndexRef.current = n);
    }

    const showSlides = (n) => {

        const slides = document.getElementsByClassName("sli__der");

        if (n > slides.length) { sliderIndexRef.current = 1 }
        if (n < 1) { sliderIndexRef.current = slides.length }
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slides[sliderIndexRef.current - 1].style.display = "block";
    }


    useEffect(() => {
        showSlides(sliderIndexRef.current);
    })

    return (
        <div className="slider__container">


            {
                imgArr.map((imgs, index) => (
                    <div key={index} className="sli__der fade__efct">
                        <img loading='lazy' src={imgs} alt='slider banners' />
                    </div>
                ))
            }

            <button className="sli__der__prev" onClick={() => toggleNextSlider(-1)}>&#10094;</button>
            <button className="sli__der__next" onClick={() => toggleNextSlider(1)}>&#10095;</button>
        </div>
    )
}

export default SliderContainer
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useNavigate, useParams } from 'react-router-dom';
import AbortableAlert from '../../../components/AbortableAlert';
import PageHeader from '../../../components/PageHeader'
import { acceptPost, addFeaturedPost, autoApprove, deletePost, rejectPost, removeAutoApprove, removeFeaturedPost, revertToPending, unPublishPost, unPublishPostAndReject } from '../../../stores/edit/EditSlice';
import ContentInfoBox from './comps/ContentInfoBox';
import PostInfoBox from './comps/PostInfoBox';

const GetPostById = () => {

    const dispatch = useDispatch();
    const params = useParams();

    const [param, setParam] = useState(null);
    const [loading, setIsLoading] = useState(true);
    const post = useSelector(
        ({ firestore: { ordered } }) => ordered[`${param}-posts`]
    )

    const { isEditLoading } = useSelector(state => state.edit)

    useEffect(() => {
        if (params) {
            setParam(params.id)
        }
    }, [params]);

    useFirestoreConnect(() => {
        return [
            {
                collection: "posts",
                doc: param,
                storeAs: `${param}-posts`
            }
        ]
    })

    const publishPost = () => {
        dispatch(acceptPost(params.id))
            .then(() => {
                AbortableAlert("Post status successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const isRejectPost = () => {
        dispatch(rejectPost(params.id))
            .then(() => {
                AbortableAlert("Post status successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleUnpublish = () => {
        dispatch(unPublishPost(params.id))
            .then(() => {
                AbortableAlert("Post status successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleUnpublishAndReject = () => {
        dispatch(unPublishPostAndReject(params.id))
            .then(() => {
                AbortableAlert("Post status successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleAutoPublish = () => {
        dispatch(autoApprove(post[0].author.id))
            .then(() => {
                AbortableAlert("User privileges successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleRemoveAutoPublish = () => {
        dispatch(removeAutoApprove(post[0].author.id))
            .then(() => {
                AbortableAlert("User privileges successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleAddToFeatured = () => {
        dispatch(addFeaturedPost(params.id))
            .then(() => {
                AbortableAlert("Post added to featured list.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handleRemoveFromFeatured = () => {
        if (window.confirm("Do you want to proceed?")) {
            dispatch(removeFeaturedPost(params.id))
                .then(() => {
                    AbortableAlert("Post removed featured list.");
                })
                .catch((err) => {
                    AbortableAlert(err);
                })
        }

    }

    const handleRevertToPending = () => {
        dispatch(revertToPending(params.id))
            .then(() => {
                AbortableAlert("Post status successfully updated.");
            })
            .catch((err) => {
                AbortableAlert(err);
            })
    }

    const handlePostDelete = () => {
        if (window.confirm("Do you want to proceed?")) {
            setIsLoading(false);
            dispatch(deletePost(params.id))
                .then(() => {
                    navigate("/admin/posts", { replace: true });
                })
                .catch((err) => {
                    AbortableAlert(err);
                })
        }
    }


    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(-1, { replace: true });
    }


    if (isLoaded(post) && loading) {
        if (!isEmpty(post)) {
            return <>

                {/* <!-- Content Header (Page header) --> */}
                <PageHeader
                    pName="Posts"
                    pDesc="Manage posts by ID"
                    handleBack={handleNavigate}
                />

                {/* // < !--Main content-- > */}
                < section className="content container-fluid" >

                    {/* <!-------------------------- | Your Page Content Here --------------------------> */}
                    {/* overview */}
                    <div className="row">
                        <PostInfoBox
                            duration={post[0].properties.duration}
                            tags={post[0].properties.tags}
                            status={post[0].status}
                            createdAt={post[0].createdAt}
                            rUsername={post[0].review.username}
                            reviewedAt={post[0].review.reviewedAt}
                            isEditLoading={isEditLoading}
                            publishPost={publishPost}
                            handleUnpublish={handleUnpublish}
                            handleAddToFeatured={handleAddToFeatured}
                            handleRemoveFromFeatured={handleRemoveFromFeatured}
                            handlePostDelete={handlePostDelete}
                            handleRejectPost={isRejectPost}
                            handleRevertToPending={handleRevertToPending}
                        />
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                            {/* <!-- Box Comment --> */}
                            <ContentInfoBox
                                createdAt={post[0].createdAt}
                                username={post[0].author.username}
                                photoUrl={post[0].author.photoURL}
                                banner={post[0].properties.banner}
                                title={post[0].properties.title}
                                subTitle={post[0].properties.subTitle}
                                contentHTML={post[0].properties.contentHTML}
                                status={post[0].status}
                                handleUnpublish={handleUnpublish}
                                handleUnpublishAndReject={handleUnpublishAndReject}
                                handleAutoPublish={handleAutoPublish}
                                handleRemoveAutoPublish={handleRemoveAutoPublish}
                                addFeaturedPost={handleAddToFeatured}
                                removeFeaturedPost={handleRemoveFromFeatured}
                            />
                            {/* <!-- /.box --> */}
                        </div>
                        <div className="col-md-2"></div>
                    </div >

                </section >
                {/* // <!-- /.content --> */}
            </>
        } else {
            return <>
                {/* <!-- Content Header (Page header) --> */}
                < PageHeader
                    pName="Posts"
                    pDesc="Manage posts by ID"
                    handleBack={handleNavigate}
                />

                {/* // < !--Main content-- > */}
                < section className="content container-fluid" >
                    <h5>You have a broken link or a missing post</h5>
                </section >
            </>
        }

    }

}

export default GetPostById
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Link, useParams } from 'react-router-dom'
import BoxCard from '../../../components/BoxCard'
import PageHeader from '../../../components/PageHeader'
import Overview from './Overview'

const FilteredPosts = () => {

    const params = useParams();
    const [param, setParam] = useState({
        status: 0,
        id: ''
    });

    useEffect(() => {
        if (params) {
            const number = (params.id === 'pending') ? 2 : (params.id === 'reviewed') ? 0 : (params.id === 'rejected') ? 1 : null;
            setParam(param => ({ ...param, status: number, id: params.id }));
        }
    }, [params]);

    // const { firestore } = useSelector(state => state)
    // console.log(firestore)

    useFirestoreConnect(() => {
        return [
            {
                collection: "posts",
                where: ['status', '==', param.status],
                orderBy: ["createdAt"],
                storeAs: `${param.id}-posts`
            }
        ]
    })

    const filteredPosts = useSelector(
        ({ firestore: { ordered } }) => ordered[`${param.id}-posts`]
    )

    return <>
        {/* <!-- Content Header (Page header) --> */}
        <PageHeader
            pName="Posts"
            pDesc="Manage posts"
        />

        {/* // < !--Main content-- > */}
        < section className="content container-fluid" >

            {/* <!-------------------------- | Your Page Content Here --------------------------> */}
            {/* overview */}
            <div className="row">
                <Overview />
            </div>

            {/* posts lisr */}
            <div className="row">
                <div className="col-xs-12">
                    <section className="sub content-header" >
                        <h4 style={{ margin: "0" }}>
                            {filteredPosts && filteredPosts.length} posts available
                        </h4>
                        <div className="btn-group">
                            <button type="button" className="btn btn-default btn-edit dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                Filter by status
                                <span className="caret"></span>
                            </button>
                            <ul className="dropdown-menu pos30">
                                <li><Link to="../status/pending"><span className='box-status bg-yellow'></span> Pending</Link></li>
                                <li><Link to="../status/reviewed"><span className='box-status bg-green'></span> Reviewed</Link></li>
                                <li><Link to="../status/rejected"><span className='box-status bg-red'></span> Rejected</Link></li>
                            </ul>
                        </div>
                    </section >
                    <div className="content-data">
                        <div className="row">
                            {filteredPosts &&
                                filteredPosts.map(post => (
                                    <div key={post.id} className="col-md-3 col-sm-6 col-xs-12">
                                        <BoxCard
                                            id={post.id}
                                            banner={post.properties.banner}
                                            message={post.properties.contentPlain}
                                            title={post.properties.title}
                                            status={post.status}
                                            text="post"
                                            type="child"
                                        />
                                    </div>
                                ))

                            }

                        </div>
                    </div>
                </div>
            </div>
        </section >
        {/* // <!-- /.content --> */}
    </>
}

export default FilteredPosts
import React from "react";
import { useSelector } from "react-redux";

const Overview = () => {
  const partners = useSelector(
    ({ firestore: { ordered } }) => ordered.partners
  );
  return (
    <>
      {/* <!-- /.col --> */}
      <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <div className="info-box">
          <span className="info-box-icon bg-green">
            <i className="fa fa-check-square-o"></i>
          </span>

          <div className="info-box-content">
            <span className="info-box-text">Partners</span>
            <span className="info-box-number">
              {partners && partners.length}
            </span>
          </div>
          {/* <!-- /.info-box-content --> */}
        </div>
        {/* <!-- /.info-box --> */}
      </div>
      {/* <!-- /.col --> */}
    </>
  );
};

export default Overview;
